import { Box } from "@mui/material";
import { LabelValueListItem } from "components/atoms/LabelValueListItem";
import { format } from "date-fns";
import { SelectedDisinfectionReport } from "types/types";
import {
  getMapPositions,
  getTaskDuration,
  getUVCLightDurationInHMS,
} from "utils/UVD/UVDUtils";

interface Props {
  selectedReport: SelectedDisinfectionReport | null;
}

export const DetailsTab = ({ selectedReport }: Props) => {
  const { taskDurationInHMS } = getTaskDuration(
    selectedReport?.start,
    selectedReport?.end,
  );

  const { completed, remaining, failed } = getMapPositions(selectedReport);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        gap: "16px",
        flexDirection: "column",
      }}
    >
      <LabelValueListItem
        label="Started by"
        value={selectedReport?.startedBy ?? "-"}
      />
      <LabelValueListItem
        label="Finished by"
        value={selectedReport?.submittedBy ?? "-"}
      />
      <LabelValueListItem
        label="Disinfection started"
        value={format(new Date(selectedReport?.start), "dd-MM-yyyy HH:mm")}
      />
      <LabelValueListItem
        label="Disinfection ended"
        value={format(new Date(selectedReport?.end), "dd-MM-yyyy HH:mm")}
      />
      <LabelValueListItem
        label="UV-C light duration"
        value={getUVCLightDurationInHMS(selectedReport?.uvcLightDuration)}
      />
      <LabelValueListItem
        label={
          selectedReport?.type === "Predefined"
            ? "Total duration"
            : "Workflow duration "
        }
        value={taskDurationInHMS}
      />
      {selectedReport?.type === "Predefined" ? (
        <>
          <LabelValueListItem
            label="Positions disinfected"
            value={completed.length}
          />
          <LabelValueListItem
            label="Positions remaining"
            value={remaining.length}
          />
          <LabelValueListItem
            label="Positions failed driving to point"
            value={failed.length}
          />
        </>
      ) : null}
    </Box>
  );
};
