import { Grid } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { RobotListCard } from "components/modules/RobotListCard";
import { graphql } from "gql";
import { IRobot } from "gql/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { ForbiddenPage } from "./ForbiddenPage";

const QUERY = graphql(`
  query OrganizationRobotListPage($orgId: String!) {
    organization(id: $orgId) {
      name
    }
    robots(organizationId: $orgId) {
      robots {
        id
        serialNumber
        robotType
        active {
          organization {
            id
            name
            country
          }
        }
        ... on UvdRobot {
          status {
            batteryMergerPercentage
            lastTimeActive
            job {
              state
              type
            }
          }
          version {
            seto
          }
        }
      }
    }
  }
`);

export const OrganizationRobotListPage = () => {
  const navigate = useNavigate();
  const { orgId } = useParams();

  const { data, error } = useQueryWithSnack(QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: orgId === "support",
  });

  const robots = data?.robots.robots as IRobot[] | undefined;

  const sortedRobots = robots
    ?.map((item) => ({
      ...item,
      dataSource: item.active?.organization.id !== orgId,
    }))
    .sort((x, y) => {
      return x.dataSource === y.dataSource ? 0 : x.dataSource ? 1 : -1;
    });

  const forbidden = getIsForbidden(error);
  if (forbidden) return <ForbiddenPage />;

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={[
          {
            label: orgId ? data?.organization?.name ?? "-" : "-",
          },
          { label: "Robots" },
        ]}
      />
      <Grid item>
        <Grid container spacing={6}>
          {(sortedRobots ?? []).map((robot) => (
            <RobotListCard
              key={robot.id}
              robot={robot}
              onClick={() => navigate(robot.id)}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
