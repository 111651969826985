import { Box } from "@mui/material";
import { LabelValueListItem } from "components/atoms/LabelValueListItem";
import { getShowTechnicalServiceNeeded } from "utils/UVD/UVDUtils";

export const HardwareList = ({ robot }: { robot: any | undefined }) => {
  const { showPLCWarning: showWarning } = getShowTechnicalServiceNeeded(robot);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
      }}
    >
      <LabelValueListItem
        label="Hardware"
        value={robot?.version?.hardware || "-"}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
      <LabelValueListItem
        label="Charging system"
        value={robot?.charger || "-"}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
      <LabelValueListItem
        label="PLC model"
        value={robot?.version?.plc.model || "-"}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
      <LabelValueListItem
        label="PLC version"
        labelColor={showWarning ? "auto" : undefined}
        value={robot?.version?.plc.number || "-"}
        showWarning={showWarning}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
      <LabelValueListItem
        label="Motor controller firmware"
        value={robot?.version?.motorController || "-"}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
      <LabelValueListItem
        label="Carrier board software"
        value={robot?.version?.carrierboard || "-"}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
      <LabelValueListItem
        label="PIR firmware"
        value={robot?.version?.pir || "-"}
        wrapperStyle={{ marginRight: 4, paddingLeft: 2 }}
      />
    </Box>
  );
};
