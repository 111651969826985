import { Box } from "@mui/material";
import { ReactElement } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { WidgetDescription } from "../WidgetDescription";
import { HeaderLabel } from "../WidgetHeader";

export interface DrawerHeaderProps {
  onClose: () => void;
  header: string | ReactElement;
  description?: string | ReactElement;
  wrapperStyle?: any;
}

export const DrawerHeader = ({
  onClose,
  header,
  description,
  wrapperStyle,
}: DrawerHeaderProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(6),
        ...wrapperStyle,
      })}
    >
      <Box>
        <HeaderLabel>{header}</HeaderLabel>
        {description ? (
          <WidgetDescription>{description}</WidgetDescription>
        ) : null}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box
          onClick={onClose}
          sx={(theme) => ({
            cursor: "pointer",
            width: "fit-content",
            "& svg": {
              color: theme.palette.grey[400],
            },
            ":hover svg": {
              color: theme.palette.primary.main,
            },
          })}
        >
          <IoCloseOutline size={24} />
        </Box>
      </Box>
    </Box>
  );
};
