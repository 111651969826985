import { Grid } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { Organizations } from "components/organisms/Organizations";

export const SupportOrganizationListPage = () => {
  return (
    <Grid container spacing={6}>
      <HeaderGrid crumbs={[{ label: "Support" }, { label: "Organizations" }]} />
      <Grid item xs={12}>
        <Organizations />
      </Grid>
    </Grid>
  );
};
