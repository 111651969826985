export const ROUTES = {
  login: {
    default: "/login",
    resetPassword: "/login/reset",
  },
  agreements: "/:id",
  profile: "/cloud/profile",
  support: {
    users: "/cloud/support/users",
    user: "/cloud/support/users/:userId",
    organizations: "/cloud/support/organizations",
    organization: "/cloud/support/organizations/:orgId",
    orgRoomList: "/cloud/support/organizations/:orgId/rooms",
    robots: "/cloud/support/robots",
    unassignedRobots: "/cloud/support/unassigned-robots",
    robot: "/cloud/support/robots/:robotId",
    unassignedRobot: "/cloud/support/unassigned-robots/:robotId",
    disinfections: "/cloud/support/disinfections",
    migration: "/cloud/support/migration",
  },
  organization: {
    robots: "/cloud/fm/:orgId/robots",
    robot: "/cloud/fm/:orgId/robots/:robotId",
    users: "/cloud/fm/:orgId/users",
    user: "/cloud/fm/:orgId/users/:userId",
    settings: "/cloud/fm/:orgId/settings",
    disinfections: "/cloud/fm/:orgId/disinfections",
    rooms: "/cloud/fm/:orgId/rooms",
  },
};
