import { Box, Typography } from "@mui/material";
import { ReactElement, useMemo } from "react";
import { GlobalSearchTabs, NoResults } from "./GlobalSearch";

interface Props {
  resultCount: { all: number; visible: number };
  selectedFilter: GlobalSearchTabs;
  children: ReactElement;
}

const MAX_RESULTS_ROBOTS = 12;
const MAX_RESULTS_ORG_USERS = 20;

export const ResultTab = ({ resultCount, selectedFilter, children }: Props) => {
  const maxCount = useMemo(() => {
    let count = 0;

    switch (selectedFilter) {
      case "robots":
        count =
          resultCount.visible < MAX_RESULTS_ROBOTS
            ? resultCount.visible
            : MAX_RESULTS_ROBOTS;
        break;
      case "users":
        count = MAX_RESULTS_ORG_USERS;
        break;
      case "organizations":
        count = MAX_RESULTS_ORG_USERS;
        break;
      default:
        break;
    }

    return count;
  }, [resultCount]);

  return selectedFilter === "all" && resultCount.all !== 0 ? (
    children
  ) : resultCount.all > 0 ? (
    <Box>
      {children}
      {resultCount.all > maxCount ? (
        <Typography
          sx={{ margin: "10px 24px", textAlign: "center" }}
          color={(theme) => theme.palette.grey[500]}
        >
          Showing first {maxCount} {selectedFilter}
        </Typography>
      ) : null}
    </Box>
  ) : (
    <NoResults selectedFilter={selectedFilter} />
  );
};
