import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonProps, Tooltip, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { ConditionalWrapper } from "utils/ConditionalWrapper";
import { ButtonLight } from "../ButtonLight";

type PrimaryAction = {
  label: string;
  onClick?: () => void;
  loading?: boolean | undefined;
  disabled?: boolean;
  color?: ButtonProps["color"];
};

type SecondaryAction = {
  label: string;
  onClick: () => void;
  color?: ButtonProps["color"];
  loading?: boolean | undefined;
  disabled?: boolean;
  toolTip?: string;
};

export interface DrawerFooterProps {
  action?: PrimaryAction;
  secondaryAction?: SecondaryAction;
  error?: string | ReactElement;
  success?: string | ReactElement;
  alertLabel?: string | ReactElement;
  alertSubmitButtonLabel?: string | ReactElement;
  overrideWarning?: boolean;
}

export const DrawerFooter = ({
  secondaryAction,
  action,
  error,
  success,
  alertLabel,
  alertSubmitButtonLabel,
  overrideWarning,
}: DrawerFooterProps) => {
  const [showWarning, setShowWarning] = useState(false);

  const handleSecondaryClick = () => {
    if (overrideWarning) {
      secondaryAction?.onClick();
    } else {
      setShowWarning(true);
    }
  };

  return (
    <Box
      sx={() => ({
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
      })}
    >
      {!success && !error && showWarning ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            backgroundColor: theme.palette.error.main,
            padding: "32px 24px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          })}
        >
          <Typography color="white" mb={6} textAlign="center" maxWidth="70%">
            {alertLabel || "Are you sure?"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <ButtonLight
              variant="contained"
              color="error"
              sx={{ marginRight: 8 }}
              onClick={() => setShowWarning(false)}
            >
              Cancel
            </ButtonLight>
            <ButtonLight variant="outlined" onClick={secondaryAction?.onClick}>
              {alertSubmitButtonLabel || secondaryAction?.label}
            </ButtonLight>
          </Box>
        </Box>
      ) : null}
      {success || error ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            backgroundColor: error
              ? theme.palette.error.light
              : success
                ? theme.palette.success.light
                : undefined,
            padding: 4,
            justifyContent: "center",
          })}
        >
          {error ? (
            <Typography
              variant="body2"
              color={(theme) => theme.palette.error.dark}
              textAlign="center"
            >
              {error}
            </Typography>
          ) : null}
          {success ? (
            <Typography
              variant="body2"
              color={(theme) => theme.palette.success.dark}
              textAlign="center"
            >
              {success}
            </Typography>
          ) : null}
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: secondaryAction ? "space-between" : "flex-end",
          padding: theme.spacing(6),
          backgroundColor: "white",
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        })}
      >
        {secondaryAction ? (
          <ConditionalWrapper
            condition={secondaryAction.disabled || false}
            wrapper={(children: any) => {
              return (
                <Tooltip title={secondaryAction.toolTip} arrow placement="top">
                  {children}
                </Tooltip>
              );
            }}
          >
            <Box>
              <Button
                variant="outlined"
                disabled={
                  secondaryAction.disabled ||
                  showWarning ||
                  secondaryAction?.loading ||
                  action?.loading
                }
                onClick={handleSecondaryClick}
                color={secondaryAction.color || "error"}
                sx={{
                  fontWeight: 500,
                  borderWidth: 2,
                  ":hover": { borderWidth: 2 },
                }}
              >
                {secondaryAction.label}
              </Button>
            </Box>
          </ConditionalWrapper>
        ) : null}
        {action ? (
          <LoadingButton
            disabled={action.disabled || showWarning || action?.loading}
            loading={action.loading}
            variant="contained"
            type="submit"
            onClick={action.onClick}
            color={action.color || undefined}
            sx={{ alignSelf: "flex-end", fontWeight: 500 }}
          >
            {action.label}
          </LoadingButton>
        ) : null}
      </Box>
    </Box>
  );
};
