import { Box, Typography } from "@mui/material";
import { Warning } from "components/atoms/Warning";
import { SimpleTable } from "components/modules/SimpleTable";
import { Organization, Role } from "gql/graphql";
import { getEnvironment } from "utils/UVD/environmentUtils";

export const ReviewUsers = ({
  selectedOrg,
}: {
  selectedOrg: Partial<Organization> | null;
}) => {
  const { oldSystemLink } = getEnvironment();

  return (
    <Box>
      <Warning
        message={`Make sure all users to migrate from ${selectedOrg?.name} are listed below. To ensure we invite the right people, remove internal and obsolete users.`}
        sx={{ marginBottom: 6 }}
      />
      <SimpleTable
        data={selectedOrg?.affiliations || []}
        tableRowProps={{
          sx: { pointerEvents: "none" },
        }}
        noDataMessage="No users in this organization"
        columnConfig={[
          {
            key: "index",
            renderCell: (row: any, index) => index + 1,
            sx: { width: 50 },
          },
          {
            key: "email",
            renderCell: (row: any) => row.identity.email,
          },
          {
            key: "role",
            renderCell: (row: any) =>
              row.roles.map((item: Role) => item.title).join(", "),
          },
        ]}
      />
      <Typography
        color={(theme) => theme.palette.grey[600]}
        mt={6}
        mb={6}
        sx={{ textAlign: "center" }}
      >
        If necessary, make changes to {selectedOrg?.name} in {oldSystemLink} and
        refresh this page before continuing.
      </Typography>
    </Box>
  );
};
