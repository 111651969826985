import { Box } from "@mui/material";
import { PaperBox } from "components/modules/PaperBox";
import { sanitize } from "dompurify";
import { useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { graphql } from "../../gql";
import { LanguageCodes } from "../../gql/graphql";
import { PageHeader } from "../atoms/PageHeader";
import { Spacer } from "../atoms/Spacer";
import { NoMatch } from "./NoMatch";

const GET_CONTENT_PAGE = graphql(`
  query GetContentPages($id: String!) {
    contentPages(id: $id, language: EN) {
      id
      language
      subject
      content
    }
  }
`);

export const ContentPage = () => {
  const { id } = useParams();

  const { data } = useQueryWithSnack(GET_CONTENT_PAGE, {
    variables: {
      id: id ?? "",
    },
  });

  if (!data) {
    return <>Loading...</>;
  }

  let contentPage = data.contentPages.find(
    (o) => o.language === LanguageCodes.En,
  );
  contentPage =
    data.contentPages.find((o) => o.language === LanguageCodes.En) ??
    contentPage;

  if (!contentPage) {
    return <NoMatch />;
  }

  return (
    <Box
      sx={() => ({
        padding: "1.5rem",
      })}
    >
      <Spacer />
      <Box
        sx={() => ({
          margin: "0 auto",
          maxWidth: "114rem",
          width: "100%",
        })}
      >
        <Spacer />
        <PageHeader>
          <>{contentPage.subject}</>
        </PageHeader>
        <Spacer />
        <PaperBox>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(contentPage.content),
            }}
          ></div>
        </PaperBox>
      </Box>
    </Box>
  );
};
