import { CloudTextField } from "../CloudTextField";

interface Props {
  name: string;
  formik: any;
  onChange?: () => void;
  [key: string]: any;
}

export const FormikTextField = (props: Props) => {
  const { formik, helperText, name } = props;

  return (
    <CloudTextField
      {...props}
      value={formik?.values[name] || ""}
      onChange={(e) => {
        formik.setFieldValue(name, e.target.value);
        props.onChange && props.onChange();
      }}
      error={formik?.touched[name] && !!formik?.errors[name]}
      onBlur={() => formik.setFieldTouched(name)}
      helperText={
        formik?.errors[name] && formik?.touched[name]
          ? formik.errors[name]
          : helperText
      }
    />
  );
};
