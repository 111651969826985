import { Box, Button } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { SupportIcon } from "assets/icons/SupportIcon";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { Organization } from "gql/graphql";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { AssignSupportRole } from "./dialogs/AssignSupportRole";
import { InviteUser } from "./dialogs/InviteUser";

interface Props {
  users: any[];
  columns: GridColDef[];
  loading?: boolean;
  organization?: Organization;
}

export const Users = ({ users, columns, loading, organization }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOnSupportPath = isSupportPath(location);

  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [inviteSupporterDialogOpen, setInviteSupporterDialogOpen] =
    useState(false);

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <WidgetHeader
            label={<FormattedMessage id="users" defaultMessage="Users" />}
          />
          <Box>
            {isOnSupportPath ? (
              <Button
                onClick={() => setInviteSupporterDialogOpen(true)}
                variant="outlined"
                size="small"
                sx={{
                  marginRight: 4,
                  svg: {
                    height: 15,
                    width: 13,
                  },
                }}
                startIcon={<SupportIcon />}
              >
                Invite supporter
              </Button>
            ) : null}
            <Button
              variant="outlined"
              size="small"
              onClick={() => setInviteUserDialogOpen(true)}
            >
              Invite user
            </Button>
          </Box>
        </Box>
        <CloudDataGrid
          onRowClick={(params: GridRowParams) => navigate(params.row.id)}
          rows={users}
          columns={columns || []}
          loading={loading}
          pageSize={25}
          initialState={{
            sorting: {
              sortModel: [{ field: "user", sort: "asc" }],
            },
          }}
        />
      </WidgetPaper>
      <InviteUser
        open={inviteUserDialogOpen}
        onClose={() => setInviteUserDialogOpen(false)}
        organization={organization}
      />
      {isOnSupportPath && (
        <AssignSupportRole
          open={inviteSupporterDialogOpen}
          onClose={() => setInviteSupporterDialogOpen(false)}
        />
      )}
    </>
  );
};
