import { ActivityCode } from "gql/graphql";
import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const activityMsg: DefineMsg = defineMessages({
  [ActivityCode.OrganizationCreated]: {
    id: "activity.OrganizationCreated",
    defaultMessage: "Organization <orgLink>{orgName}</orgLink> registered",
  },
  [ActivityCode.OrganizationUpdated]: {
    id: "activity.OrganizationCreated",
    defaultMessage: "Organization <orgLink>{orgName}</orgLink> updated",
  },
  [ActivityCode.OrganizationDeleted]: {
    id: "activity.OrganizationCreated",
    defaultMessage: "Deleted organization",
  },
  [ActivityCode.RoleGranted]: {
    id: "activity.RoleGranted",
    defaultMessage:
      "{isSupportRole, select, true {{role} role assigned to <userLink>{username}</userLink>} other {{role} role in <orgLink>{orgName}</orgLink> assigned to <userLink>{username}</userLink>}}",
  },
  [ActivityCode.RoleRevoked]: {
    id: "activity.RoleRevoked",
    defaultMessage:
      "{role} role in <orgLink>{orgName}</orgLink> removed from <userLink>{username}</userLink>",
  },
  [ActivityCode.RobotCreated]: {
    id: "activity.RobotCreated",
    defaultMessage: "Robot <robotLink>{serialNumber}</robotLink> registered",
  },
  [ActivityCode.RobotTransferred]: {
    id: "activity.RobotTransferred",
    defaultMessage:
      "Robot <robotLink>{serialNumber}</robotLink> {type, select, BOTH {transferred from <fromOrgLink>{fromOrgName}</fromOrgLink> to <toOrgLink>{toOrgName}</toOrgLink>} FROM {transferred from <fromOrgLink>{fromOrgName}</fromOrgLink> to <unassignedRobotsLink>Unassigned Robots</unassignedRobotsLink>} TO {added to <toOrgLink>{toOrgName}</toOrgLink>} other {}}",
  },
  [ActivityCode.IdentityOnboarded]: {
    id: "activity.IdentityOnboardet",
    defaultMessage: "Onboarded successfully",
  },
});
