import { gql, useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { OrganizationAutoComplete } from "components/atoms/autoComplete/OrganizationAutoComplete";
import { UserAutoComplete } from "components/atoms/autoComplete/UserAutoComplete";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudLabel } from "components/atoms/CloudLabel";
import { OrganizationCardContent } from "components/modules/cards/OrganizationCardContent";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { CloudDialog, CloudDialogProps } from "components/modules/CloudDialog";
import { FmRoles } from "components/modules/FmRoles";

import { useFormik } from "formik";
import { graphql } from "gql";
import { Identity, Organization, RoleType } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { isSupportPath } from "utils/privileges/privilegeUtils";

import { FormikAutocomplete } from "components/atoms/formik/FormikAutocomplete";
import { getUserRefetchQueries } from "utils/refetchQueries";
import { useUserDisplayName } from "utils/useUserDisplayName";
import * as Yup from "yup";

export const INVITE = graphql(`
  mutation InviteUser($inviteInputs: [InviteInput!]!, $orgId: String) {
    invite(inviteInputs: $inviteInputs, organizationId: $orgId) {
      id
      email
      affiliations {
        organization {
          name
        }
        roles {
          title
        }
      }
    }
  }
`);

const INVITE_USER_IDENTITY_FRAGMENT = gql`
  fragment InviteUserIdentity on Identity {
    id
    email
    firstName
    lastName
    affiliations {
      id
      organization {
        id
      }
      roles {
        title
      }
    }
    ...UserCardContent
    ...UserDisplayName
  }
  ${UserCardContent.fragments.identity}
  ${useUserDisplayName.fragments.identity}
`;

const INVITE_USER_ORGANIZATION_FRAGMENT = gql`
  fragment InviteUserOrganization on Organization {
    id
    ...OrganizationCardContent
  }
  ${OrganizationCardContent.fragments.organization}
`;

interface Props {
  organization?: Organization | null;
  identity?: Identity;
  defaultRole?: RoleType.Owner | RoleType.Editor | RoleType.Viewer;
  currentUserIsSupport?: boolean;
  hideNavigation?: boolean;
}

type Values = {
  emails: Array<string>;
  role: string;
  organization?: Organization;
};

export const InviteUser = ({
  open,
  onClose,
  organization,
  identity,
  defaultRole,
  hideNavigation,
}: Props & CloudDialogProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const supportPath = isSupportPath(location);
  const displayName = useUserDisplayName(identity);

  const refetchQueries = getUserRefetchQueries(organization?.id, identity?.id);

  const [inviteUser] = useMutation(INVITE, {
    refetchQueries,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (identity) {
      formik.setFieldValue("emails", [identity.email]);
    }
    if (organization) {
      formik.setFieldValue("organization", organization);
    }
    if (defaultRole) {
      formik.setFieldValue("role", defaultRole);
    }
  }, [identity, organization, defaultRole, open]);

  useEffect(() => {
    (() => formik.validateForm())();
  }, [open]);

  const formik = useFormik<Values>({
    initialValues: {
      emails: [],
      role: "",
      organization: undefined,
    },
    validationSchema: Yup.object({
      emails: Yup.lazy((val) =>
        Array.isArray(val)
          ? Yup.array()
              .of(Yup.string())
              .min(
                1,
                intl.formatMessage({
                  id: "required",
                  defaultMessage: "Required",
                }),
              )
          : Yup.string().required(
              intl.formatMessage({
                id: "required",
                defaultMessage: "Required",
              }),
            ),
      ),
      role: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
      organization: Yup.object().required("Organization is required"),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Inviting user...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      const inputs = values.emails.map((email) => ({
        email: email.trim(),
        role: values.role as RoleType,
      }));

      inviteUser({
        variables: {
          inviteInputs: inputs,
          orgId: values.organization?.id || organization?.id,
        },
        onCompleted: (data) => {
          formik.resetForm();

          const multipleInvites = inputs.length > 1;

          enqueueSnackbar(
            multipleInvites
              ? `Invited ${inputs.length} Users to ${values.organization?.name}`
              : `Invited ${displayName || _.first(values.emails)} to ${
                  values.organization?.name
                }`,
            {
              variant: "success",
              actionLabel:
                hideNavigation || multipleInvites
                  ? undefined
                  : "Go to user page",
              onActionClick:
                hideNavigation || multipleInvites
                  ? undefined
                  : () => {
                      if (supportPath) {
                        navigate("/cloud/support/users/" + data.invite[0].id);
                      } else {
                        navigate(
                          `/cloud/fm/${values.organization?.id}/users/${data.invite[0].id}`,
                        );
                      }
                    },
            },
          );
          closeSnackbar(snackbarId);
        },
        onError: () => {
          formik.resetForm();

          const multipleInvites = inputs.length > 1;

          enqueueSnackbar(
            multipleInvites
              ? `Could not invite ${inputs.length} Users to ${values.organization?.name}`
              : `Could not invite ${
                  displayName || _.first(values.emails)
                } to ${values.organization?.name}`,
            {
              variant: "error",
            },
          );
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  return (
    <form>
      <CloudDialog
        header="Invite user"
        open={open}
        onClose={handleClose}
        hideCancel
        submitLabel="Send invitation"
        onSubmit={formik.handleSubmit}
        submitDisabled={formik.isValid === false}
      >
        <CloudDialogContent>
          {identity ? (
            <UserCardContent identity={identity} hideIcon />
          ) : (
            <FormikAutocomplete
              name="emails"
              formik={formik}
              multiple
              component={UserAutoComplete}
              disableAutoSelect
            />
          )}
          <CloudLabel>To</CloudLabel>
          {organization ? (
            <OrganizationCardContent hideIcon organization={organization} />
          ) : (
            <Box mb={2}>
              <OrganizationAutoComplete
                multiple={false}
                placeholder="Organization"
                value={formik.values.organization}
                onChange={(e: any, value: Organization) => {
                  formik.setFieldValue("organization", value);
                }}
                onBlur={() => formik.setFieldTouched("organization")}
                textfieldprops={{
                  error:
                    !!formik.touched.organization &&
                    !!formik.errors.organization,
                  helperText:
                    !!formik.touched.organization &&
                    !!formik.errors.organization
                      ? formik.errors.organization
                      : undefined,
                }}
              />
            </Box>
          )}
          <CloudLabel>Fleet management role</CloudLabel>
          <FmRoles
            organizationId={organization?.id || formik.values.organization?.id}
            radioGroupProps={{
              onBlur: () => formik.setFieldTouched("role"),
            }}
            value={formik.values.role}
            onChange={(role: any) => formik.setFieldValue("role", role)}
          />
        </CloudDialogContent>
      </CloudDialog>
    </form>
  );
};

InviteUser.fragments = {
  identity: INVITE_USER_IDENTITY_FRAGMENT,
  organization: INVITE_USER_ORGANIZATION_FRAGMENT,
};
