import { InMemoryCache, makeVar } from "@apollo/client";

export const tokenLanguageVar = makeVar("");
export const firebaseErrorVar = makeVar("");

export const localeVar = makeVar("");
export const isMenuVisibleVar = makeVar(true);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        tokenLanguage: {
          read() {
            return tokenLanguageVar();
          },
        },
        firebaseErrors: {
          read() {
            return firebaseErrorVar();
          },
        },
        locale: {
          read() {
            return localeVar();
          },
        },
        isMenuVisible: {
          read() {
            return isMenuVisibleVar();
          },
        },
      },
    },
  },
});
