import { Box, Typography, useTheme } from "@mui/material";
import { fontFamilies } from "assets/fonts/fontFamilies";
import { PinIcon, RemainingPinIcon } from "assets/icons/PinIcon";
import { CloudLabel } from "components/atoms/CloudLabel";
import { useMemo } from "react";
import { IoTimerOutline } from "react-icons/io5";
import {
  DisinfectionPositionStatus,
  SelectedDisinfectionReport,
} from "types/types";
import { secondsToMS } from "utils/utilFunctions";
import { getMapPositions } from "utils/UVD/UVDUtils";

interface Props {
  selectedReport: SelectedDisinfectionReport | null;
}

const getPositionText = (value: string) => {
  const stringArr = value.split("");

  if (stringArr.length > 60) {
    return value.slice(0, 60) + " ...";
  } else {
    return value;
  }
};

export const PositionsTab = ({ selectedReport }: Props) => {
  const { completed, failed, remaining } = getMapPositions(selectedReport);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "-24px",
      }}
    >
      {completed.length > 0 ? (
        <>
          <CloudLabel sx={{ marginBottom: "12px" }}>Disinfected</CloudLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {completed.map((item) => {
              return (
                <PositionListItem
                  key={item.name}
                  type={DisinfectionPositionStatus.Disinfected}
                  label={getPositionText(item.name || "")}
                  number={item.order ? item.order : "-"}
                  time={item.time}
                />
              );
            })}
          </Box>
        </>
      ) : null}
      {failed.length > 0 ? (
        <>
          <CloudLabel sx={{ marginBottom: "12px" }}>
            Failed to disinfect
          </CloudLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {failed.map((item) => {
              return (
                <PositionListItem
                  key={item.name}
                  type={DisinfectionPositionStatus.Failed}
                  label={getPositionText(item.name || "")}
                  number={item.order ? item.order : "-"}
                  time={item.time}
                />
              );
            })}
          </Box>
        </>
      ) : null}
      {remaining.length > 0 ? (
        <>
          <CloudLabel sx={{ marginBottom: "12px" }}>Remaining</CloudLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {remaining.map((item) => {
              return (
                <PositionListItem
                  key={item.name}
                  type={DisinfectionPositionStatus.Remaining}
                  label={getPositionText(item.name || "")}
                  number={item.order ? item.order : "-"}
                  time={item.time}
                />
              );
            })}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

const PositionListItem = ({
  type,
  label,
  number,
  time,
}: {
  type: DisinfectionPositionStatus;
  label: string;
  number: number | string;
  time?: number | null;
}) => {
  const theme = useTheme();

  const pinColor = useMemo(() => {
    switch (type) {
      case DisinfectionPositionStatus.Failed:
        return "#D92E2E";
      case DisinfectionPositionStatus.Disinfected:
        return theme.palette.primary.main;
      case DisinfectionPositionStatus.Remaining:
        return null; // does not have a color
      default:
        return theme.palette.grey[600];
    }
  }, [type, theme]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={() => ({
          position: "relative",
          width: "fit-content",
          "& svg > *": { color: pinColor },
        })}
      >
        {type === DisinfectionPositionStatus.Remaining ? (
          <RemainingPinIcon />
        ) : (
          <PinIcon />
        )}
        <Typography
          sx={{
            fontFamily: fontFamilies.industryBold,
            fontSize: "10px",
            lineHeight: "12px",
            position: "absolute",
            top: 6,
            width: "100%",
            textAlign: "center",
            color:
              type === DisinfectionPositionStatus.Remaining
                ? theme.palette.primary.main
                : "white",
          }}
        >
          {number}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: 2,
        }}
      >
        <Typography>{label}</Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 2,
            "& svg > *": {
              color: theme.palette.grey[400],
            },
          })}
        >
          <IoTimerOutline size={20} style={{ marginRight: 4 }} />
          <Typography color={(theme) => theme.palette.grey[400]}>
            {secondsToMS(time ?? 0)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
