import { Box } from "@mui/material";
import { WidgetWrapper } from "components/atoms/WidgetWrapper";
import { CloudSelector } from "components/modules/CloudSelector";
import { useState } from "react";
import { IoWarning } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { SelectedDisinfectionReport } from "types/types";
import { getInterruptionArr } from "utils/UVD/UVDUtils";
import { DetailsTab } from "./DetailsTab";
import { InterruptionsTab } from "./InterruptionsTab";
import { PositionsTab } from "./PositionsTab";

export const ReportTabs = ({
  selectedReport,
}: {
  selectedReport: SelectedDisinfectionReport;
}) => {
  const { activeInterruptions } = getInterruptionArr(
    selectedReport?.interruptions,
  );
  const [selectedTab, setSelectedTab] = useState(
    selectedReport?.type === "Predefined" ? "positions" : "details",
  );

  const tabArr = [
    {
      label: <FormattedMessage id="details" defaultMessage="Details" />,
      value: "details",
      onClick: setSelectedTab,
    },
  ]
    .concat(
      selectedReport?.type === "Predefined"
        ? [
            {
              label: (
                <FormattedMessage id="positions" defaultMessage="Positions" />
              ),
              value: "positions",
              onClick: setSelectedTab,
            },
          ]
        : [],
    )
    .concat(
      activeInterruptions.length > 0
        ? [
            {
              label: (
                <Box display="flex" alignItems="center" component="span">
                  <IoWarning size={16} style={{ marginRight: 4 }} />
                  <FormattedMessage
                    id="interruptions"
                    defaultMessage="Interruptions"
                  />
                </Box>
              ),
              value: "interruptions",
              onClick: setSelectedTab,
            },
          ]
        : [],
    );

  return (
    <WidgetWrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        maxHeight: 500,
        flexGrow: 1,
        boxSizing: "border-box",
      }}
    >
      <CloudSelector
        style={{ margin: "0 auto", marginBottom: "24px" }}
        elements={tabArr}
        selectedValue={selectedTab}
      />
      {selectedTab === "details" ? (
        <DetailsTab selectedReport={selectedReport} />
      ) : null}
      {selectedTab === "positions" ? (
        <PositionsTab selectedReport={selectedReport} />
      ) : null}
      {selectedTab === "interruptions" ? (
        <InterruptionsTab interruptions={selectedReport?.interruptions} />
      ) : null}
    </WidgetWrapper>
  );
};
