import { Box } from "@mui/material";
import { FormikTextField } from "components/atoms/formik";
import { useIntl } from "react-intl";

interface Props {
  formik: any;
  disableForm?: boolean;
}

export const OrgOwnerEmail = ({ formik, disableForm }: Props) => {
  const intl = useIntl();

  return (
    <Box>
      <FormikTextField
        name="email"
        disabled={disableForm}
        formik={formik}
        label={intl.formatMessage({
          id: "create.organization.ownerEmail",
          defaultMessage: "Owner email address",
        })}
        placeholder={intl.formatMessage({
          id: "create.organization.ownerEmail",
          defaultMessage: "Owner email address",
        })}
      />
    </Box>
  );
};
