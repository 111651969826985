import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  selected: boolean;
  label: string | ReactElement;
  onClick: () => void;
  disabled?: boolean;
}

export const SelectorButton = ({
  selected,
  label,
  onClick,
  disabled,
}: Props) => {
  return (
    <Box
      onClick={onClick}
      component="button"
      disabled={disabled}
      sx={(theme) => ({
        marginRight: "2px",
        padding: "7px 16px",
        borderRadius: "5px",
        backgroundColor: disabled
          ? theme.palette.grey[100]
          : selected
            ? theme.palette.primary.main
            : "transparent",
        border: "none",
        cursor: disabled ? "auto" : selected ? "auto" : "pointer",
        ":hover p": {
          color: disabled
            ? "auto"
            : selected
              ? "white"
              : theme.palette.grey[900],
        },
      })}
    >
      <Typography
        variant="body2"
        color={(theme) =>
          selected && !disabled ? "white" : theme.palette.grey[500]
        }
        sx={{ fontSize: "13px", lineHeight: "16px" }}
      >
        {label}
      </Typography>
    </Box>
  );
};
