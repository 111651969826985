import { Grid } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { UnassignedRobots } from "components/organisms/UnassignedRobots";

export const UnassignedRobotListPage = () => {
  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={[{ label: "Support" }, { label: "Unassigned Robots" }]}
      />
      <Grid item xs={12}>
        <UnassignedRobots />
      </Grid>
    </Grid>
  );
};
