import { canDo } from "@blue-ocean-robotics/cloud-library";
import { Grid, Skeleton } from "@mui/material";
import { InlineCardEmptyView } from "components/atoms/InlineCardEmptyView";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { RoleCard } from "components/modules/cards/RoleCard";
import { Affiliation, Privilege, Role, RoleType } from "gql/graphql";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { roles } from "trans/definedMessages/roleMessages";
import { InviteUser } from "../dialogs/InviteUser";
import { EditOrganizationRole } from "../panels/EditOrganizationRole";

interface Props {
  loading: boolean;
  currentUserIsSupport: boolean;
  actor: any;
  identity: any;
  userIsMe: boolean;
  showAllOrgs: boolean;
  readonly?: boolean;
}

export const UserOrganizationRoles = ({
  readonly,
  loading,
  currentUserIsSupport,
  actor,
  showAllOrgs,
  identity,
  userIsMe,
}: Props) => {
  const { orgId } = useParams();
  const intl = useIntl();

  const [editOrgRoleOpen, setEditOrgRoleOpen] = useState(false);
  const [selectedAffiliation, setSelectedAffiliation] = useState(null);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

  const affiliations = useMemo(() => {
    if (showAllOrgs) {
      return identity?.affiliations;
    } else {
      return identity?.affiliations?.filter(
        (item: Affiliation) => item.organization?.id === orgId,
      );
    }
  }, [showAllOrgs, identity?.affiliations]);

  const currentOrg = orgId
    ? affiliations?.find((item: any) => item.organization.id === orgId)
        .organization
    : null;

  const otherAffiliationsText = useMemo(() => {
    if (identity?.affiliations?.length - 1 > 0 && !showAllOrgs) {
      return `+${
        identity?.affiliations?.length - 1
      } other Roles outside ${currentOrg?.name}`;
    } else return null;
  }, [affiliations, currentOrg]);

  if (loading) return <Skeleton />;

  return (
    <>
      <WidgetPaper>
        <WidgetHeader
          label="Organizations"
          buttonLabel={readonly || !currentUserIsSupport ? undefined : "Add"}
          onButtonClick={
            readonly || !currentUserIsSupport
              ? undefined
              : () => setInviteUserDialogOpen(true)
          }
        />
        <Grid container mt={0} spacing={4} display="flex">
          {affiliations?.length > 0 ? (
            affiliations?.map((item: any) => {
              const canEditRole =
                item.roles.length > 0 && !readonly
                  ? canDo(
                      actor,
                      item.roles[0],
                      Privilege.GenericRoleManagement,
                      item.organization?.id,
                    )
                  : false;

              return (
                <Grid
                  key={item?.organization?.id}
                  item
                  xs={6}
                  display="flex"
                  sx={{ "& .MuiGrid-root": { flexGrow: 1 } }}
                >
                  <RoleCard
                    disabled={!canEditRole}
                    header={item.organization?.name}
                    description={item.roles
                      .map((item: Role) =>
                        intl.formatMessage(roles[item.title as RoleType]),
                      )
                      .join(", ")}
                    iconType="settings"
                    onClick={() => {
                      setSelectedAffiliation(item);
                      setEditOrgRoleOpen(true);
                    }}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} display="flex">
              <InlineCardEmptyView
                sx={{ width: "100%", maxWidth: "100%" }}
                label="No organizations"
              />
            </Grid>
          )}
          {otherAffiliationsText ? (
            <Grid item xs={6} display="flex">
              <InlineCardEmptyView
                sx={{ width: "100%", maxWidth: "100%" }}
                label={otherAffiliationsText}
              />
            </Grid>
          ) : null}
        </Grid>
      </WidgetPaper>
      {selectedAffiliation ? (
        <EditOrganizationRole
          open={editOrgRoleOpen}
          onClose={() => setEditOrgRoleOpen(false)}
          selectedAffiliation={selectedAffiliation}
          identity={identity}
          userIsMe={userIsMe}
        />
      ) : null}
      {inviteUserDialogOpen ? (
        <InviteUser
          identity={identity}
          organization={currentOrg}
          open={inviteUserDialogOpen}
          onClose={() => setInviteUserDialogOpen(false)}
          currentUserIsSupport={currentUserIsSupport}
          hideNavigation
        />
      ) : null}
    </>
  );
};
