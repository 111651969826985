import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { CloudLabel } from "./CloudLabel";

type CloudTextFieldProps = {
  label?: string | ReactElement;
  wrapperStyle?: any;
} & TextFieldProps;

export const CloudTextField = ({
  label,
  value,
  onChange,
  placeholder,
  wrapperStyle,
  fullWidth = false,
  type,
  ...props
}: CloudTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: fullWidth ? 1 : 0,
        ...wrapperStyle,
      }}
    >
      {label ? <CloudLabel>{label}</CloudLabel> : null}
      <TextField
        fullWidth={fullWidth}
        value={value || undefined}
        onChange={onChange}
        placeholder={placeholder}
        type={
          type === "password" ? (showPassword ? "text" : "password") : "text"
        }
        {...props}
        InputProps={{
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  disabled={props.disabled}
                >
                  {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                </IconButton>
              </InputAdornment>
            ) : undefined,
          ...props?.InputProps,
          disableUnderline: true,
        }}
      />
    </Box>
  );
};
