import { useMutation } from "@apollo/client";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CloudTextField } from "components/atoms/CloudTextField";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { SUPPORT_MENU_QUERY } from "components/modules/menuItems/SupportMenuItem";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { ASSIGNED_ROBOTS_QUERY } from "components/organisms/AssignedRobots";
import { MigrationDialog } from "components/organisms/migration/MigrationDialog";
import { MigrationGuideWidget } from "components/organisms/migration/MigrationGuideWidget";
import { ORG_LIST_QUERY } from "components/organisms/Organizations";
import { graphql } from "gql";
import { Organization, Privilege } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useState } from "react";
import { IoColorWand, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getEnvironment } from "utils/UVD/environmentUtils";
import { ForbiddenPage } from "./ForbiddenPage";

const QUERY = graphql(`
  query MigrationPage {
    LEGACY__Organizations {
      id
      name
      robotAffiliations {
        robot {
          serialNumber
        }
        active {
          serialNumber
        }
      }
      domainVerifications {
        hostname
      }
      affiliations {
        roles {
          title
        }
        identity {
          email
        }
      }
    }
  }
`);

const MIGRATE = graphql(`
  mutation MigrateOrg($orgId: String, $countryCode: CountryCodes) {
    LEGACY__Migrate(organizationId: $orgId, countryCode: $countryCode) {
      id
      name
    }
  }
`);

export const MigrationPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const canMigrate = usePrivilege(Privilege.Migrate).hasPrivilege;

  const [search, setSearch] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [page, setPage] = useState(0);

  const { data, error, loading, refetch } = useQueryWithSnack(QUERY);
  const orgs = (data?.LEGACY__Organizations || []).filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase()),
  );

  const { envCallName } = getEnvironment();

  const [migrateOrganization] = useMutation(MIGRATE, {
    refetchQueries: [
      { query: QUERY },
      { query: SUPPORT_MENU_QUERY },
      { query: ASSIGNED_ROBOTS_QUERY },
      { query: ORG_LIST_QUERY },
    ],
  });

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrg(null);
  };

  const handleMigrate = (selectedOrgCountry: any) => {
    setDialogOpen(false);
    const snackbarId = enqueueSnackbar(`Migrating ${selectedOrg?.name}...`, {
      variant: "loading",
      persist: true,
    });

    migrateOrganization({
      variables: {
        orgId: selectedOrg?.id,
        countryCode: selectedOrgCountry,
      },
      onCompleted: () => {
        enqueueSnackbar(`${selectedOrg?.name} was migrated`, {
          variant: "success",
          actionLabel: "Go to organization",
          onActionClick: () =>
            navigate(`/cloud/support/organizations/${selectedOrg?.id}`),
        });
        setSelectedOrg(null);
        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(`${selectedOrg?.name} could not be migrated`, {
          variant: "success",
        });
        setSelectedOrg(null);
        closeSnackbar(snackbarId);
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      maxWidth: 300,
      minWidth: 150,
      flex: 1,
      headerName: "Name",
      valueGetter: (params) => params.row?.name,
      renderCell: (params) => <OrganizationCell organization={params.row} />,
    },
    {
      field: "robots",
      maxWidth: 120,
      flex: 1,
      headerName: "Robots",
      align: "right",
      headerAlign: "right",
      valueGetter(params) {
        return params.row.robotAffiliations?.length || 0;
      },
    },
    {
      field: "users",
      maxWidth: 120,
      flex: 1,
      headerName: "Users",
      align: "right",
      headerAlign: "right",
      valueGetter(params) {
        return (params.row.affiliations || [])?.length || 0;
      },
    },
    {
      field: "domains",
      maxWidth: 120,
      flex: 1,
      headerName: "Domains",
      align: "right",
      headerAlign: "right",
      valueGetter(params) {
        return (params.row.domainVerifications || [])?.length || 0;
      },
    },
    {
      field: "migrateAction",
      align: "right",
      maxWidth: 150,
      flex: 1,
      headerName: "",
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setDialogOpen(true);
            setSelectedOrg(params.row);
          }}
          variant="contained"
          size="small"
          startIcon={<IoColorWand size={13} />}
        >
          Migrate
        </Button>
      ),
    },
  ];

  const forbidden = getIsForbidden(error);

  if (!canMigrate || error) {
    return null;
  }

  if (forbidden) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Grid container spacing={6}>
        <HeaderGrid
          crumbs={
            loading
              ? []
              : [
                  { label: "Support" },
                  {
                    label: "Migrate from FM 1.0",
                  },
                ]
          }
        />
        <Grid item xs={12}>
          <MigrationGuideWidget />
        </Grid>
        <Grid item xs={12}>
          <WidgetPaper>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <WidgetHeader
                label="Organizations"
                description={`${orgs.length} organizations found in FM 1.0-${envCallName}`}
              />
              <Button onClick={() => refetch()} variant="outlined" size="small">
                Refresh
              </Button>
            </Box>
            <CloudTextField
              sx={{ mt: 4, width: "50%" }}
              placeholder="Organization name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setPage(0)}
              InputProps={{
                startAdornment: (
                  <>
                    <IoSearch size={20} color={theme.palette.grey[500]} />
                  </>
                ),
              }}
            />
            <CloudDataGrid
              rows={orgs}
              columns={columns}
              loading={loading}
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }],
                },
              }}
              paginationMode="client"
              pageSize={25}
              page={page}
              onPageChanged={setPage}
            />
          </WidgetPaper>
        </Grid>
      </Grid>
      <MigrationDialog
        selectedOrg={selectedOrg}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleMigrate}
      />
    </>
  );
};
