import { Paper, PaperProps, SxProps, Theme } from "@mui/material";
import _ from "lodash";

export const PaperBox = ({ children, ...rest }: PaperProps) => {
  rest.sx = () => ({
    ..._.merge(
      {
        padding: "1rem",
      } as SxProps<Theme>,
      rest.sx,
    ),
  });

  return <Paper {...rest}>{children}</Paper>;
};
