import { Box, IconButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

interface MenuTitleProps {
  title: string | ReactElement;
  icon?: ReactElement;
  orgId?: string;
  hideSettings?: boolean;
}

export const MenuTitle = ({
  title,
  orgId,
  hideSettings = false,
}: MenuTitleProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const settingsPageActive =
    location.pathname === `/cloud/fm/${orgId}/settings`;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 0px 0px 0px",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.grey[700],
            fontWeight: 700,
            textTransform: "uppercase",
            lineHeight: "20px",
            padding: "8px 0px",
            maxWidth: 180,
          })}
        >
          {title}
        </Typography>
        {hideSettings ? null : (
          <IconButton
            sx={(theme) => ({
              minWidth: 38,
              minHeight: 38,
              transition: "none",
              background: settingsPageActive ? "white" : "auto",
              "svg > *": {
                color: settingsPageActive
                  ? theme.palette.primary.main
                  : theme.palette.grey[700],
              },
              ":hover": {
                transition: "none",
                background: "white",
                "svg > *": {
                  color: theme.palette.primary.main,
                },
              },
            })}
            onClick={() => navigate(`fm/${orgId}/settings`)}
          >
            <IoSettingsOutline size={20} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
