import { LoginHeader } from "components/atoms/LoginHeader";
import { CloudHorizontalStepper } from "components/atoms/stepper/CloudHorizontalStepper";
import { CloudSteps } from "components/atoms/stepper/CloudStepper";
import { graphql } from "gql";
import { Affiliation, Identity } from "gql/graphql";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOwnerAffiliationsPending } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { FormWrapper } from "../login/LoginDefault";
import { SetupOrganization } from "../login/SetupOrganization";
import { SetupProfile } from "../login/SetupProfile";

const ONBOARDING_QUERY = graphql(`
  query OnboardingFlow {
    me {
      id
      firstName
      lastName
      email
      inviteState
      affiliations {
        id
        organization {
          id
          name
          country
          activationState
        }
        roles {
          title
          date
        }
      }
    }
  }
`);

export const OnboardingFlow = () => {
  const navigate = useNavigate();

  const [stepIndex, setStepIndex] = useState(0);

  const { data, loading } = useQueryWithSnack(ONBOARDING_QUERY);

  const stepHeader = () => {
    if (steps[stepIndex]?.label === "setup-profile") {
      return "Setup your profile";
    } else return "Setup your organization";
  };

  const me = data?.me as Identity | undefined;

  const ownerAffiliations = useMemo(() => {
    if (me?.affiliations) {
      return getOwnerAffiliationsPending(me.affiliations);
    }
    return [];
  }, [me?.affiliations]);

  const setupProfile = me?.firstName === null;

  const steps = useMemo(() => {
    let stepsArr = [] as CloudSteps;

    if (me?.firstName === null && me?.lastName === null) {
      stepsArr = stepsArr.concat({
        hidden: setupProfile === false,
        label: "setup-profile",
        component: () => (
          <SetupProfile
            user={me}
            buttonLabel={
              ownerAffiliations.length > 0
                ? `Complete onboarding for ${
                    _.first(ownerAffiliations)?.organization?.name ||
                    "your organization(s)"
                  }`
                : "Go to UVD Fleet"
            }
            onComplete={() => {
              if (ownerAffiliations.length > 0) {
                setStepIndex(stepIndex + 1);
              } else {
                navigate("/");
              }
            }}
          />
        ),
      });
    }
    if (ownerAffiliations?.length > 0) {
      stepsArr = stepsArr.concat(
        ownerAffiliations?.length > 0
          ? ownerAffiliations.map((aff: Affiliation, index: number) => ({
              hidden: false,
              label: "setup-org" + aff.id,
              component: () => (
                <SetupOrganization
                  key={aff.id}
                  allAffiliations={ownerAffiliations}
                  affiliation={aff}
                  buttonLabel={`Finish onboarding`}
                  onComplete={() => {
                    if (index + 1 === ownerAffiliations.length) {
                      navigate(`/cloud`);
                    } else {
                      setStepIndex(index + 1);
                    }
                  }}
                />
              ),
            }))
          : [],
      );
    }

    return stepsArr.filter((item) => !item.hidden);
  }, [me, ownerAffiliations]);

  useEffect(() => {
    if (steps.length === 0 && !loading) {
      navigate("/cloud");
    }
  }, [steps.length, loading]);

  return (
    <FormWrapper>
      <LoginHeader>{stepHeader()}</LoginHeader>
      {steps.length > 1 ? (
        <CloudHorizontalStepper
          hideLabels
          wrapperStyle={{ alignSelf: "center" }}
          stepIndex={stepIndex}
          steps={steps}
        />
      ) : null}
      {steps[stepIndex]?.component?.()}
    </FormWrapper>
  );
};
