import { OrganizationAvatar } from "components/atoms/avatars/OrganizationAvatar";
import { SupportAvatar } from "components/atoms/avatars/SupportAvatar";
import { InlineCard, InlineCardProps } from "components/atoms/InlineCard";
import { useMemo } from "react";
import { CloudCardContent, CloudCardContentProps } from "./CloudCardContent";

interface RoleCardProps extends CloudCardContentProps {
  type?: "support" | "organization";
  onClick: () => void;
}

export const RoleCard = ({
  iconType,
  onClick,
  header,
  description,
  type = "organization",
  ...props
}: RoleCardProps & InlineCardProps) => {
  const avatar = useMemo(() => {
    switch (type) {
      case "support":
        return <SupportAvatar sx={{ marginRight: 2 }} />;
      default:
        return (
          <OrganizationAvatar
            orgId={header?.toString()}
            sx={{ marginRight: 2 }}
          />
        );
    }
  }, [type]);

  return (
    <InlineCard
      onClick={props.disabled ? undefined : onClick}
      disabled={props.disabled}
    >
      <CloudCardContent
        header={header || "-"}
        description={description}
        iconType={iconType}
        avatar={avatar}
        hideIcon={props.disabled || props.hideIcon}
        {...props}
      />
    </InlineCard>
  );
};
