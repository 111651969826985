import { Box, Tooltip, Typography } from "@mui/material";
import { WarningYellowIcon } from "assets/icons/WarningYellowIcon";
import { Disinfection } from "gql/graphql";
import _ from "lodash";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ConditionalWrapper } from "utils/ConditionalWrapper";
import { getMapPositions } from "utils/UVD/UVDUtils";

export const DisinfectedCell = ({
  disinfection,
}: {
  disinfection: Disinfection;
}) => {
  const type = disinfection?.type;
  let config = { value: "", postfix: "", warning: false };

  const interruptionCount = useMemo(() => {
    return disinfection?.interruptions
      ? _.sum(
          Object.values(disinfection?.interruptions).filter((item) =>
            _.isNumber(item),
          ),
        )
      : 0;
  }, [disinfection?.interruptions]);

  switch (type) {
    case "Stationary":
    case "Remote controlled":
      config = {
        ...config,
        value: disinfection?.uvcLightDuration
          ? Math.round((disinfection?.uvcLightDuration || 0) / 60).toString()
          : "0",
        postfix: "min",
        warning: interruptionCount > 0,
      };
      break;
    case "Predefined":
      const { completed, all } = getMapPositions(disinfection);

      config = {
        ...config,
        value: `${completed.length}/${all.length}`,
        postfix: "positions",
        warning: interruptionCount > 0,
      };
      break;
    default:
      break;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {config.warning === true ? (
          <ConditionalWrapper
            condition={interruptionCount > 0}
            wrapper={(children: any) => {
              return (
                <Tooltip
                  title={
                    <FormattedMessage
                      id="interruptions-count"
                      defaultMessage={`{numInterruptions, plural, =1 {1 interruption} other {# interruptions}}`}
                      values={{
                        numInterruptions: interruptionCount,
                      }}
                    />
                  }
                  arrow
                  placement="top"
                >
                  {children}
                </Tooltip>
              );
            }}
          >
            <Box sx={{ ":hover": { cursor: "pointer" } }}>
              <WarningYellowIcon />
            </Box>
          </ConditionalWrapper>
        ) : null}

        <Typography textAlign={"right"} ml={1}>
          {config.value}
        </Typography>
      </Box>
      <Typography
        textAlign={"right"}
        fontSize={10}
        sx={{ lineHeight: "12px" }}
        color={(theme) => theme.palette.grey[400]}
      >
        {config.postfix}
      </Typography>
    </Box>
  );
};
