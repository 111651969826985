import { ListSectionHeader } from "components/atoms/ListSectionHeader";
import { UvdRobot } from "gql/graphql";
import { useNavigate } from "react-router-dom";
import { RobotCardContent } from "../cards/RobotCardContent";
import { ResultWrapper } from "./GlobalSearch";

interface Props {
  assignedRobotResults: any;
  unassignedRobotResults: any;
  isSupporter: boolean;
  selectedFilter: string;
  onClose: () => void;
}

export const RobotResults = ({
  assignedRobotResults,
  unassignedRobotResults,
  isSupporter,
  selectedFilter,
  onClose,
}: Props) => {
  const navigate = useNavigate();

  const showUnassignedRobotResults =
    isSupporter &&
    (selectedFilter === "all" || selectedFilter === "robots") &&
    unassignedRobotResults?.length > 0;

  const handleNavigation = (obj: any) => {
    if (isSupporter) {
      navigate("/cloud/support/robots/" + obj.id);
    } else {
      navigate(`/cloud/fm/${obj?.active?.organization?.id}/robots/` + obj?.id);
    }
  };

  return (
    <>
      {assignedRobotResults.length > 0 ? (
        <>
          <ListSectionHeader>Robots</ListSectionHeader>
          {assignedRobotResults.map((robot: UvdRobot) => {
            return (
              <ResultWrapper
                key={robot.id}
                onClick={() => {
                  onClose();
                  handleNavigation(robot);
                }}
              >
                <RobotCardContent
                  robot={robot}
                  hideIcon
                  hideAvatar={false}
                  wrapperStyle={{ padding: "8px 0px" }}
                  description={robot?.active?.organization?.name}
                />
              </ResultWrapper>
            );
          })}
        </>
      ) : null}
      {showUnassignedRobotResults && unassignedRobotResults.length > 0 ? (
        <>
          <ListSectionHeader>Unassigned Robots</ListSectionHeader>
          {unassignedRobotResults.map((robot: UvdRobot) => {
            return (
              <ResultWrapper
                key={robot.id}
                onClick={() => {
                  onClose();
                  handleNavigation(robot);
                }}
              >
                <RobotCardContent
                  robot={robot}
                  hideIcon
                  hideAvatar={false}
                  wrapperStyle={{ padding: "8px 0px" }}
                  description={robot?.active?.organization?.name}
                />
              </ResultWrapper>
            );
          })}
        </>
      ) : null}
    </>
  );
};
