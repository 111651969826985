import { useSubscription } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { RobotListCard } from "components/modules/RobotListCard";
import { BatteryCell } from "components/modules/tableCells/BatteryCell";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { RobotCell } from "components/modules/tableCells/RobotCell";
import { TechnicalServiceNeededCell } from "components/modules/tableCells/TechnicalServiceNeededCell";
import { UVDRobotStatusCell } from "components/modules/tableCells/UVDRobotStatusCell";
import { ForbiddenPage } from "components/pages/ForbiddenPage";
import { graphql } from "gql";
import { IRobot, UvdRobot } from "gql/graphql";

import _ from "lodash";
import { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { ON_ROBOT_CHANGED } from "./UnassignedRobots";

export const ASSIGNED_ROBOTS_QUERY = graphql(`
  query AssignedRobots($organizationId: String) {
    robots(isActive: true, organizationId: $organizationId) {
      robots {
        id
        serialNumber
        robotType
        active {
          organization {
            id
            name
            country
          }
        }
        ... on UvdRobot {
          status {
            batteryMergerPercentage
            lastTimeActive
            job {
              state
              type
            }
          }
          version {
            seto
          }
        }
      }
    }
  }
`);

const columns: GridColDef[] = [
  {
    field: "robot",
    maxWidth: 250,
    minWidth: 150,
    flex: 1,
    headerName: "Robot",
    valueGetter: (params) => params.row?.serialNumber,
    renderCell: (params: GridRenderCellParams) => (
      <RobotCell robot={params.row} showStatusDot disableHoverOnRobotName />
    ),
  },
  {
    field: "organization",
    maxWidth: 250,
    minWidth: 150,
    flex: 1,
    headerName: "Organization",
    valueGetter: (params) => params.row.active?.organization?.name,
    renderCell: (params: GridRenderCellParams) => (
      <OrganizationCell
        organization={params.row.active?.organization}
        hideAvatar
      />
    ),
  },
  {
    field: "battery",
    flex: 1,
    maxWidth: 200,
    headerName: "Battery",
    valueGetter: (params) => params.row?.status?.batteryMergerPercentage,
    renderCell: (params) => (
      <BatteryCell
        robot={params.row}
        hideBattery={false}
        batteryMergerPercentage={params.row?.status?.batteryMergerPercentage}
      />
    ),
  },
  {
    field: "status",
    flex: 1,
    maxWidth: 250,
    headerName: "Status",
    sortable: false,
    renderCell: (params) => <UVDRobotStatusCell robot={params.row} />,
  },
  {
    field: "technical-service",
    headerName: "Technical Service",
    renderCell: TechnicalServiceNeededCell,
    maxWidth: 150,
    flex: 1,
  },
  {
    field: "sw-version",
    maxWidth: 100,
    flex: 1,
    headerName: "SW Version",
    align: "right",
    headerAlign: "right",
    valueGetter(params) {
      return params.row.version?.seto || "";
    },
  },
];

export const AssignedRobots = ({
  columns: columnsProps,
  label,
  showGrid = false,
}: {
  columns?: GridColDef[];
  label?: string | ReactElement;
  showGrid?: boolean;
}) => {
  const navigate = useNavigate();
  const { orgId } = useParams();

  const { data, loading, error } = useQueryWithSnack(ASSIGNED_ROBOTS_QUERY, {
    variables: {
      organizationId: orgId,
    },
  });

  const robots = data?.robots.robots as IRobot[] | undefined;

  const sub = useSubscription(ON_ROBOT_CHANGED);
  useEffect(() => {
    if (robots && sub.data && sub.data.robotChanged) {
      const subRobot = sub.data.robotChanged as UvdRobot;
      const foundRobot = robots.find((o) => o.id == subRobot.id);
      if (foundRobot) {
        _.merge(foundRobot, subRobot);
      }
    }
  }, [robots, sub.data]);

  if (getIsForbidden(error)) return <ForbiddenPage />;

  if (showGrid) {
    return (
      <Grid container spacing={6}>
        {(robots ?? []).map((robot) => (
          <RobotListCard
            key={robot.id}
            robot={robot}
            onClick={() => navigate(robot.id)}
          />
        ))}
      </Grid>
    );
  } else {
    return (
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WidgetHeader
            label={
              label ?? <FormattedMessage id="robots" defaultMessage="Robots" />
            }
          />
        </Box>
        <CloudDataGrid
          onRowClick={(params: GridRowParams) => navigate(params.row.id)}
          rows={robots ?? []}
          columns={columnsProps ?? columns}
          loading={loading}
          pageSize={25}
          initialState={{
            sorting: {
              sortModel: [{ field: "robot", sort: "asc" }],
            },
          }}
        />
      </WidgetPaper>
    );
  }
};
