import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const filterOptionMsg: DefineMsg = defineMessages({
  serialNumbers: { id: "filterOption.serialNumbers", defaultMessage: "Robot" },
  rooms: { id: "filterOption.rooms", defaultMessage: "Room" },
  departments: { id: "filterOption.departments", defaultMessage: "Department" },
  operators: { id: "filterOption.operators", defaultMessage: "Operator" },
  disinfectionModes: {
    id: "filterOption.disinfectionModes",
    defaultMessage: "Mode",
  },
  disinfectionStatus: {
    id: "filterOption.disinfectionStatus",
    defaultMessage: "Status",
  },
  interruption: {
    id: "filterOption.interruptionReasons",
    defaultMessage: "Interruption reason",
  },
  organizationIds: {
    id: "filterOption.organizationIds",
    defaultMessage: "Organization",
  },
  robotTypes: {
    id: "filterOption.robotTypes",
    defaultMessage: "Robot type",
  },
  organizationCountries: {
    id: "filterOption.organizationCountries",
    defaultMessage: "Organization country",
  },
});
