import { styled } from "@mui/system";
import { useConfig } from "config/configHook";
import { CSSProperties } from "react";

const LogoImg = styled("img")(() => ({
  height: "inherit",
  display: "block",
  margin: "auto",
}));

const BorBanner = styled("div")(() => ({
  height: "67px",
  opacity: ".25",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  marginTop: 32,
}));

const BorWing = styled("div")(() => ({
  height: "2px",
  backgroundColor: "black",
  flexGrow: 1,
  marginTop: "14px",
  opacity: ".25",
}));

const BorLogo = styled(LogoImg)(() => ({
  filter: "brightness(0)",
  padding: "0 14px",
  width: "75px",
  height: "auto",
}));

interface Props {
  style?: CSSProperties;
}

export const BORBanner = ({ style }: Props) => {
  const config = useConfig();

  return (
    <BorBanner style={style}>
      <BorWing />
      <BorLogo src={config.logo?.borLogo} />
      <BorWing />
    </BorBanner>
  );
};
