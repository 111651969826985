import { Box, Divider, Typography } from "@mui/material";
import { format, isThisMonth } from "date-fns";
import { useMemo } from "react";
import { IoChevronBack, IoChevronForward, IoOptions } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { utcDateToLocaleDate } from "utils/utilFunctions";
import { FilterButton, FilterIconButton } from "./filters/FilterButton";

interface Props {
  start: Date;
  filterCount: number;
  onOpenDrawer: () => void;
  onBackClick: () => void;
  onForwardClick: () => void;
  onTodayClick: () => void;
}

export const DisinfectionFilterButtons = ({
  start,
  filterCount,
  onOpenDrawer,
  onBackClick,
  onForwardClick,
  onTodayClick,
}: Props) => {
  const disableForwardButton = useMemo(() => {
    return isThisMonth(utcDateToLocaleDate(start));
  }, [start]);

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      <FilterButton
        disabled={disableForwardButton}
        onClick={onTodayClick}
        sx={{ mr: 6 }}
      >
        <FormattedMessage id="today" defaultMessage="Today" />
      </FilterButton>
      <FilterIconButton
        onClick={onBackClick}
        sx={(theme) => ({ marginRight: theme.spacing(2) })}
      >
        <IoChevronBack size={16} />
      </FilterIconButton>
      <FilterIconButton
        disabled={disableForwardButton}
        onClick={onForwardClick}
      >
        <IoChevronForward size={16} />
      </FilterIconButton>

      <Typography
        sx={(theme) => ({
          fontSize: "15px",
          lineHeight: "24px",
          marginRight: "24px",
          marginLeft: "24px",
          color: theme.palette.grey[700],
          minWidth: "110px",
          textAlign: "center",
        })}
      >
        {format(utcDateToLocaleDate(start), "MMMM yyyy")}
      </Typography>
      <Divider orientation="vertical" sx={{ height: "32px" }} />
      <FilterButton
        onClick={onOpenDrawer}
        variant="outlined"
        startIcon={<IoOptions />}
        sx={(theme) => ({
          marginLeft: theme.spacing(6),
        })}
        endIcon={
          filterCount !== 0 ? (
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                borderRadius: 100,
                padding: "4px 6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <Typography
                variant="body2"
                color="white"
                sx={() => ({
                  fontSize: "11px",
                  lineHeight: "11px",
                  textAlign: "center",
                })}
              >
                {filterCount}
              </Typography>
            </Box>
          ) : null
        }
      >
        Filter
      </FilterButton>
    </Box>
  );
};
