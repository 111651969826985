import { Box, Button, IconButton, Skeleton } from "@mui/material";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { EditRobot } from "components/organisms/panels/EditRobot";
import { graphql } from "gql";
import { Privilege, RobotType, UvdRobot } from "gql/graphql";
import { useState } from "react";
import { IoOpenOutline, IoSettingsOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { enumMsg } from "trans/definedMessages/enumMessages";
import { isSupportPath } from "utils/privileges/privilegeUtils";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";
import { getUVDRobotImgSrc } from "utils/UVD/UVDUtils";
import { HardwareList } from "./HardwareList";
import { UVCTubes } from "./UVCTubes";

export const ROBOT_HARDWARE_QUERY = graphql(`
  query RobotHardware($robotId: String!) {
    robot(id: $robotId) {
      id
      serialNumber
      robotType
      journalPage
      ... on UvdRobot {
        charger
        status {
          batteries {
            health
            percentage
          }
          lastTimeActive
          batteryMergerPercentage
          distanceTraveled
          job {
            state
            type
          }
          operationSeconds
          uvcTubes {
            installationDate
            operationSeconds
          }
        }
        version {
          carrierboard
          hardware
          language
          languageBuild
          motorController
          pir
          plc {
            model
            number
            hasNewAvailable
          }
        }
      }
    }
  }
`);

interface Props {
  display?: "datasource" | "default";
}

export const RobotHardware = ({ display = "default" }: Props) => {
  const { robotId } = useParams();
  const intl = useIntl();
  const location = useLocation();
  const canUpdateRobot = usePrivilege(Privilege.RobotUpdate).hasPrivilege; // TODO: robot id?

  const isOnSupportPath = isSupportPath(location);

  const [editRobotOpen, setEditRobotOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(ROBOT_HARDWARE_QUERY, {
    variables: { robotId: robotId || "" },
    skip: !robotId,
  });

  const robot = data?.robot as UvdRobot;
  const status = useUvdConnectionStatus(robot);

  if (loading) return <Skeleton height={400} />;

  return (
    <>
      <WidgetPaper
        sx={() => ({
          padding: 0,
          paddingBottom: 6,
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 6,
            paddingBottom: 0,
          }}
        >
          <WidgetHeader
            label={intl.formatMessage(
              enumMsg[robot?.robotType || RobotType.Tuv],
            )}
          />
          {isOnSupportPath && canUpdateRobot ? (
            <IconButton onClick={() => setEditRobotOpen(true)}>
              <IoSettingsOutline />
            </IconButton>
          ) : null}
        </Box>
        {status === UVDConnectionStatus.UNKNOWN ||
        display === "datasource" ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={getUVDRobotImgSrc(robot?.robotType || RobotType.Tuv)}
                  style={{ height: 210, width: "auto" }}
                />
              </Box>
              <HardwareList robot={robot} />
            </Box>
            <UVCTubes uvcTubes={robot?.status?.uvcTubes} />
          </Box>
        )}
        {robot?.journalPage !== "" && isOnSupportPath ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              target="_blank"
              href={robot?.journalPage || ""}
              endIcon={<IoOpenOutline style={{ marginBottom: 2 }} />}
              variant="text"
            >
              Show robot journal
            </Button>
          </Box>
        ) : null}
      </WidgetPaper>
      {editRobotOpen ? (
        <EditRobot
          open={editRobotOpen}
          onClose={() => setEditRobotOpen(false)}
          robot={robot}
        />
      ) : null}
    </>
  );
};
