import { NumberChart } from "components/modules/charts/NumberChart";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { ReactElement, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const DISINFECTIONCYCLES = graphql(`
  query DisinfectionCycles($filter: DisinfectionFilter!) {
    numberOfDisinfections(filter: $filter)
    robots {
      robots {
        id
        serialNumber
      }
    }
  }
`);

interface Props {
  showDisinfectionLink?: boolean;
  header?: string | ReactElement;
  description?: string | ReactElement;
  filter?: DisinfectionFilter;
  actionCardChildren?: ReactElement;
  queryParams?: any;
}

export const CyclesCounterChart = ({
  description,
  filter,
  actionCardChildren,
  queryParams,
  header,
}: Props) => {
  const { data, loading } = useQueryWithSnack(DISINFECTIONCYCLES, {
    variables: {
      filter: filter ?? {},
    },
    ...queryParams,
  });

  const count = useMemo(() => {
    const number = data?.numberOfDisinfections ?? 0;
    if (number >= 1000)
      return `${number % 1000 ? "+" : ""}${Math.floor(number / 100) / 10}K`;
    return number;
  }, [data?.numberOfDisinfections]);

  return (
    <NumberChart
      header={
        header ?? (
          <FormattedMessage
            id="disinfection-cycles"
            defaultMessage="Disinfection Cycles"
          />
        )
      }
      description={
        description ?? (
          <FormattedMessage
            id="disinfection-cycles-description"
            defaultMessage="Number of disinfection cycles in this period"
          />
        )
      }
      value={count}
      actionCardChildren={actionCardChildren}
      loading={loading}
    />
  );
};
