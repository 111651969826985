import { DisinfectionFilter, DisinfectionFilterOptions } from "gql/graphql";
import { AutoCompleteFilter } from "./filters/AutoCompleteFilter";
import { InterruptionFilter } from "./filters/InterruptionFilter";
import { ModeFilter } from "./filters/ModeFilter";
import { OrganizationCountryFilter } from "./filters/OrganizationCountryFilter";
import { OrganizationFilter } from "./filters/OrganizationFilter";
import { RobotTypeFilter } from "./filters/RobotTypeFilter";
import { StatusFilter } from "./filters/StatusFilter";

export type onChangeFilter = {
  onChange: (
    value: string | undefined,
    category: string,
    checked?: boolean,
  ) => void;
};

interface Props {
  filterListLabels: any;
  filterOptions: any;
  supportFilterOptions?: any;

  id: keyof DisinfectionFilterOptions;
  DDFilters: DisinfectionFilter;
}

export const getFilterComponent = ({
  id,
  filterListLabels,
  filterOptions,
  supportFilterOptions,
  onChange,
  DDFilters,
}: Props & onChangeFilter) => {
  switch (id) {
    case "serialNumbers":
      return (
        <AutoCompleteFilter
          key="serialNumbers"
          id="serialNumbers"
          selected={filterListLabels.includes("serialNumbers")}
          options={filterOptions?.serialNumbers || []}
          value={DDFilters.serialNumbers || []}
          label="Robot"
          onChange={onChange}
        />
      );
    case "rooms":
      return (
        <AutoCompleteFilter
          key="rooms"
          id="rooms"
          selected={filterListLabels.includes("rooms")}
          options={filterOptions?.rooms || []}
          value={DDFilters.rooms || []}
          label="Room"
          onChange={onChange}
        />
      );
    case "departments":
      return (
        <AutoCompleteFilter
          key="departments"
          id="departments"
          selected={filterListLabels.includes("departments")}
          value={DDFilters.departments || []}
          onChange={onChange}
          options={filterOptions?.departments}
          label="Department"
        />
      );
    case "operators":
      return (
        <AutoCompleteFilter
          key="operators"
          id="operators"
          selected={filterListLabels.includes("operators")}
          options={filterOptions?.operators || []}
          value={DDFilters.operators || []}
          label="Operator"
          onChange={onChange}
        />
      );
    case "disinfectionModes":
      return (
        <ModeFilter
          key="disinfectionModes"
          selected={filterListLabels.includes("disinfectionModes")}
          value={DDFilters.disinfectionModes}
          onChange={onChange}
          options={filterOptions?.disinfectionModes || []}
        />
      );
    case "disinfectionStatus":
      return (
        <StatusFilter
          key="disinfectionStatus"
          selected={filterListLabels.includes("disinfectionStatus")}
          value={DDFilters.disinfectionStatus || null}
          onChange={onChange}
          options={filterOptions?.disinfectionStatus || []}
        />
      );
    case "organizations":
      return (
        <OrganizationFilter
          key="organizations"
          selected={filterListLabels.includes("organizations")}
          value={DDFilters.organizationIds || []}
          options={supportFilterOptions?.organizations || []}
          onChange={onChange}
        />
      );
    case "organizationCountries":
      return (
        <OrganizationCountryFilter
          key="organizationCountries"
          selected={filterListLabels.includes("organizationCountries")}
          value={DDFilters.organizationCountries || []}
          onChange={onChange}
        />
      );
    case "robotTypes":
      return (
        <RobotTypeFilter
          key="robotTypes"
          selected={filterListLabels.includes("robotTypes")}
          value={DDFilters.robotTypes}
          onChange={onChange}
          options={supportFilterOptions?.robotTypes || []}
        />
      );
    case "interruptionReasons":
      return (
        <InterruptionFilter
          key="interruptionReasons"
          selected={filterListLabels.includes("interruption")}
          value={DDFilters.interruption?.reasons || []}
          onChange={onChange}
          options={supportFilterOptions?.interruptionReasons || []}
        />
      );
    default:
      return null;
  }
};
