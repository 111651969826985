import { Grid, Paper, Typography } from "@mui/material";
import { UVCTube } from "components/atoms/UVCTube";
import { WidgetDescription } from "components/atoms/WidgetDescription";
import { RobotUvcTube } from "gql/graphql";
import _ from "lodash";

export const UVCTubes = ({
  uvcTubes,
}: {
  uvcTubes: RobotUvcTube[] | undefined;
}) => {
  const otherArr = _.fill(Array(8), null);

  return (
    <Paper
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.light,
        padding: 4,
        margin: 6,
      })}
    >
      <Typography variant="h5">UV-C Tube lifetime</Typography>
      <WidgetDescription>Hours left</WidgetDescription>
      <Grid container spacing={2} mt={4}>
        {((uvcTubes || [])?.length > 0 ? uvcTubes : otherArr)?.map(
          (tube, index) => (
            <Grid item xs={6} key={index}>
              <UVCTube tube={tube} index={index} />
            </Grid>
          ),
        )}
      </Grid>
    </Paper>
  );
};
