import { Box, PaperProps } from "@mui/material";
import { SingleValueDonutChart } from "components/atoms/SingleValueDonutChart";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { ReactElement } from "react";

interface Props {
  header: string | ReactElement;
  value: number | string;
  donutColor: string;
  donutBackgroundColor: string;
  donutSize: number;
  description?: string | ReactElement;
  donutTextColor?: string;
  paperProps?: PaperProps;
  paperTextColor?: string;
}

export const DonutChart = ({
  header,
  description,
  value,
  paperProps,
  donutColor,
  donutBackgroundColor,
  donutTextColor,
  donutSize,
  paperTextColor,
}: Props) => {
  return (
    <WidgetPaper
      {...paperProps}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <WidgetHeader
        label={header}
        description={description}
        labelStyle={{ color: paperTextColor ? paperTextColor : "auto" }}
        descriptionStyle={{ color: paperTextColor ? paperTextColor : "auto" }}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        <SingleValueDonutChart
          value={value}
          color={donutColor}
          backgroundColor={donutBackgroundColor}
          textColor={donutTextColor}
          size={donutSize}
        />
      </Box>
    </WidgetPaper>
  );
};
