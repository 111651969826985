import {
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material";
import { styled } from "@mui/system";

export const LinearProgressBar = styled(LinearProgress)<LinearProgressProps>(
  ({ theme, color }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "rgba(24, 28, 50, 0.05)",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color ? color : theme.palette.success.main,
    },
  }),
);

LinearProgressBar.defaultProps = {
  variant: "determinate",
};
