import { styled, Typography } from "@mui/material";

export const MoreText: any = styled(Typography)(({ theme }) => ({
  display: "inline",
  paddingLeft: 4,
  color: theme.palette.grey[600],
}));

MoreText.defaultProps = {
  component: "span",
};
