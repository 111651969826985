export const UVDRobotUnassignedIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8092 1.0404C10.5257 1.01252 10.2231 1.00142 9.89998 1.00946H6.08882C6.08882 1.00946 0.960745 0.564615 1.01757 5.77709L1.01634 11.8113H1.00151L1.01634 14.3708V17.4951C1.01634 17.4951 0.735904 19.0059 2.4988 18.9932H13.2022C13.2022 18.9932 14.9996 19.1972 14.9996 17.3637V5.77709C14.9996 5.77709 15.0039 5.62946 14.9834 5.38614"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M4.88944 19V16.6283C4.8595 15.5094 5.71861 15.3755 5.71861 15.3755C6.58292 15.4817 6.43453 14.7388 6.43453 14.7388V13.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.43457 1V7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.1105 19V16.6318C11.1405 15.5128 10.2779 15.379 10.2779 15.379C9.4165 15.4851 9.56551 14.7388 9.56551 14.7388V13.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M9.56543 1V7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.99978 6.625C5.85201 6.625 4.11089 8.30393 4.11089 10.375C4.11089 12.4461 5.85201 14.125 7.99978 14.125C10.1476 14.125 11.8887 12.4461 11.8887 10.375C11.8887 8.30393 10.1476 6.625 7.99978 6.625Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <circle cx="14" cy="2" r="2" fill="currentColor" />
    </svg>
  );
};
