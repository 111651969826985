import { gql, useMutation } from "@apollo/client";
import { Box, Button, Skeleton } from "@mui/material";
import {
  CloudStepper,
  CloudStepperProps,
} from "components/atoms/stepper/CloudStepper";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { graphql } from "gql";
import { ActivationState, RoleType } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useIntl } from "react-intl";
import { miscMsg } from "trans/definedMessages/misc";
import {
  getOrganizationOwnerInfo,
  getOrganizationStatus,
} from "utils/privileges/privilegeUtils";
import { useUserDisplayName } from "utils/useUserDisplayName";
import {
  arrayToPrettyString,
  ConjunctionType,
  getFormattedDate,
} from "utils/utilFunctions";
import { InviteUser } from "../dialogs/InviteUser";
import { getPillStatus } from "../userWidgets/UserOnboardingStatus";

const ORGANIZATION_ONBOARDING_FRAGMENT = gql`
  fragment OrganizationOnboardingStatus on Organization {
    id
    name
    onboardedAt
    createdAt
    activationState
    country
    affiliations {
      identity {
        id
        email
        firstName
        lastName
        inviteState
        firstLogin
        invitedDate
      }
      roles {
        date
        title
      }
    }
    ...InviteUserOrganization
  }
  ${InviteUser.fragments.organization}
`;

const RESEND = graphql(`
  mutation ResendOwnerInvitations($orgId: String!) {
    sendReminderEmailsToPendingOwners(targetOrganizationId: $orgId)
  }
`);

interface Props {
  organization: any;
  loading?: boolean;
}

export const OrganizationOnboardingStatus = ({
  organization,
  loading,
}: Props) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(true);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);

  const ownerInfo = getOrganizationOwnerInfo(organization?.affiliations);
  const status = getOrganizationStatus(
    organization?.activationState,
    ownerInfo.hasOwner,
  );

  const [resendOwnerInvitations] = useMutation(RESEND);

  const pillStatus = getPillStatus(status);

  useEffect(() => {
    if (status === ActivationState.Active) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [status]);

  const ownerOnboardingStatusDescription = useMemo(() => {
    if (ownerInfo.firstOwner?.identity?.firstLogin) {
      return getFormattedDate(ownerInfo.firstOwner?.identity?.firstLogin) ?? "";
    } else if (ownerInfo.hasOwner) {
      return `Waiting for ${arrayToPrettyString(ownerInfo.ownerAffiliations, (o) => useUserDisplayName(o.identity), ConjunctionType.OR)}`;
    } else {
      return "";
    }
  }, [ownerInfo]);

  const organizationOnboardingDescription = useMemo(() => {
    if (organization?.onboardedAt) {
      return getFormattedDate(organization?.onboardedAt) ?? "";
    } else if (ownerInfo.hasOwner) {
      return `Waiting for ${arrayToPrettyString(ownerInfo.ownerAffiliations, (o) => useUserDisplayName(o.identity), ConjunctionType.OR)}`;
    } else {
      return "";
    }
  }, [organization, ownerInfo]);

  const hasOnboardedOwner =
    ownerInfo.ownerAffiliations.filter(
      (item) => item.identity.inviteState === "ACTIVE",
    ).length > 0;

  const steps: CloudStepperProps["steps"] = useMemo(() => {
    return [
      {
        label: "Organization created",
        description: getFormattedDate(organization?.createdAt) ?? "",
      },
      {
        label: "Owner added to organization",
        description: getFormattedDate(ownerInfo.firstOwnerInviteDate) ?? "",
        variant: !ownerInfo?.firstOwnerInviteDate ? "waiting" : "done",
      },
      {
        label: "Owner onboarding completed",
        description: ownerOnboardingStatusDescription,
        variant: "waiting",
        hidden:
          hasOnboardedOwner ||
          ownerInfo.hasOwner === false ||
          !!organization?.onboardedAt,
      },
      {
        label: "Organization onboarding completed",
        description: organizationOnboardingDescription,
        variant: organization?.onboardedAt ? "done" : "waiting",
      },
    ];
  }, [organization]);

  const handleResendInvitation = () => {
    const snackbarId = enqueueSnackbar("Saving your name...", {
      variant: "loading",
      persist: true,
    });

    resendOwnerInvitations({
      variables: {
        orgId: organization.id,
      },
      onCompleted: () => {
        enqueueSnackbar(
          intl.formatMessage(
            miscMsg.organizationOnboardingInviteOwnersSnackbar,
            { count: ownerInfo.ownerAffiliations.length },
          ),
          {
            variant: "success",
          },
        );
        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(`Could not resend invitation`, {
          variant: "error",
        });
        closeSnackbar(snackbarId);
      },
    });
  };

  if (loading) return <Skeleton height={150} />;

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <WidgetHeader
            label="Status"
            pillLabel={pillStatus?.label}
            pillStyle={pillStatus?.style}
            pillColor={pillStatus?.color}
          />
          {status === "ACTIVE" ? (
            <Box onClick={() => setIsOpen(!isOpen)} sx={{ cursor: "pointer" }}>
              {isOpen ? <IoChevronUp size={24} /> : <IoChevronDown size={24} />}
            </Box>
          ) : null}
        </Box>
        {isOpen ? (
          <CloudStepper size="small" style={{ marginTop: 4 }} steps={steps} />
        ) : null}
        {status === "MISSING_OWNER" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: ownerInfo.hasOwner ? "space-between" : "flex-end",
              marginTop: 6,
            }}
          >
            <Button onClick={() => setInviteUserDialogOpen(true)} size="small">
              Invite owner
            </Button>
          </Box>
        ) : status === "PENDING" ? (
          <Box mt={6} display="flex" justifyContent="space-between">
            <Button size="small" onClick={handleResendInvitation}>
              {`Resend owner invitation${
                ownerInfo.ownerAffiliations.length > 1 ? "s" : ""
              }`}
            </Button>
          </Box>
        ) : null}
      </WidgetPaper>
      <InviteUser
        organization={organization}
        defaultRole={RoleType.Owner}
        open={inviteUserDialogOpen}
        onClose={() => setInviteUserDialogOpen(false)}
      />
    </>
  );
};

OrganizationOnboardingStatus.fragments = {
  organization: ORGANIZATION_ONBOARDING_FRAGMENT,
};
