import { Box } from "@mui/material";
import { ReactElement } from "react";

export const DrawerWrapper = ({
  children,
}: {
  children: Array<ReactElement | null | undefined> | ReactElement;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 84px)",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
