import { Box, Typography } from "@mui/material";
import { Pill } from "components/atoms/Pill";
import { Warning } from "components/atoms/Warning";
import { SimpleTable } from "components/modules/SimpleTable";
import { Organization } from "gql/graphql";
import { getEnvironment } from "utils/UVD/environmentUtils";

export const ReviewRobots = ({
  selectedOrg,
}: {
  selectedOrg: Partial<Organization> | null;
}) => {
  const { oldSystemLink } = getEnvironment();

  return (
    <Box>
      <Warning
        message={`Make sure that all robots to migrate from ${selectedOrg?.name} are listed below:`}
        sx={{ marginBottom: 6 }}
      />
      <SimpleTable
        data={selectedOrg?.robotAffiliations || []}
        tableRowProps={{
          sx: { pointerEvents: "none" },
        }}
        noDataMessage="No robots in this organization"
        columnConfig={[
          {
            key: "index",
            renderCell: (row: any, index) => {
              console.log("row", row);

              return <>{index + 1}</>;
            },
            sx: { width: 50 },
          },
          {
            key: "serialNumber",
            renderCell: (row: any) => row.robot.serialNumber,
          },
          {
            key: "status",
            renderCell: (row: any) => {
              if (!!row.active) {
                return <Pill label="Active" color="success" />;
              } else {
                return <Pill label="Previous Robot" color="info" />;
              }
            },
          },
        ]}
      />
      <Typography
        color={(theme) => theme.palette.grey[600]}
        mt={6}
        mb={6}
        sx={{ textAlign: "center" }}
      >
        If necessary, make changes to {selectedOrg?.name} in {oldSystemLink} and
        refresh this page before continuing.
      </Typography>
    </Box>
  );
};
