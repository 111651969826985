import { Box } from "@mui/material";
import { BORBanner } from "components/atoms/BORBanner";
import { Outlet } from "react-router-dom";

export const DashboardContent = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexGrow: 1,
        padding: theme.spacing(4),
        flexDirection: "column",
      })}
    >
      <Box
        sx={() => ({
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          width: "100%",
        })}
      >
        <Outlet />
      </Box>
      <BORBanner />
    </Box>
  );
};
