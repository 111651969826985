import { Chip } from "@mui/material";

export const DisinfectionModeCell = ({ type }: { type: string }) => {
  switch (type) {
    case "Stationary":
      return <Chip size="small" label="Stationary" variant="outlined" />;
    case "Remote controlled":
      return <Chip size="small" label="Remote Controlled" variant="outlined" />;
    case "Predefined":
      return <Chip size="small" label="Predefined" variant="outlined" />;
    default:
      return null;
  }
};
