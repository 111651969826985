import { CloudAutoComplete } from "components/atoms/autoComplete/CloudAutocomplete";
import { CloudLabel } from "components/atoms/CloudLabel";
import { ReactElement, SyntheticEvent } from "react";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  id: string;
  value: string[] | null;
  options: string[] | undefined;
  selected: boolean;
  label: string | ReactElement;
  placeholder?: string;
  getOptionLabel?: any;
  autoCompleteProps?: any;
}

export const AutoCompleteFilter = ({
  value,
  onChange,
  options,
  selected,
  label,
  placeholder = "Name",
  id,
  getOptionLabel,
  autoCompleteProps,
}: Props & onChangeFilter) => {
  return (
    <>
      <CloudLabel primary={selected}>{label}</CloudLabel>
      <CloudAutoComplete
        options={options || []}
        value={value}
        autoComplete="no"
        placeholder={placeholder}
        onChange={(event: SyntheticEvent, value: string) => onChange(value, id)}
        getOptionLabel={getOptionLabel}
        {...autoCompleteProps}
      />
    </>
  );
};
