import { Button, styled } from "@mui/material";

export const ButtonLight = styled(Button)(({ theme }) => ({
  background: "white",
  color: theme.palette.grey[900],
  "&.MuiButton-contained:hover": {
    background: "rgba(255, 255, 255, 0.80)",
  },
  "&.MuiButton-containedSuccess": {
    color: theme.palette.success.main,
  },
  "&.MuiButton-containedError": {
    color: theme.palette.error.main,
  },
  "&.MuiButton-outlined": {
    color: "white",
    borderColor: "white",
    background: "transparent",
    fontWeight: 500,
    borderWidth: 2,
    ":hover": {
      color: "white",
      borderColor: "white",
      fontWeight: 500,
      borderWidth: 2,
    },
  },
  "&.MuiButton-outlined:hover": {
    borderColor: "rgba(255, 255, 255, 0.80)",
    color: "rgba(255, 255, 255, 0.80)",
  },
  "&.Mui-disabled": {
    color: "rgba(255, 255, 255, 0.25)",
    background: "rgba(255, 255, 255, 0.10)",
  },
}));
