import { gql, useSubscription } from "@apollo/client";
import { Grid } from "@mui/material";
import { Identity } from "gql/graphql";
import { enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { hasSupportRole, isSupportPath } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { UserOrganizationRoles } from "./UserOrganizationRoles";
import { UserSupportRoles } from "./UserSupportRoles";

export const USER_ROLES_QUERY = gql`
  query UserOrganizationRoles($userId: String!) {
    supportAssignable
    identity(id: $userId) {
      id
      email
      affiliations {
        id
        organization {
          id
          name
          country
        }
        roles {
          title
          date
          clearance
          override
          privileges
        }
      }
      supportRole {
        title
        clearance
        override
        privileges
      }
    }
  }
`;

export const USER_ROLES_ME_QUERY = gql`
  query UserOrganizationRolesForMe {
    me {
      id
      firstName
      lastName
      email
      supportRole {
        title
        clearance
        override
        privileges
      }
      affiliations {
        organization {
          id
          name
          country
        }
        roles {
          title
          clearance
          override
          privileges
        }
      }
    }
  }
`;

const GET_ME_SUB = gql`
  subscription OnMeChangedUserRoles {
    meChanged {
      id
      firstName
      lastName
      email
      inviteState
      language
      affiliations {
        id
        organization {
          id
          name
        }
        roles {
          title
          clearance
          override
          privileges
        }
      }
      supportRole {
        title
        clearance
        override
        privileges
      }
      notifications {
        id
        read
        template
        variables
      }
    }
  }
`;

export const UserRoles = ({ readonly }: { readonly?: boolean }) => {
  const { userId } = useParams();
  const location = useLocation();
  const isOnSupportPath = isSupportPath(location);

  const { data, loading: identityLoading } = useQueryWithSnack(
    USER_ROLES_QUERY,
    {
      variables: { userId: userId ?? "" },
      skip: !userId,
    },
  );

  const { data: meData, loading: meLoading } =
    useQueryWithSnack(USER_ROLES_ME_QUERY);

  const { error: subError, data: subData } = useSubscription(GET_ME_SUB);

  const userIsMe =
    userId === meData?.me?.id || location.pathname.includes("profile");

  const meIdentity = useMemo(() => {
    return (subData?.meChanged ?? meData?.me) as Identity | undefined;
  }, [meData, subData?.meChanged]);

  const identity = userIsMe ? meIdentity : data?.identity;
  const loading = identityLoading || meLoading;

  const currentUserIsSupport = hasSupportRole(meData?.me);
  const showAllOrgs = isOnSupportPath || currentUserIsSupport || userIsMe;

  useMemo(() => {
    if (subError) {
      enqueueSnackbar("User verification: " + subError.message, {
        variant: "warning",
      });
    }
  }, [subError]);

  return (
    <>
      {currentUserIsSupport ? (
        <Grid item xs={12} mb={6}>
          <UserSupportRoles
            readonly={readonly}
            identity={identity}
            canAssign={(data?.supportAssignable || []).length > 0}
            loading={loading}
            userIsMe={userIsMe}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} mb={6}>
        <UserOrganizationRoles
          readonly={readonly}
          loading={loading}
          currentUserIsSupport={currentUserIsSupport}
          actor={meData?.me}
          identity={identity}
          showAllOrgs={showAllOrgs}
          userIsMe={userIsMe}
        />
      </Grid>
    </>
  );
};
