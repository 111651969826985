import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { CloudDialog, CloudDialogProps } from "components/modules/CloudDialog";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { IoChevronForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "utils/utilFunctions";

const columns: GridColDef[] = [
  {
    field: "name",
    width: 200,
    flex: 1,
    headerName: "Name",
    valueGetter: (params) => params.row?.organization?.name,
    renderCell: (params) => (
      <OrganizationCell organization={params.row.organization} />
    ),
  },
  {
    field: "createdAt",
    headerName: "Since",
    width: 200,
    flex: 1,
    renderCell: (params) => getFormattedDate(params.value),
    align: "right",
    headerAlign: "right",
  },
  {
    field: "navigate",
    headerName: "",
    renderCell: () => <IoChevronForward />,
    sortable: false,
    align: "right",
    headerAlign: "right",
    width: 40,
  },
];

interface Props {
  affiliations: any;
}

export const PrevAffiliations = ({
  open,
  onClose,
  affiliations,
}: Props & CloudDialogProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  return (
    <CloudDialog
      header="Previous owners"
      description="Organizations that still have access to their disinfection data"
      open={open}
      onClose={handleClose}
      hideFooter
    >
      <CloudDialogContent>
        <CloudDataGrid
          onRowClick={(params: GridRowParams) =>
            navigate(
              "/cloud/support/organizations/" + params.row.organization.id,
            )
          }
          rows={affiliations || []}
          columns={columns}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
        />
      </CloudDialogContent>
    </CloudDialog>
  );
};
