import { AvatarProps } from "@mui/material";
import { RobotJob, RobotType } from "gql/graphql";
import { AvatarSize } from "types/types";
import { getAvatarSize } from "utils/avatarUtils";
import { getUVDRobotStatusImgSrc } from "utils/UVD/UVDUtils";
import { CloudAvatar } from "./CloudAvatar";

interface CloudAvatarProps extends AvatarProps {
  size?: AvatarSize;
}

interface RobotAvatarProps extends CloudAvatarProps {
  robotType: RobotType;
  isOffline?: boolean;
  robotJob?: RobotJob;
}

export const RobotAvatar = ({
  size = "medium",
  robotType,
  sx,
  isOffline = false,
  robotJob,
}: RobotAvatarProps) => {
  const avatarSize = getAvatarSize(size);

  return (
    <CloudAvatar
      variant="circular"
      size={size}
      sx={{
        background: "transparent !important",
        ...sx,
      }}
    >
      <img
        src={getUVDRobotStatusImgSrc(robotType, robotJob)}
        style={{
          height: avatarSize.height,
          width: "auto",
          opacity: isOffline ? 0.25 : 1,
        }}
      />
    </CloudAvatar>
  );
};
