import { CloudStepsDialog } from "components/modules/CloudStepsDialog";
import { ReviewOrganization } from "components/organisms/migration/ReviewOrganization";
import { ReviewRobots } from "components/organisms/migration/ReviewRobots";
import { ReviewUsers } from "components/organisms/migration/ReviewUsers";
import { Organization } from "gql/graphql";
import { useState } from "react";

interface Props {
  selectedOrg: Partial<Organization> | null;
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedOrgCountry: any) => void;
}

export const MigrationDialog = ({
  selectedOrg,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [selectedOrgCountry, setSelectedOrgCountry] = useState<any>(null);

  const steps = [
    {
      hidden: false,
      label: "Review Robots",
      component: () => <ReviewRobots selectedOrg={selectedOrg} />,
      onSubmit: () => setStepIndex(1),
      submitLabel: "Looks good, continue",
      submitDisabled: false,
      loading: false,
    },
    {
      hidden: false,
      label: "Review Users",
      component: () => <ReviewUsers selectedOrg={selectedOrg} />,
      onSubmit: () => setStepIndex(2),
      submitLabel: "Looks good, continue",
      submitDisabled: false,
      loading: false,
    },
    {
      hidden: false,
      label: "Review Organization",
      component: () => (
        <ReviewOrganization
          selectedOrg={selectedOrg}
          setSelectedOrgCountry={setSelectedOrgCountry}
          selectedOrgCountry={selectedOrgCountry}
        />
      ),
      onSubmit: () => {
        onSubmit(selectedOrgCountry);
        setStepIndex(0);
        setSelectedOrgCountry(null);
      },
      submitLabel: "Migrate now",
      submitDisabled: false,
      loading: false,
    },
  ];

  return (
    <CloudStepsDialog
      header={`Migrate ${selectedOrg?.name}`}
      open={open}
      onClose={() => {
        onClose();
        setStepIndex(0);
        setSelectedOrgCountry(null);
      }}
      hideCancel
      steps={steps}
      stepIndex={stepIndex}
      errorMessage={undefined}
      maxWidth="sm"
    />
  );
};
