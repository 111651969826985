import { Box, Typography } from "@mui/material";
import { isValidElement, ReactElement } from "react";

export const DataLabelAndValueListItem = ({
  label,
  value,
  helperText,
}: {
  label: string;
  value: string | number | ReactElement;
  helperText?: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Typography flex={1} fontWeight={500}>
        {label}
      </Typography>
      <Box display="flex" flex={1} flexDirection="column">
        {isValidElement(value) ? value : <Typography>{value}</Typography>}
        {helperText ? (
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey[600]}
          >
            {helperText}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
