import {
  Box,
  FormControlLabel,
  Radio,
  RadioProps,
  SxProps,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";

interface Props {
  label: ReactElement | string;
  value: RadioProps["value"];
  style?: SxProps;
  description?: ReactElement | string;
  disabled?: boolean;
}

export const RadioButton = ({
  label,
  description,
  value,
  disabled,
  style,
}: Props) => {
  return (
    <FormControlLabel
      disabled={disabled}
      sx={{
        padding: "8px 0px",

        ...style,
      }}
      value={value}
      control={<Radio />}
      label={
        <Box>
          <Typography>{label}</Typography>
          {description ? (
            <Typography color={(theme) => theme.palette.grey[500]}>
              {description}
            </Typography>
          ) : null}
        </Box>
      }
    />
  );
};
