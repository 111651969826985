import {
  Box,
  Button,
  ButtonProps,
  ChipProps,
  styled,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { Pill } from "./Pill";
import { WidgetDescription } from "./WidgetDescription";

export const HeaderLabel = styled(Typography)(() => ({
  textTransform: "capitalize",
}));

HeaderLabel.defaultProps = {
  variant: "h4",
};

interface WidgetHeaderProps {
  label: ReactElement | string;
  description?: ReactElement | string;
  buttonLabel?: ReactElement | string;
  onButtonClick?: () => void;
  labelStyle?: any;
  descriptionStyle?: any;
  wrapperStyle?: any;
  pillLabel?: ReactElement | string;
  pillColor?: ChipProps["color"];
  pillStyle?: any;
  buttonVariant?: ButtonProps["variant"];
}

export const WidgetHeader = ({
  label,
  description,
  buttonLabel,
  onButtonClick,
  labelStyle,
  descriptionStyle,
  wrapperStyle,
  pillLabel,
  pillColor,
  pillStyle,
  buttonVariant,
}: WidgetHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        minHeight: 30,
        ...wrapperStyle,
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginRight: theme.spacing(4),
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <HeaderLabel mb={1} style={labelStyle}>
            {label}
          </HeaderLabel>
          {pillLabel ? (
            <Pill
              label={pillLabel}
              color={pillColor || "primary"}
              sx={{ mb: 1, ml: 2, ...pillStyle }}
            />
          ) : null}
        </Box>
        {description ? (
          <WidgetDescription style={descriptionStyle}>
            {description}
          </WidgetDescription>
        ) : null}
      </Box>

      {buttonLabel ? (
        <Button
          onClick={onButtonClick}
          variant={buttonVariant || "outlined"}
          size="small"
        >
          {buttonLabel}
        </Button>
      ) : null}
    </Box>
  );
};
