import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, DialogActions, Typography } from "@mui/material";
import { ReactElement } from "react";

export interface CloudDialogFooterProps {
  onSubmit?: () => void;
  submitLabel?: string | ReactElement;
  onCancelClick?: () => void;
  hideCancel?: boolean;
  submitDisabled?: boolean;
  preventDefault?: boolean;
  loading?: boolean;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  errorMessage?: string;
}

export const CloudDialogFooter = ({
  hideCancel = false,
  onCancelClick,
  onSubmit,
  submitLabel,
  submitDisabled,
  preventDefault,
  loading,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  errorMessage,
}: CloudDialogFooterProps) => {
  return (
    <Box>
      {errorMessage ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            background: theme.palette.error.light,
            padding: 4,
          })}
        >
          <Typography
            variant="body2"
            color={(theme) => theme.palette.error.main}
          >
            {errorMessage}
          </Typography>
        </Box>
      ) : null}
      <DialogActions
        sx={(theme) => ({
          padding: 6,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        })}
      >
        {hideCancel ? null : (
          <Button variant="outlined" autoFocus onClick={onCancelClick}>
            Cancel
          </Button>
        )}
        <Box>
          {!!onSecondaryButtonClick ? (
            <Button
              variant="text"
              sx={{ marginRight: 4 }}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonLabel}
            </Button>
          ) : null}
          <LoadingButton
            loading={loading}
            disabled={submitDisabled}
            type="submit"
            variant="contained"
            onClick={(e) => {
              preventDefault && e.preventDefault();
              onSubmit && onSubmit();
            }}
          >
            {submitLabel || "OK"}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Box>
  );
};
