import { Box, Tooltip, useTheme } from "@mui/material";
import { IoCheckmarkCircle, IoRemoveCircleOutline } from "react-icons/io5";

export const UVDRobotStatusIconCell = ({
  status,
  size,
}: {
  status: string;
  size?: number;
}) => {
  const theme = useTheme();

  switch (status) {
    case "complete":
      return (
        <Tooltip title="Complete" arrow placement="top">
          <Box>
            <IoCheckmarkCircle
              size={size || 20}
              color={theme.palette.success.main}
            />
          </Box>
        </Tooltip>
      );
    case "incomplete":
      return (
        <Tooltip title="Incomplete" arrow placement="top">
          <Box>
            <IoRemoveCircleOutline
              size={size || 20}
              color={theme.palette.grey[400]}
            />
          </Box>
        </Tooltip>
      );
    case undefined:
    default:
      return <></>;
  }
};
