import { Avatar } from "@mui/material";
import { SupportAvatar } from "components/atoms/avatars/SupportAvatar";
import { RoleType } from "gql/graphql";
import { getIcon } from "../ActivityWidget";

export const LogEntryIcon = ({ logEntry }: any) => {
  const roleValue = logEntry.variables.find(
    (item: any) => item.key === "ROLE",
  )?.value;
  if (roleValue === RoleType.SecondLevel) {
    return (
      <SupportAvatar
        size="small"
        variant="circular"
        style={{ margin: "0px 12px" }}
      />
    );
  }

  return (
    <Avatar
      sx={(theme) => ({
        width: 28,
        height: 28,
        bgcolor: theme.palette.grey[100],
        "svg > *": {
          color: theme.palette.grey[600],
        },
        margin: "0px 12px",
      })}
    >
      {getIcon(logEntry.code)}
    </Avatar>
  );
};
