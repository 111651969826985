import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { graphql } from "gql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query OrgDisinfectionCountCell($orgId: String!) {
    disinfections(filter: { organizationIds: [$orgId] }) {
      count
    }
  }
`);

export const OrgDisinfectionCountCell = (params: GridRenderCellParams) => {
  const { data } = useQueryWithSnack(QUERY, {
    variables: { orgId: params.row.id },
  });

  return <Typography>{data?.disinfections.count} Disinfections</Typography>;
};
