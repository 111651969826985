import { RadioGroup, RadioGroupProps } from "@mui/material";
import { RadioButton } from "components/atoms/buttons/RadioButton";
import { graphql } from "gql";
import { NormalRoleType } from "gql/graphql";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { roleDescriptions } from "trans/definedMessages/roleDescriptions";
import { roles } from "trans/definedMessages/roleMessages";
import { FleetManagementRole } from "types/types";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

export const ASSIGNABLE_ROLES = graphql(`
  query AssignableRoles($orgId: String!) {
    assignable(targetOrganizationId: $orgId)
  }
`);

interface Props {
  value: RadioGroupProps["value"];
  onChange: (value: string) => void;
  organizationId: string | undefined;
  disabled?: boolean;
  radioGroupProps?: RadioGroupProps;
}

export const FmRoles = ({
  value,
  onChange,
  disabled,
  radioGroupProps,
  organizationId,
}: Props) => {
  const intl = useIntl();

  const { data } = useQueryWithSnack(ASSIGNABLE_ROLES, {
    variables: {
      orgId: organizationId ?? "",
    },
    skip: !organizationId,
  });

  useEffect(() => {
    if (data?.assignable.length === 1) {
      onChange(data.assignable[0] as string);
    }
  }, [data]);

  return (
    <RadioGroup
      value={value}
      onChange={(e: any) => onChange(e.target.value)}
      sx={{ display: "flex", flexDirection: "column" }}
      {...radioGroupProps}
    >
      {Object.values(FleetManagementRole).map((item) => (
        <RadioButton
          key={item}
          disabled={
            disabled ||
            !data?.assignable.includes(item as unknown as NormalRoleType)
          }
          label={intl.formatMessage(roles[item])}
          value={item}
          description={intl.formatMessage(roleDescriptions[item])}
        />
      ))}
    </RadioGroup>
  );
};
