import { Disinfection } from "gql/graphql";

export type FilterValue = Array<string> | string;

type FilterListItem = {
  label: string;
  value: any;
};

export type FilterList = Array<FilterListItem>;

export type AvatarSize =
  | "tiny"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "huge";

export enum DisinfectionPositionStatus {
  Failed,
  Remaining,
  Disinfected,
}

export enum FleetManagementRole {
  Owner = "Owner",
  Editor = "Editor",
  Viewer = "Viewer",
}

export enum SupportRole {
  SecondLevel = "SecondLevel",
  // FirstLevel = "FirstLevel",
}

export type SelectedDisinfectionReport =
  | Partial<Disinfection>
  | undefined
  | null;

export type ChipColor =
  | "success"
  | "warning"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | undefined;

interface MessageDescriptor {
  id?: string;
  description?: string | object;
  defaultMessage?: string;
}

export type DefineMsg = Record<string, MessageDescriptor>;
