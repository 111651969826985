import { useSubscription } from "@apollo/client";
import { Box, Skeleton, styled } from "@mui/material";
import { graphql } from "gql";
import { Identity } from "gql/graphql";
import { useMemo } from "react";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { OrganizationMenuItem } from "../modules/menuItems/OrganizationMenuItem";
import { SupportMenuItem } from "../modules/menuItems/SupportMenuItem";

export const GET_IDENTITY_WITH_AFFILIATIONS = graphql(`
  query DashboardMenuGetModules {
    me {
      supportRole {
        title
        privileges
      }
      affiliations {
        id
        organization {
          id
          name
        }
        roles {
          title
          privileges
        }
      }
    }
  }
`);

const GET_ME_SUB = graphql(`
  subscription OnMeChangedMenu {
    meChanged {
      id
      firstName
      lastName
      email
      inviteState
      language
      affiliations {
        id
        organization {
          id
          name
        }
        roles {
          title
          privileges
        }
      }
      supportRole {
        title
        privileges
      }
      notifications {
        id
        read
        template
        variables
      }
    }
  }
`);

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "232px",
  minWidth: "232px",
  padding: theme.spacing(4),
  paddingRight: theme.spacing(2),
  paddingBottom: 24,
  flexDirection: "column",
  position: "sticky",
  top: 0,
}));

export const DashboardMenu = () => {
  const { data: modulesData, loading } = useQueryWithSnack(
    GET_IDENTITY_WITH_AFFILIATIONS,
  );

  const { data: subData } = useSubscription(GET_ME_SUB);

  const identity = useMemo(() => {
    return (subData?.meChanged ?? modulesData?.me) as Identity | undefined;
  }, [modulesData, subData]);

  if (loading) {
    return (
      <Wrapper>
        <Skeleton
          variant="text"
          sx={{ fontSize: "2rem", width: "50%", height: "30px", mb: 2, mt: 4 }}
        />
        <Skeleton variant="rounded" width={"100%"} height={28} sx={{ mb: 2 }} />
        <Skeleton variant="rounded" width={"100%"} height={28} sx={{ mb: 2 }} />
        <Skeleton variant="rounded" width={"100%"} height={28} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {identity?.supportRole ? (
        <>
          <SupportMenuItem />
          {(modulesData?.me?.affiliations || []).length > 0 ? (
            <Box
              sx={(theme) => ({
                background: theme.palette.grey[300],
                width: "100%",
                height: "1px",
                marginTop: 2,
              })}
            />
          ) : null}
        </>
      ) : null}
      {identity?.affiliations?.map((affiliation) => (
        <OrganizationMenuItem
          key={affiliation.organization.id}
          {...affiliation}
        />
      ))}
    </Wrapper>
  );
};
