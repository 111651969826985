import {
  DisinfectionFilter,
  DisinfectionFilterInterruption,
} from "gql/graphql";
import _ from "lodash";
import { FilterList } from "types/types";

export const getSelectedFilterList = (
  DDFilters: DisinfectionFilter,
): FilterList => {
  return Object.keys(DDFilters).reduce((accumulator: any, item: any) => {
    const value = DDFilters[item as keyof DisinfectionFilter];

    if (item === "timeSpan") {
      return accumulator;
    } else if (item === "interruption") {
      return (value as DisinfectionFilterInterruption).reasons.length === 0
        ? accumulator
        : accumulator.concat([{ label: item, value: value }]);
    } else if (_.isNull(value) || value === undefined) {
      return accumulator;
    } else if (_.isArray(value) && value.length === 0) {
      return accumulator;
    } else {
      return accumulator.concat([{ label: item, value: value }]);
    }
  }, []);
};
