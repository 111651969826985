import { Button, styled } from "@mui/material";

export const FilterButton = styled(Button)(({ theme }) => ({
  maxHeight: "32px",
  padding: "8px 16px",
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[700],
  ":hover": {
    backgroundColor: theme.palette.grey[350],
  },
  "&.MuiButton-outlined": {
    border: "1px solid " + theme.palette.grey[300],
    background: "transparent",
    color: theme.palette.grey[600],
    ":hover": {
      border: "1px solid " + theme.palette.grey[350],
      color: theme.palette.grey[700],
    },
  },
}));

export const FilterIconButton = styled(FilterButton)(() => ({
  padding: "8px",
}));

FilterButton.defaultProps = {
  size: "small",
};

FilterIconButton.defaultProps = {
  size: "small",
};
