import { Dialog } from "@mui/material";
import {
  CloudDialogFooter,
  CloudDialogFooterProps,
} from "components/atoms/CloudDialogFooter";
import {
  DrawerHeader,
  DrawerHeaderProps,
} from "components/atoms/drawer/DrawerHeader";
import { ReactElement } from "react";

export interface CloudDialogProps {
  open: boolean;
  onClose: () => void;
  children?: ReactElement[] | ReactElement;
  contentStyle?: any;
  hideFooter?: boolean;
}

export const CloudDialog = ({
  open,
  children,
  onClose,
  header,
  description,
  hideFooter,
  ...footerProps
}: CloudDialogProps & CloudDialogFooterProps & DrawerHeaderProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
      <DrawerHeader
        header={header}
        description={description}
        onClose={onClose}
        wrapperStyle={{ paddingBottom: 0 }}
      />
      {children}
      {hideFooter ? null : <CloudDialogFooter {...footerProps} />}
    </Dialog>
  );
};
