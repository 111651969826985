import GoBeFavicon from "../assets/favicons/gobe-favicon.svg";
import GoBeLogo from "../assets/logos/gobe-logo.png";
import { gobeTheme } from "../theme/gobeTheme";
import { Config } from "./configTypes";

export const gobeConfig: Config = {
  theme: gobeTheme,
  defaultRouteKey: "profile",
  logo: {
    defaultLogo: GoBeLogo,
  },
  favicon: GoBeFavicon,
};
