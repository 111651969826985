import { Affiliation, Role } from "gql/graphql";

/**
 * returns Owners and Editors in Organization
 * @param   {Affiliation[]} orgAffiliations Affiliations in an organization
 */
export function getOrganizationAdmins(
  orgAffiliations: any, // Affiliation
): {
  owners: Array<Affiliation> | [];
  editors: Array<Affiliation> | [];
} {
  let admins = { owners: [], editors: [] };

  if (orgAffiliations?.length > 0) {
    admins = {
      owners: orgAffiliations
        .filter((item: any) => {
          return item.roles.map((item: Role) => item.title)?.includes("Owner");
        })
        .map((item: any) => item.identity),
      editors: orgAffiliations
        .filter((item: any) => {
          return item.roles.map((item: Role) => item.title)?.includes("Editor");
        })
        .map((item: any) => item.identity),
    };
  }
  return admins;
}
