import { InlineCard } from "components/atoms/InlineCard";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { StatusCardContent } from "components/modules/cards/StatusCardContent";
import { IoChevronForward } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

export const OrgRooms = () => {
  const navigate = useNavigate();
  const { orgId } = useParams();

  return (
    <WidgetPaper>
      <WidgetHeader label="Rooms" />
      <InlineCard
        sx={{ mt: 3 }}
        onClick={() => navigate(`/cloud/support/organizations/${orgId}/rooms`)}
      >
        <StatusCardContent
          header="View Disinfections per Room"
          description="All-time summary"
          action={<IoChevronForward size={18} />}
        />
      </InlineCard>
    </WidgetPaper>
  );
};
