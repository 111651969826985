import { useReactiveVar, useSubscription } from "@apollo/client";
import { renderTemplate } from "@blue-ocean-robotics/cloud-library";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { isMenuVisibleVar } from "cache";
import { DashboardBanner } from "components/organisms/DashboardBanner";
import { DashboardContent } from "components/organisms/DashboardContent";
import { DashboardMenu } from "components/organisms/DashboardMenu";
import { closeSnackbar, useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
  getOwnerAffiliationsPending,
  getUniqueUserRoles,
  hasSupportRole,
} from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useConfig } from "../../config/configHook";
import { useFirebase } from "../../firebaseWrapper";
import { graphql } from "../../gql";
import {
  Identity,
  Notification as NotificationMessage,
} from "../../gql/graphql";
import { NoticeTemplate } from "../../templates/noticeTemplate.type";

const GET_PRIVILEGES = graphql(`
  query DashboardGetModules {
    me {
      id
      firstName
      lastName
      email
      inviteState
      language
      supportRole {
        title
        privileges
      }
      affiliations {
        id
        organization {
          id
          name
          activationState
        }
        roles {
          title
          privileges
        }
      }
      notifications {
        id
        read
        template
        variables
      }
    }
  }
`);

const GET_ME_SUB = graphql(`
  subscription OnMeChanged {
    meChanged {
      id
      firstName
      lastName
      email
      inviteState
      language
      affiliations {
        id
        organization {
          id
          activationState
        }
        roles {
          title
          privileges
        }
      }
      notifications {
        id
        read
        template
        variables
      }
    }
  }
`);

interface Props {
  defaultRoute: string;
}

export const Dashboard = ({ defaultRoute }: Props) => {
  const navigate = useNavigate();
  const config = useConfig();
  const firebase = useFirebase(config);
  const [user, loading] = useAuthState(firebase.auth);
  const { enqueueSnackbar } = useSnackbar();
  const isMenuVisible = useReactiveVar(isMenuVisibleVar);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const visibilityInStorage = sessionStorage.getItem("isMenuVisible");

  Notification.requestPermission();
  const [notifications, setNotifications] = useState<NotificationMessage[]>();

  const { data: modulesData, client } = useQueryWithSnack(GET_PRIVILEGES, {
    skip: !user,
  });
  const me = modulesData?.me as Identity | undefined;

  const { error: subError, data: subData } = useSubscription(GET_ME_SUB, {
    skip: !user,
  });

  const identity = useMemo(() => {
    return (subData?.meChanged ?? me) as Identity | undefined;
  }, [me, subData?.meChanged]);

  const isSupporter = hasSupportRole(me);

  const userRoles = getUniqueUserRoles(me?.affiliations);

  const ownerOnboardingPending = useMemo(() => {
    if (identity?.affiliations) {
      return getOwnerAffiliationsPending(identity.affiliations);
    }
    return [];
  }, [identity]);

  useEffect(() => {
    if (ownerOnboardingPending.length > 0) {
      enqueueSnackbar(
        "You need to complete onboarding for your organizations.",
        {
          persist: true,
          variant: "warning",
          preventDuplicate: true,
          actionLabel: "Start onboarding",
          onActionClick: () => {
            navigate("/onboarding");
            closeSnackbar();
          },
        },
      );
    }
  }, [ownerOnboardingPending]);

  useMemo(() => {
    if (!identity || Notification.permission !== "granted") {
      return;
    }
    setNotifications(identity.notifications); // notifications variable is still the old until finished

    if (
      notifications &&
      notifications?.length !== identity.notifications.length
    ) {
      for (const notification of identity.notifications.filter(
        (o) => !notifications.some((p) => o.id === p.id),
      )) {
        let template: NoticeTemplate;
        try {
          template =
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`./../../templates/${notification.template}`).template;
        } catch (err) {
          continue;
        }
        try {
          template =
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(
              `./../../templates/${notification.template}.${identity.language}`,
            ).template;
        } catch (err) {}
        new Notification(
          renderTemplate(template.simple.title, notification.variables),
          {
            body: renderTemplate(
              template.simple.template,
              notification.variables,
            ),
          },
        );
      }
    }
  }, [identity, notifications]);

  useMemo(() => {
    if (subError) {
      enqueueSnackbar("User verification: " + subError.message, {
        variant: "warning",
      });
    }
  }, [enqueueSnackbar, subError]);

  useEffect(() => {
    if (loading) return;
    // TODO: if the user is no longer authenticated or if there is an error
    // they should be alerted and logged out

    if (!user) {
      navigate("/");
      closeSnackbar();
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (visibilityInStorage) {
      sessionStorage.setItem("isMenuVisible", visibilityInStorage.toString());
      isMenuVisibleVar(visibilityInStorage === "false" ? false : true);
    } else {
      sessionStorage.setItem("isMenuVisible", largeScreen.toString());
      isMenuVisibleVar(largeScreen);
    }
  }, []);

  useEffect(() => {
    client.resetStore();
  }, []);

  if (!user) return null;

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        flexGrow: 1,
      })}
    >
      <DashboardBanner
        onToggleMenu={() => {
          isMenuVisibleVar(!isMenuVisible);
          sessionStorage.setItem("isMenuVisible", (!isMenuVisible).toString());
        }}
        isSupporter={isSupporter}
        userRoles={userRoles}
        defaultRoute={defaultRoute}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
        }}
      >
        {sessionStorage.getItem("isMenuVisible") === "true" ? (
          <DashboardMenu />
        ) : null}
        <DashboardContent />
      </Box>
    </Box>
  );
};
