import { Avatar, useTheme } from "@mui/material";
import { SupportIcon } from "assets/icons/SupportIcon";
import {
  getAvatarBorderRadius,
  getAvatarIconSize,
  getAvatarSize,
} from "utils/avatarUtils";
import { CloudAvatarProps } from "./UserAvatar";

export const SupportAvatar = ({
  size = "medium",
  variant = "rounded",
  style,
}: CloudAvatarProps) => {
  const theme = useTheme();
  const iconSize = getAvatarIconSize(size);
  const borderRadius = getAvatarBorderRadius(size, variant);
  const avatarSize = getAvatarSize(size);

  return (
    <Avatar
      variant={variant}
      style={{
        backgroundColor: theme.palette.primary.light,
        textTransform: "uppercase",
        borderRadius,
        ...avatarSize,
      }}
      sx={(theme) => ({
        "& svg": {
          height: iconSize,
          width: iconSize,
        },
        "& svg > *": { color: theme.palette.primary.main },
        marginRight: 2,
        ...style,
      })}
    >
      <SupportIcon />
    </Avatar>
  );
};
