import { useMutation } from "@apollo/client";
import { FormikTextField } from "components/atoms/formik";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { useFormik } from "formik";
import { graphql } from "gql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { ROBOT_HARDWARE_QUERY } from "../robotWidgets/hardware/RobotHardware";

const UPDATE_ROBOT = graphql(`
  mutation UpdateRobot($robotId: String!, $robotJournalLink: String) {
    updateRobot(id: $robotId, journalPage: $robotJournalLink) {
      id
    }
  }
`);

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  robot: any;
}

type Values = {
  robotJournalLink?: string;
};

export const EditRobot = ({ open, onClose, robot }: Props) => {
  const intl = useIntl();
  const { robotId } = useParams();

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const [updateRobot] = useMutation(UPDATE_ROBOT, {
    refetchQueries: [
      {
        query: ROBOT_HARDWARE_QUERY,
        variables: { robotId: robotId ?? "" },
      },
    ],
  });

  const formik = useFormik<Values>({
    initialValues: {
      robotJournalLink: "",
    },
    validationSchema: Yup.object({
      robotJournalLink: Yup.string().url(
        intl.formatMessage({
          id: "login.invalidUrl",
          defaultMessage: "Invalid URL",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Updating organization...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      updateRobot({
        variables: {
          robotId: robotId ?? robot?.id ?? "",
          robotJournalLink: values.robotJournalLink?.trim(),
        },
        onCompleted: () => {
          enqueueSnackbar(`Updated robot journal link`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not update robot`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue("robotJournalLink", robot?.journalPage || "");
  }, [robot?.journalPage]);

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={handleClose}
        header={`Edit Robot ${robot?.serialNumber}`}
        action={{
          label: "Save changes",
          onClick: formik.handleSubmit,
          disabled:
            formik.isValid === false ||
            formik.values.robotJournalLink === robot?.journalPage,
        }}
      >
        <FormikTextField
          name="robotJournalLink"
          formik={formik}
          label="Link to robot journal"
          placeholder="Robot journal link"
        />
      </CloudDrawer>
    </form>
  );
};
