import { Box, Button, styled, Typography } from "@mui/material";
import { GoogleIcon } from "assets/icons/GoogleIcon";
import { MicrosoftIcon } from "assets/icons/MicrosoftIcon";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { useNavigate } from "react-router-dom";

const SSOButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderRadius: 5,
  color: theme.palette.grey[900],
  ":hover": {
    borderColor: theme.palette.grey[500],
    color: theme.palette.grey[900],
  },
}));

SSOButton.defaultProps = {
  variant: "outlined",
};

export const SSOButtons = () => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[300],
            height: 2,
            flex: 1,
          })}
        />
        <Typography color={(theme) => theme.palette.grey[500]} m={2}>
          or continue with
        </Typography>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[300],
            height: 2,
            flex: 1,
          })}
        />
      </Box>

      <Box display="flex" alignItems="center" flexDirection="column">
        <SSOButton
          onClick={() =>
            firebase.signInWithGoogle().then(() => navigate("/cloud"))
          }
          startIcon={<GoogleIcon />}
          sx={{ marginBottom: 2 }}
        >
          Google
        </SSOButton>
        <SSOButton
          onClick={() =>
            firebase.signInWithMicrosoft().then(() => navigate("/cloud"))
          }
          startIcon={<MicrosoftIcon />}
        >
          Microsoft
        </SSOButton>
      </Box>
    </>
  );
};
