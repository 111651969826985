import { Stack, Typography } from "@mui/material";
import { CloudAvatar } from "components/atoms/avatars/CloudAvatar";
import { SupportAvatar } from "components/atoms/avatars/SupportAvatar";
import { Pill } from "components/atoms/Pill";
import { Activity, Identity } from "gql/graphql";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { getInitials } from "utils/utilFunctions";

export const Actor = ({
  logEntry,
  userIsMe,
}: {
  logEntry: Activity;
  userIsMe: boolean;
}) => {
  const identity = logEntry.actor as Identity | undefined;

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <CloudAvatar seed={identity?.id} variant="circular" size="tiny">
        {getInitials(useUserDisplayName(identity) || "")}
      </CloudAvatar>
      <Typography variant="bodyS" ml={1} mr={1} component="p">
        {logEntry.actorId
          ? identity
            ? useUserDisplayName(identity)
            : "Unknown user"
          : "System"}
      </Typography>
      {!!identity?.supportRole ? (
        <>
          <SupportAvatar
            size="tiny"
            variant="circular"
            style={{ marginRight: 0 }}
          />
          <Typography variant="bodyS" ml={1} mr={1} component="p">
            UVD Customer Service
          </Typography>
        </>
      ) : null}
      {userIsMe ? (
        <Pill
          label="YOU"
          color="primary"
          sx={{
            color: "white",
          }}
        />
      ) : null}
    </Stack>
  );
};
