import { SxProps, Typography } from "@mui/material";
import { ReactElement } from "react";
import { InlineCard } from "./InlineCard";

export const InlineCardEmptyView = ({
  label,
  sx,
  fullWidth,
}: {
  label: string | ReactElement;
  sx?: SxProps;
  fullWidth?: boolean;
}) => {
  return (
    <InlineCard
      empty
      fullWidth={fullWidth}
      sx={{
        padding: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Typography
        variant="body2"
        color={(theme) => theme.palette.grey[500]}
        textAlign="center"
      >
        {label}
      </Typography>
    </InlineCard>
  );
};
