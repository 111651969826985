import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { LargeSpinner } from "components/atoms/LargeSpinner";
import { UserBasicInfo } from "components/organisms/userWidgets/UserBasicInfo";
import { UserOnboardingStatus } from "components/organisms/userWidgets/UserOnboardingStatus";
import { UserRoles } from "components/organisms/userWidgets/UserRoles";
import { graphql } from "gql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

export const ME_QUERY = graphql(`
  query ProfilePage {
    me {
      id
      firstName
      lastName
      email
      language
      inviteState
      firstLogin
      firstReminderSent
      invitedDate
      lastLogin
      secondReminderSent
      loginWithGoogle
      loginWithMicrosoft
      loginWithPassword
      affiliations {
        id
        organization {
          id
          name
          country
        }
        roles {
          title
          date
        }
      }
      supportRole {
        title
      }
    }
  }
`);

const columnLeft = ["basicInfo"];

const columnRight = ["status", "userRoles"];

const smallScreenColumns = ["basicInfo", "status", "userRoles"];

export const ProfilePage = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const { data, loading } = useQueryWithSnack(ME_QUERY);

  const renderColumn = (type: any) => {
    switch (type) {
      case "basicInfo":
        return (
          <Grid item xs={12} mb={6} key={"basicInfo"}>
            <UserBasicInfo user={data?.me} userIsMe />
          </Grid>
        );
      case "status":
        return (
          <Grid item xs={12} mb={6} key={"status"}>
            <UserOnboardingStatus user={data?.me} />
          </Grid>
        );
      case "userRoles":
        return (
          <Grid item xs={12} mb={6} key="orgRoles">
            <UserRoles readonly />
          </Grid>
        );
      default:
        return null;
    }
  };

  const widgetArr = largeScreen ? columnLeft : smallScreenColumns;

  if (loading) {
    return <LargeSpinner />;
  }

  return (
    <Grid container spacing={6}>
      <HeaderGrid crumbs={[{ label: "My Profile" }]} />
      <Grid item xs={12} xl={6}>
        {widgetArr.map((item) => renderColumn(item))}
      </Grid>
      {largeScreen ? (
        <Grid item xs={12} xl={6}>
          {columnRight.map((item) => renderColumn(item))}
        </Grid>
      ) : null}
    </Grid>
  );
};
