export const firebaseAuthErrors: { [key: string]: string } = {
  "code-expired":
    "The SMS code has expired. Re-send the verification code to try again.",
  "credential-already-in-use":
    "This email address was already registered. Try to log in from the Login page instead.",
  "custom-token-mismatch": "There was a problem with the domain setup. Id: $id",
  "email-already-in-use":
    "This email address was already registered. Try to log in from the Login page instead.",
  "cancelled-popup-request":
    "We cancelled this request because of another popup was opened.",
  "invalid-user-token":
    "We cannot verify your account. Empty your browser's cache before trying again.",
  "invalid-email": "Enter a valid email address.",
  "invalid-oauth-provider":
    "There was a problem with the domain setup. Id: $id",
  "unauthorized-domain": "There was a problem with the domain setup. Id: $id",
  "wrong-password": "Enter a valid password.",
  "invalid-phone-number":
    "Enter a phone number starting with '+', followed by the country code.",
  "invalid-recipient-email":
    "We did not send the email because the recipient's address was invalid.",
  "missing-phone-number": "Provide a recipient phone number.",
  "account-exists-with-different-credential":
    "This email address is was used with another login method. Use a different email address or login method to continue.",
  "popup-blocked":
    "We could not launch a popup for this request. Make sure your browser allows popups.",
  "popup-closed-by-user":
    "We could not finish the request because the popup was closed.",
  "unverified-email":
    "We did not send the email because the recipient's address was not verified.",
  "user-not-found": "This user does not seem to be registered.",
  "weak-password": "Enter a password that is at least 8 characters long.",
  "invalid-credential": "Enter a valid email / password combination.",
};
