import { Box, BoxProps, styled } from "@mui/material";

interface Props extends BoxProps {
  hideFooter?: boolean;
}

export const DrawerContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hideFooter",
})<Props>(({ hideFooter }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "flex-start",
  overflow: "auto",
  marginBottom: hideFooter ? 0 : "84px",
  padding: `16px 24px`,
}));
