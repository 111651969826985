import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { DisinfectionStatus } from "gql/graphql";
import { useIntl } from "react-intl";
import { enumMsg } from "trans/definedMessages/enumMessages";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  value: DisinfectionStatus | null;

  options: DisinfectionStatus[] | undefined;
  selected: boolean;
}

export const StatusFilter = ({
  value,
  onChange,
  options,
  selected,
}: Props & onChangeFilter) => {
  const intl = useIntl();

  return (
    <>
      <CloudLabel primary={selected}>Status</CloudLabel>
      <RadioGroup
        defaultValue={null}
        value={value}
        name="disinfectionstatus"
        onChange={(e: any) => onChange(e.target.value, "disinfectionStatus")}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <FormControlLabel
          disabled={!options?.includes(DisinfectionStatus.Complete)}
          value={DisinfectionStatus.Complete}
          control={<Radio />}
          label={intl.formatMessage(enumMsg[DisinfectionStatus.Complete])}
        />
        <FormControlLabel
          disabled={!options?.includes(DisinfectionStatus.InComplete)}
          value={DisinfectionStatus.InComplete}
          control={<Radio />}
          label={intl.formatMessage(enumMsg[DisinfectionStatus.InComplete])}
        />
      </RadioGroup>
    </>
  );
};
