import { createElement } from "react";

interface Props {
  name: string;
  formik: any;
  component: any;
  [key: string]: any;
}

export const FormikAutocomplete = (props: Props) => {
  const { formik, name, component, ...autoCompleteProps } = props;

  return createElement(component, {
    ...autoCompleteProps,
    textfieldprops: {
      ...autoCompleteProps.textfieldprops,
      helperText:
        formik.touched[name] && formik.errors[name]
          ? formik.errors[name]
          : autoCompleteProps.helperText,
      error: formik.touched[name] && !!formik.errors[name],
    },
    value: formik.values[name],
    onChange: (e: any, value: any) => {
      const newValue = value === null ? formik.initialValues[name] : value;
      formik.setFieldValue(name, newValue);
      props.onChange && props.onChange();
    },
    onBlur: () => formik.setFieldTouched(name),
  });
};
