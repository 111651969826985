import { Box, Theme, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useMemo } from "react";
import { UVDConnectionStatus } from "utils/UVD/useUvdConnectionStatus";

export const Dot = styled(Box)(
  ({
    theme,
    size,
    color,
  }: {
    theme?: Theme;
    size?: number;
    color?: string;
  }) => ({
    height: `${size || 8}px`,
    width: `${size || 8}px`,
    minWidth: `${size || 8}px`,
    backgroundColor: color || theme?.palette.grey[300],
    borderRadius: 100,
    marginRight: theme?.spacing(2),
  }),
);

const OutlinedDot = styled(Dot)(
  ({ theme, color }: { theme?: Theme; size?: number; color?: string }) => ({
    border: `1px solid ${color || theme?.palette.grey[500]}`,
    backgroundColor: "transparent",
  }),
);

interface RobotStatusDotProps {
  status: UVDConnectionStatus | undefined;
  sx?: any;
}

export const RobotStatusDot = ({ sx, status }: RobotStatusDotProps) => {
  const theme = useTheme();

  const statusColor = useMemo(() => {
    switch (status) {
      case UVDConnectionStatus.SERVICE_NEEDED:
        return theme.palette.warning.main;
      case UVDConnectionStatus.ONLINE:
        return theme.palette.success.main;
      case UVDConnectionStatus.OFFLINE:
        return theme.palette.grey[300];
      default:
        return undefined;
    }
  }, [status]);

  if (status === UVDConnectionStatus.NOT_CONNECTED || !status)
    return (
      <OutlinedDot
        sx={{ ...sx, boxSizing: "border-box" }}
        color={statusColor}
      />
    );

  return <Dot sx={sx} color={statusColor} />;
};
