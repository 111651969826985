import { Chip, styled } from "@mui/material";

export const Pill = styled(Chip)(() => ({
  fontSize: 8,
  lineHeight: "normal",
  textTransform: "uppercase",
  borderRadius: 16,
  padding: "1px 7px",
  height: "12px",
  span: {
    padding: 0,
  },
}));
