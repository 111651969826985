import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { OrganizationCardContent } from "components/modules/cards/OrganizationCardContent";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { FmRoles } from "components/modules/FmRoles";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { useFormik } from "formik";
import { Identity, Role, RoleType } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { roles } from "trans/definedMessages/roleMessages";
import { hasSupportRole, isSupportPath } from "utils/privileges/privilegeUtils";
import { getUserRefetchQueries } from "utils/refetchQueries";
import { useUserDisplayName } from "utils/useUserDisplayName";
import * as Yup from "yup";
import { INVITE } from "../dialogs/InviteUser";
import { REVOKE } from "./EditSupportRole";

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  selectedAffiliation: any;
  identity: Identity;
  userIsMe?: boolean;
}

type Values = {
  role: string;
};

export const getAlertLabel = (
  isLastAffiliation: boolean,
  userIsMe: boolean,
  supportRole?: RoleType,
  intl?: any,
) => {
  if (isLastAffiliation) {
    return userIsMe
      ? {
          button: "Delete My Profile",
          message:
            "This is your last role in the system. Are you sure you want to remove this role and thereby your access to the system?",
        }
      : {
          button: "Delete user",
          message:
            "This is the user's last role in the system. Are you sure you want to remove this role and thereby the user's access to the system?",
        };
  } else {
    return {
      button: supportRole
        ? `Remove ${intl.formatMessage(roles[supportRole])} Role`
        : "Remove user from organization",
      message: "Are you sure?",
    };
  }
};

export const EditOrganizationRole = ({
  open,
  onClose,
  selectedAffiliation,
  identity,
  userIsMe,
}: Props) => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const supportPath = isSupportPath(location);
  const displayName = useUserDisplayName(identity);

  const isSupport = hasSupportRole(identity);
  const isLastAffiliation =
    (identity?.affiliations ?? []).length === 1 && !isSupport;
  const defaultRole = _.first(selectedAffiliation.roles) as Role;

  const refetchQueries = getUserRefetchQueries(
    selectedAffiliation.organization.id,
    identity.id,
  );

  const [inviteUser] = useMutation(INVITE, {
    refetchQueries,
  });

  const [revokeUserRole, { client }] = useMutation(REVOKE, {
    refetchQueries,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    formik.setFieldValue("role", defaultRole?.title || "");
  }, [defaultRole]);

  const formik = useFormik<Values>({
    initialValues: {
      role: defaultRole?.title || "",
    },
    validationSchema: Yup.object({
      role: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Updating role...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      inviteUser({
        variables: {
          inviteInputs: {
            email: identity.email,
            role: values.role as RoleType,
          },
          orgId: selectedAffiliation.organization.id,
        },
        onCompleted: () => {
          enqueueSnackbar(`Updated role for ${displayName}`, {
            variant: "success",
          });
          if (values.role === RoleType.Viewer && userIsMe && !supportPath) {
            navigate("/cloud/profile");
          }
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not update role for ${displayName}`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  const handleRevokeRole = () => {
    const snackbarId = enqueueSnackbar("Removing role...", {
      variant: "loading",
      persist: true,
    });
    handleClose();

    revokeUserRole({
      variables: {
        userId: identity.id,
        role: defaultRole.title as RoleType,
        orgId: selectedAffiliation.organization.id,
      },
      onCompleted: () => {
        closeSnackbar(snackbarId);

        const snackbarMessage = isLastAffiliation
          ? userIsMe
            ? "Deleted my profile"
            : `Deleted user ${displayName}`
          : `Removed ${displayName} from ${selectedAffiliation.organization?.name}`;

        enqueueSnackbar(snackbarMessage, {
          variant: "success",
        });
        if (isLastAffiliation) {
          if (userIsMe) {
            firebase.logout();
            closeSnackbar();
            navigate("/");
            client.clearStore();
          } else {
            if (supportPath) {
              navigate("/cloud/support/users");
            } else {
              navigate(
                `/cloud/fm/${selectedAffiliation.organization.id}/users`,
              );
            }
          }
        } else if (userIsMe && !supportPath) {
          navigate("/cloud/profile");
        } else {
          if (supportPath) {
          } else {
            navigate(`/cloud/fm/${selectedAffiliation.organization.id}/users`);
          }
        }
      },
      onError: () => {
        enqueueSnackbar(
          `Could not remove ${displayName} role from ${selectedAffiliation.organization?.name}`,
          {
            variant: "error",
          },
        );
        closeSnackbar(snackbarId);
      },
    });
  };

  const alertLabel = getAlertLabel(isLastAffiliation, userIsMe || false);

  return (
    <form>
      <CloudDrawer
        open={open}
        onClose={handleClose}
        header="Edit role"
        action={{
          label: "Save changes",
          onClick: formik.handleSubmit,
          disabled: formik.values.role === defaultRole.title || !formik.isValid,
        }}
        secondaryAction={{
          label: "Remove user from organization",
          onClick: handleRevokeRole,
          color: "error",
        }}
        alertLabel={alertLabel.message}
        alertSubmitButtonLabel={alertLabel.button}
      >
        <Box>
          <OrganizationCardContent
            organization={selectedAffiliation.organization}
            header={selectedAffiliation.organization?.name || "-"}
            description={selectedAffiliation.roles
              .map((item: Role) =>
                intl.formatMessage(roles[item.title as RoleType]),
              )
              .join(", ")}
            wrapperStyle={{ padding: "8px 0px" }}
            hideIcon
          />
        </Box>
        <FmRoles
          organizationId={selectedAffiliation.organization.id}
          radioGroupProps={{
            onBlur: () => formik.setFieldTouched("role"),
          }}
          value={formik.values.role}
          onChange={(role: any) => formik.setFieldValue("role", role)}
        />
      </CloudDrawer>
    </form>
  );
};
