import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { CSVDisinfectionDownloader } from "components/modules/CSVDisinfectionDownloader";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { DisinfectedCell } from "components/modules/tableCells/DisinfectedCell";
import { DisinfectionModeCell } from "components/modules/tableCells/DisinfectionModeCell";
import { RobotCell } from "components/modules/tableCells/RobotCell";
import { UVDRobotStatusIconCell } from "components/modules/tableCells/UVDRobotStatusIconCell";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { secondsToHMS } from "utils/utilFunctions";
import { getUVDSerialNumber } from "utils/UVD/getUVDRobotSerialNumber";
import { getRoom } from "utils/UVD/UVDUtils";
import { DisinfectionReportDialog } from "../disinfectionReportWidgets/DisinfectionReportDialog";

const columns: GridColDef[] = [
  {
    field: "status",
    headerName: "",
    width: 20,
    sortable: false,
    renderCell: (params) => <UVDRobotStatusIconCell status={params.value} />,
  },
  {
    field: "start",
    headerName: "Date",
    headerAlign: "right",
    flex: 1,
    maxWidth: 150,
    align: "right",
    type: "date",
    renderCell: (params: GridRenderCellParams) =>
      params.value !== undefined
        ? format(new Date(params.value), "dd LLLL yyyy")
        : "",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "startedBy",
    headerName: "Started by operator",
    flex: 1,
    maxWidth: 300,
    renderCell: (params: GridRenderCellParams) => (
      <Typography fontWeight={500}>{params.value}</Typography>
    ),
  },
  {
    field: "room",
    headerName: "Room",
    flex: 1,
    maxWidth: 300,
    renderCell: (params: GridRenderCellParams) =>
      getRoom(params.row.room, params.row.department),
  },
  {
    field: "disinfected",
    headerName: "Disinfected",
    flex: 1,
    maxWidth: 100,
    sortable: false,
    headerAlign: "right",
    align: "right",
    renderCell: (params) => <DisinfectedCell disinfection={params.row} />,
  },
  {
    field: "start-finish",
    headerName: "Start - Finish",
    flex: 1,
    maxWidth: 150,
    headerAlign: "right",
    align: "right",
    type: "dateTime",
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      params.row?.start === undefined && params.row?.end === undefined
        ? ""
        : [
            format(new Date(params.row.start), "p", { locale: da }),
            format(new Date(params.row.end), "p", { locale: da }),
          ].join(" - "),
  },
  {
    field: "uvcLightDuration",
    headerName: "UV-C Duration",
    flex: 1,
    maxWidth: 150,
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams) =>
      params.row?.start === undefined && params.row?.end === undefined
        ? ""
        : secondsToHMS(params.value),
  },
  {
    field: "type",
    headerName: "Mode",
    flex: 1,
    maxWidth: 150,
    renderCell: (params) => <DisinfectionModeCell type={params.row.type} />,
  },
  {
    field: "robot",
    headerName: "Robot",
    flex: 1,
    maxWidth: 200,
    renderCell: (params: GridRenderCellParams) => (
      <RobotCell
        serialNumber={params.row.robotId}
        robot={params.row}
        showStatusDot
        showOrg
      />
    ),
    valueGetter: (params) =>
      params.row?.robot?.name ||
      getUVDSerialNumber(params.row?.robotId) ||
      undefined,
  },
];

const QUERY = graphql(`
  query DisinfectionReports($filter: DisinfectionFilter!) {
    disinfections(filter: $filter) {
      disinfections {
        id
        robotId
        robot {
          id
          robot {
            serialNumber
          }
          active {
            serialNumber
          }
          organization {
            id
            name
          }
        }
        robotId
        department
        distanceTraveled
        end
        interruptions {
          cause
          connectionClosed
          connectionUnstable
          fromUI
          functionButton
          heatDetection
          internalError
          localizationLoss
          lowBattery
          personDetection
          resetButton
          robotStuck
          tabletMovement
          walkDetection
        }
        room
        start
        startedBy
        status
        submittedBy
        type
        uvcLightDuration
        positions {
          _id
          name
          status
        }
        navigationMap {
          id
          active
          area
          mapItems {
            id
            name
            position {
              x
              y
              yaw
            }
            category
          }
          origin {
            x
            y
            yaw
          }
          resolution
          size {
            height
            width
          }
          name
          navigationMapFile {
            id
            name
          }
          zones {
            id
            name
            category
            mapItems {
              id
              category
              name
              order
              position {
                x
                y
                yaw
              }
              time
            }
          }
        }
        heatMap {
          id
          name
        }
      }
      count
    }
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const DisinfectionsReportsTable = ({ hiddenFilter }: Props) => {
  const prevFilterParams = useRef();
  const filterParams = useDDFilterParams();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [page, setPage] = useState(0);
  const [reportOpen, setReportOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
  });

  useEffect(() => {
    prevFilterParams.current = filterParams;
  }, []);

  useEffect(() => {
    if (!_.isEqual(filterParams, prevFilterParams.current)) {
      setPage(0);
    }
    prevFilterParams.current = filterParams;
  }, [filterParams]);

  const handleOpenReport = (params: GridRowParams) => {
    setSelectedReport(params.row);
    setReportOpen(true);
  };

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <WidgetHeader
            label={<FormattedMessage id="reports" defaultMessage="Reports" />}
          />

          <CSVDisinfectionDownloader
            disabled={
              loading || (data?.disinfections.disinfections ?? []).length === 0
            }
            filters={{
              ...filterParams,
              ...hiddenFilter,
            }}
          />
        </Box>
        <CloudDataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                startedBy: largeScreen,
                "start-finish": largeScreen,
                uvcLightDuration: largeScreen,
              },
            },
          }}
          rows={data?.disinfections.disinfections ?? []}
          columns={columns}
          onRowClick={handleOpenReport}
          page={page}
          onPageChanged={setPage}
          loading={loading}
          rowCount={data?.disinfections.count}
          paginationMode="client"
        />
      </WidgetPaper>
      {selectedReport ? (
        <DisinfectionReportDialog
          open={reportOpen}
          onClose={() => setReportOpen(false)}
          selectedReport={selectedReport}
        />
      ) : null}
    </>
  );
};
