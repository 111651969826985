import { Drawer, DrawerProps } from "@mui/material";
import { DrawerContent } from "components/atoms/drawer/DrawerContent";
import {
  DrawerFooter,
  DrawerFooterProps,
} from "components/atoms/drawer/DrawerFooter";
import { DrawerHeader } from "components/atoms/drawer/DrawerHeader";
import { DrawerWrapper } from "components/atoms/drawer/DrawerWrapper";
import { ReactElement } from "react";

export interface CloudDrawerProps {
  open: DrawerProps["open"];
  onClose: () => void;
  children: ReactElement[] | ReactElement | string | null | any;
  header: string | ReactElement;
  hideFooter?: boolean;
}

export const CloudDrawer = ({
  open,
  onClose,
  children,
  header,
  hideFooter = false,
  ...footerProps
}: CloudDrawerProps & DrawerFooterProps) => {
  return (
    <Drawer
      sx={{
        minWidth: 500,
      }}
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <DrawerWrapper>
        <DrawerHeader
          wrapperStyle={{ paddingBottom: 0 }}
          header={header}
          onClose={onClose}
        />
        <DrawerContent hideFooter={hideFooter}>{children}</DrawerContent>
        {hideFooter ? null : <DrawerFooter {...footerProps} />}
      </DrawerWrapper>
    </Drawer>
  );
};
