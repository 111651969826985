import { Grid } from "@mui/material";
import { CloudBreadCrumb, Crumb } from "components/modules/CloudBreadCrumb";
import { ReactElement } from "react";

export const HeaderGrid = ({
  children,
  crumbs,
}: {
  crumbs: Array<Crumb>;
  children?: ReactElement;
}) => {
  return (
    <Grid container item spacing={6} sx={{ marginTop: "-16px" }}>
      <Grid item xs={6}>
        <CloudBreadCrumb crumbs={crumbs} />
      </Grid>
      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {children}
      </Grid>
    </Grid>
  );
};
