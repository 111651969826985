import { Font, StyleSheet } from "@react-pdf/renderer";
import industryBold from "assets/fonts/Industry/Industry-Bold.ttf";
import robotoMedium from "assets/fonts/Roboto/Roboto-Medium.ttf";
import robotoRegular from "assets/fonts/Roboto/Roboto-Regular.ttf";

Font.register({
  family: "Industry",
  src: industryBold,
});

Font.register({
  family: "Roboto",
  src: robotoRegular,
});

Font.register({
  family: "Roboto-Medium",
  src: robotoMedium,
});

// Documentation in https://react-pdf.org/styling
export const styles = StyleSheet.create({
  page: {
    padding: 50,
  },
  mainHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    height: 40,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
    alignContent: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  headerContainer: {
    marginBottom: 15,
  },
  headerInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 10,
    fontSize: 9,
  },
  leftHeaderInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  rightHeaderInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "flex-end",
  },
  boldText: {
    fontFamily: "Roboto-Medium",
  },
  detailListItem: {
    marginBottom: 4,
  },
  mainHeader: {
    flex: 1,
    fontFamily: "Industry",
    fontSize: 16,
  },
  headerLogo: {
    width: 73,
    height: "auto",
    objectFit: "contain",
  },
  disinfectionStatusLogo: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  disinfectionStatusWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 4,
    fontSize: 12,
  },
  disinfectionStatus: {
    textTransform: "uppercase",
    fontFamily: "Industry",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  listItem: {
    width: "100%",
    paddingVertical: 7,
    flexDirection: "row",
    borderBottom: 0.5,
    borderBottomStyle: "solid",
    borderBottomColor: "#dddddd",
    fontSize: 9,
    fontFamily: "Roboto",
  },
  listItemNoBorder: {
    width: "100%",
    paddingVertical: 7,
    flexDirection: "row",
    fontSize: 9,
    fontFamily: "Roboto",
  },
  listItemHeader: {
    justifyContent: "flex-start",
    flexGrow: 3,
    fontFamily: "Roboto-Medium",
  },
  listItemValue: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
  detailsTxt: {
    fontSize: 12,
    fontFamily: "Industry",
    borderBottom: "none",
    color: "#167DBC",
    textTransform: "uppercase",
  },
  mapImageContainer: {
    width: "100%",
    height: "auto",
    backgroundColor: "#666666",
    position: "relative",
    paddingBottom: 10,
    marginBottom: 12,
  },
  emptyMapContainer: {
    height: 300,
    width: "100%",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(204, 204, 204, 1)",
    position: "relative",
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 12,
  },
  emptyMapTextHeader: {
    fontFamily: "Roboto",
    fontSize: 10,
    color: "#A1A5B7",
  },
  mapImage: {
    height: 300,
    width: "auto",
    objectFit: "contain",
  },
  mapLegendWrapper: {
    position: "absolute",
    height: 150,
    width: 80,
    backgroundColor: "rgba(36, 37, 37, 0.2)",
    bottom: 10,
    left: 10,
    fontSize: 9,
    borderRadius: 3,
    padding: 10,
  },
  mapLegendHeader: {
    color: "#ffffff",
    opacity: 1,
    marginBottom: 1,
  },
  mapLegendText: {
    color: "#ffffff",
    opacity: 1,
  },
  mapLegendColor100: {
    height: 20,
    width: 10,
    backgroundColor: "rgb(152, 31, 52)",
    opacity: 1,
  },
  mapLegendColor75: {
    height: 20,
    width: 10,
    backgroundColor: "rgb(204, 30, 26)",
    opacity: 1,
  },
  mapLegendColor50: {
    height: 20,
    width: 10,
    backgroundColor: "rgb(234, 113, 113)",
    opacity: 1,
  },
  mapLegendColor25: {
    height: 20,
    width: 10,
    backgroundColor: "rgb(244, 153, 67)",
    opacity: 1,
  },
  mapLegendColor0: {
    height: 20,
    width: 10,
    backgroundColor: "rgb(240, 228, 0)",
    opacity: 1,
  },
  logoWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    padding: "0 15%",
  },
  logoLine: {
    flexGrow: 1,
    height: 1,
    backgroundColor: "#b2b2b2",
  },
  footerWrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
  },
  pageNumberWrapper: {
    position: "absolute",
    bottom: 20,
    right: 30,
  },
  pageNumber: {
    fontSize: 8,
    textAlign: "right",
    color: "#22a2d9",
  },
  footerLogo: {
    width: 30,
    height: "auto",
    objectFit: "contain",
    margin: "15px 15px 15px",
  },
  positionWrapper: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
  },
  positionIconContainer: {
    position: "relative",
    width: 15,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },
  positionIconImage: {
    width: 14,
    height: 18,
  },
  positionIconText: {
    position: "absolute",
    color: "#fff",
    fontSize: 6,
    textAlign: "center",
    top: 8,
    fontFamily: "Industry",
  },
  remainingPositionIconText: {
    position: "absolute",
    color: "#22A2D9",
    fontSize: 6,
    textAlign: "center",
    top: 8,
    fontFamily: "Industry",
  },
  positionHeader: {
    fontSize: 10,
    marginTop: 12,
    fontFamily: "Industry",
    width: "100%",
  },
  positionText: {
    fontSize: 9,
    maxWidth: 135,
  },
  positionListItem: {
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 400,
    marginBottom: 6,
    width: 150,
    borderBottom: "0.5px solid #DDDDDD",
    display: "flex",
    alignItems: "center",
    marginRight: 15,
    paddingBottom: 3,
  },
});
