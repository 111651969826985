export const PulseIcon = () => {
  return (
    <svg
      width="85"
      height="88"
      viewBox="0 0 85 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="36" r="52" fill="#22A2D9" fillOpacity="0.02" />
      <circle cx="33" cy="36" r="32" fill="#22A2D9" fillOpacity="0.06" />
      <circle cx="33" cy="36" r="18" fill="#22A2D9" fillOpacity="0.18" />
      <circle cx="33" cy="36" r="6" fill="#22A2D9" />
    </svg>
  );
};
