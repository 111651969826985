import { CloudSnackbar } from "components/modules/CloudSnackbar";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";

declare module "notistack" {
  interface VariantOverrides {
    loading: true;
  }
  interface OptionsObject {
    actionLabel?: any;
    onActionClick?: any;
  }
}

export const CloudSnackbarProvider = ({ children }: SnackbarProviderProps) => {
  return (
    <SnackbarProvider
      Components={{
        loading: CloudSnackbar,
        error: CloudSnackbar,
        success: CloudSnackbar,
        warning: CloudSnackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
