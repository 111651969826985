import { CountryAutocomplete } from "components/atoms/autoComplete/CountryAutocomplete";
import { CloudLabel } from "components/atoms/CloudLabel";
import { CountryCodes } from "gql/graphql";
import { SyntheticEvent } from "react";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  value: CountryCodes[] | undefined | null;
  options?: CountryCodes[] | undefined;
  selected: boolean;
}

export const OrganizationCountryFilter = ({
  value,
  onChange,
  options,
  selected,
}: Props & onChangeFilter) => {
  return (
    <>
      <CloudLabel primary={selected}>Organization country</CloudLabel>
      <CountryAutocomplete
        hideLabel
        value={value}
        options={options || undefined}
        placeholder="Country"
        onChange={(event: SyntheticEvent, value: any) =>
          onChange(value, "organizationCountries")
        }
      />
    </>
  );
};
