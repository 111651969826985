import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const miscMsg: DefineMsg = defineMessages({
  organizationOnboardingInviteOwnersSnackbar: {
    id: "organization-onboarding-invite-owners",
    defaultMessage:
      "{count, plural, =0 {} one {Invitation was} other {Invitations were}} sent again",
  },
});
