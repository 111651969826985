import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const CloudInlineLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

CloudInlineLink.defaultProps = {
  target: "_blank",
};
