import { Grid, useTheme } from "@mui/material";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { LargeSpinner } from "components/atoms/LargeSpinner";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { AverageDurationChart } from "components/organisms/charts/AverageDurationChart";
import { CycleOverviewChart } from "components/organisms/charts/CycleOverviewChart";
import { CyclesCounterChart } from "components/organisms/charts/CyclesCounterChart";
import { DisinfectionModesChart } from "components/organisms/charts/DisinfectionModesChart";
import { DisinfectionsReportsTable } from "components/organisms/charts/DisinfectionReportsTable";
import { DisinfectionsByOperatorTable } from "components/organisms/charts/DisinfectionsByOperatorTable";
import { PositionsReachedChart } from "components/organisms/charts/PositionsReachedChart";
import { UniqueOperatorsChart } from "components/organisms/charts/UniqueOperatorsChart";
import { UniqueRoomsChart } from "components/organisms/charts/UniqueRoomsChart";
import { DisinfectionDashboardFilter } from "components/organisms/DisinfectionDashboardFilter";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { useParams } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { ForbiddenPage } from "./ForbiddenPage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ArcElement,
  Legend,
);

const QUERY = graphql(`
  query DisinfectionDashboardPage($orgId: String!) {
    organization(id: $orgId) {
      id
      name
    }
  }
`);

export const DisinfectionDashboardPage = () => {
  const theme = useTheme();
  const { orgId } = useParams();

  const filterParams = useDDFilterParams();

  ChartJS.defaults.font.family = theme.typography.fontFamily;

  const { data, error, loading } = useQueryWithSnack(QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: orgId === "support" || orgId === "" || !orgId,
  });

  const forbidden = getIsForbidden(error);
  if (forbidden) {
    return <ForbiddenPage />;
  }

  if (loading) return <LargeSpinner />;

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={[
          { label: orgId ? data?.organization?.name ?? "-" : "Support" },
          { label: "Disinfections" },
        ]}
      >
        <DisinfectionDashboardFilter
          hiddenFilter={
            orgId
              ? ({ organizationIds: orgId } as unknown as DisinfectionFilter)
              : undefined
          }
        />
      </HeaderGrid>
      <Grid item container xs={12} direction="row" spacing={4}>
        <Grid item xs={6} xl={4} display="flex">
          <CyclesCounterChart
            filter={{
              ...filterParams,
              ...(orgId ? { organizationIds: orgId } : undefined),
            }}
            queryParams={{
              skip: !filterParams?.timeSpan,
            }}
          />
        </Grid>
        <Grid item xs={6} xl={4} display="flex">
          <UniqueRoomsChart
            hiddenFilter={
              orgId
                ? ({
                    organizationIds: orgId,
                  } as unknown as DisinfectionFilter)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} xl={4} display="flex">
          <UniqueOperatorsChart
            hiddenFilter={
              orgId
                ? ({
                    organizationIds: orgId,
                  } as unknown as DisinfectionFilter)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} xl={4} display="flex">
          <DisinfectionModesChart
            hiddenFilter={
              orgId
                ? ({
                    organizationIds: orgId,
                  } as unknown as DisinfectionFilter)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} xl={4} display="flex">
          <AverageDurationChart
            hiddenFilter={
              orgId
                ? ({
                    organizationIds: orgId,
                  } as unknown as DisinfectionFilter)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} xl={4} display="flex">
          <PositionsReachedChart
            hiddenFilter={
              orgId
                ? ({
                    organizationIds: orgId,
                  } as unknown as DisinfectionFilter)
                : undefined
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CycleOverviewChart
          hiddenFilter={
            orgId
              ? ({ organizationIds: orgId } as unknown as DisinfectionFilter)
              : undefined
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DisinfectionsReportsTable
          hiddenFilter={
            orgId
              ? ({ organizationIds: orgId } as unknown as DisinfectionFilter)
              : undefined
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DisinfectionsByOperatorTable
          hiddenFilter={
            orgId
              ? ({ organizationIds: orgId } as unknown as DisinfectionFilter)
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
};
