import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../../config/configHook";
import { useFirebase } from "../../../firebaseWrapper";
import { LoginDefault } from "./LoginDefault";

export const Login = () => {
  const navigate = useNavigate();
  const config = useConfig();
  const firebase = useFirebase(config);
  const [user] = useAuthState(firebase.auth);

  useEffect(() => {
    if (user) {
      navigate("/cloud");
    }
  }, [user]);

  return <LoginDefault />;
};
