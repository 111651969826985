import { Typography } from "@mui/material";

import {
  OrganizationCardContent,
  OrganizationCardContentProps,
} from "../cards/OrganizationCardContent";

export const OrganizationCell = ({
  organization,
}: OrganizationCardContentProps) => {
  if (!organization) return <Typography></Typography>;

  return (
    <OrganizationCardContent
      size="small"
      organization={organization}
      hideIcon
    />
  );
};
