import { Box, Typography } from "@mui/material";
import { Battery } from "components/atoms/battery/Battery";
import { UvdRobot } from "gql/graphql";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";
import { getChargingStatus } from "utils/UVD/UVDRobotStatusConverter";

interface Props {
  hideBattery: boolean;
  batteryMergerPercentage?: number;
  robot: UvdRobot;
}

export const BatteryCell = ({
  hideBattery,
  batteryMergerPercentage,
  robot,
}: Props) => {
  const connectionStatus = useUvdConnectionStatus(robot);
  const chargingStatus = robot?.status?.job
    ? getChargingStatus(robot.status.job)
    : null;

  if (hideBattery || !robot.status) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Battery
        batteryPercent={batteryMergerPercentage ?? 0}
        charging={chargingStatus?.charging ?? false}
        pluggedIn={chargingStatus?.pluggedIn ?? false}
        isOffline={connectionStatus === UVDConnectionStatus.OFFLINE}
      />
      <Typography
        ml={2}
        color={(theme) =>
          connectionStatus === UVDConnectionStatus.OFFLINE
            ? theme.palette.grey[400]
            : "auto"
        }
      >
        {Math.floor(batteryMergerPercentage ?? 0)} %
        {chargingStatus?.charging ? ", Charging" : ""}
      </Typography>
    </Box>
  );
};
