import { gql } from "@apollo/client";
import { Identity } from "gql/graphql";
import _ from "lodash";

export function useUserDisplayName(identity?: Partial<Identity>) {
  if (identity?.firstName || identity?.lastName) {
    return [identity?.firstName, identity?.lastName]
      .filter((item) => !_.isNull(item) && item !== "")
      .join(" ");
  } else if (identity?.email) {
    return identity.email;
  } else {
    return "";
  }
}

useUserDisplayName.fragments = {
  identity: gql`
    fragment UserDisplayName on Identity {
      firstName
      lastName
      email
    }
  `,
};
