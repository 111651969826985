import { Box, Typography } from "@mui/material";
import { Dot } from "components/atoms/RobotStatusDot";
import { ReactElement } from "react";

interface Props {
  header: string | ReactElement;
  description?: string | ReactElement;
  action?: ReactElement;
  hideIcon?: boolean;
  wrapperStyle?: any;
  statusColor?: string;
}

export const StatusCardContent = ({
  header,
  description,
  action,
  statusColor,
  hideIcon,
  wrapperStyle,
}: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        flex: 1,
        "& svg > *": {
          color: theme.palette.grey[600],
        },
        ...wrapperStyle,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
        }}
      >
        {statusColor ? <Dot color={statusColor} /> : null}
        <Box ml={1}>
          <Typography>{header}</Typography>
          {description ? (
            <Typography
              variant="body2"
              color={(theme) => theme.palette.grey[500]}
            >
              {description}
            </Typography>
          ) : null}
        </Box>
      </Box>
      {hideIcon ? null : action}
    </Box>
  );
};
