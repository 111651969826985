import { Box, Button } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { OrganizationStatusCell } from "components/modules/tableCells/OrganizationStatusCell";
import { OrgDisinfectionCountCell } from "components/modules/tableCells/OrgDisinfectionCountCell";
import { OwnerCell } from "components/modules/tableCells/OwnerCell";
import { graphql } from "gql";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { CreateOrganization } from "./dialogs/CreateOrganization";

export const ORG_LIST_QUERY = graphql(`
  query Organizations {
    organizations {
      id
      name
      robotAffiliations {
        robot {
          serialNumber
          active {
            organization {
              id
            }
          }
        }
      }
      country
      activationState
      affiliations {
        identity {
          id
          firstName
          lastName
          email
        }
        roles {
          title
        }
      }
    }
  }
`);

const columns: GridColDef[] = [
  {
    field: "name",
    maxWidth: 300,
    minWidth: 150,
    flex: 1,
    headerName: "Name",
    valueGetter: (params) => params.row?.name,
    renderCell: (params) => <OrganizationCell organization={params.row} />,
  },
  {
    field: "owner",
    maxWidth: 300,
    flex: 1,
    headerName: "Owner",
    sortable: false,
    renderCell: OwnerCell,
  },
  {
    field: "robots",
    maxWidth: 80,
    flex: 1,
    headerName: "Robots",
    align: "right",
    headerAlign: "right",
    valueGetter(params) {
      return params.row.robotAffiliations?.length || 0;
    },
  },
  {
    field: "users",
    maxWidth: 80,
    flex: 1,
    headerName: "Users",
    align: "right",
    headerAlign: "right",
    valueGetter(params) {
      return (params.row.affiliations || [])?.length || 0;
    },
  },
  {
    field: "disinfections",
    align: "right",
    headerAlign: "right",
    maxWidth: 180,
    flex: 1,
    headerName: "Disinfections",
    sortable: false,
    renderCell: OrgDisinfectionCountCell,
  },
  {
    field: "status",
    maxWidth: 100,
    flex: 1,
    headerName: "Status",
    sortable: false,
    renderCell: OrganizationStatusCell,
  },
];

export const Organizations = () => {
  const navigate = useNavigate();
  const [createOrgDialogOpen, setCreateOrgDialogOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(ORG_LIST_QUERY);

  const handleNavigate = (params: GridRowParams) => {
    navigate(`/cloud/support/organizations/${params.row.id}`);
  };

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <WidgetHeader
            label={
              <FormattedMessage
                id="organizations"
                defaultMessage="Organizations"
              />
            }
          />

          <Button
            onClick={() => setCreateOrgDialogOpen(true)}
            variant="outlined"
            size="small"
          >
            New organization
          </Button>
        </Box>
        <CloudDataGrid
          onRowClick={handleNavigate}
          rows={data?.organizations || []}
          columns={columns}
          loading={loading}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          pageSize={25}
        />
      </WidgetPaper>
      <CreateOrganization
        open={createOrgDialogOpen}
        onClose={() => setCreateOrgDialogOpen(false)}
      />
    </>
  );
};
