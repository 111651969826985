import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const columns = [
  {
    field: "operator",
    headerName: "Operator",
    flex: 1,
    maxWidth: 300,
    renderCell: (params: GridRenderCellParams) => (
      <Typography fontWeight={500}>{params.value}</Typography>
    ),
  },
  {
    field: "complete",
    headerName: "Complete",
    maxWidth: 100,
    type: "number",
    flex: 1,
  },
  {
    field: "incomplete",
    headerName: "Incomplete",
    maxWidth: 100,
    type: "number",
    flex: 1,
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    maxWidth: 100,
    flex: 1,
  },
];

const QUERY = graphql(`
  query DisinfectionByOperator($filter: DisinfectionFilter!) {
    disinfectionsGroupedByOperator(filter: $filter) {
      operator
      complete
      incomplete
      total
    }
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const DisinfectionsByOperatorTable = ({ hiddenFilter }: Props) => {
  const filterParams = useDDFilterParams();
  const [page, setPage] = useState(0);

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
  });

  return (
    <WidgetPaper>
      <WidgetHeader
        label={
          <FormattedMessage id="by-operator" defaultMessage="By Operator" />
        }
        description={
          <FormattedMessage
            id="by-operator-description"
            defaultMessage="Number of disinfection cycles per operator in this period"
          />
        }
      />
      <CloudDataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "total", sort: "desc" }],
          },
        }}
        rows={
          data?.disinfectionsGroupedByOperator.map((item, index) => ({
            ...item,
            id: index + item.operator,
          })) ?? []
        }
        columns={columns}
        rowCount={data?.disinfectionsGroupedByOperator.length ?? 0}
        page={page}
        onPageChanged={setPage}
        loading={loading}
      />
    </WidgetPaper>
  );
};
