import { gql } from "@apollo/client";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { MiniDisinfectionModes } from "components/modules/tableCells/MiniDisinfectionModes";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getFormattedDate } from "utils/utilFunctions";
import { ORG_QUERY } from "./UserPage";

const QUERY = gql`
  query DisinfectionsByRoom($filter: DisinfectionFilter) {
    disinfectionRooms(filter: $filter) {
      autonomous
      department
      lastDateDisinfected
      remoteControlled
      room
      stationary
    }
  }
`;

const columns: GridColDef[] = [
  {
    field: "room",
    flex: 1,
    maxWidth: 250,
    headerName: "Room name",
    renderCell: (params) => (
      <Typography fontWeight={500}>{params.value}</Typography>
    ),
  },
  {
    field: "department",
    headerName: "Department",
    flex: 1,
    maxWidth: 250,
    renderCell: (params) =>
      params.value !== "" ? (
        <Chip label={params.value} variant="outlined" size="small" />
      ) : null,
  },
  {
    field: "lastDateDisinfected",
    flex: 1,
    maxWidth: 150,
    align: "right",
    headerAlign: "right",
    headerName: "Last disinfected on",
    renderCell: (params) => getFormattedDate(new Date(params.value)),
  },
  {
    field: "disinfections",
    headerName: "Disinfections",
    renderCell: (params) =>
      params.row.autonomous +
      params.row.remoteControlled +
      params.row.stationary,
    align: "right",
    headerAlign: "right",
    flex: 1,
    maxWidth: 150,
    valueGetter(params) {
      return (
        params.row.autonomous +
        params.row.remoteControlled +
        params.row.stationary
      );
    },
  },
  {
    field: "graph",
    headerName: "",
    renderCell: MiniDisinfectionModes,
    width: 76,
    sortable: false,
  },
  {
    field: "autonomous",
    headerName: "Predefined",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "remoteControlled",
    headerName: "Remote Controlled",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "stationary",
    headerName: "Stationary",
    align: "right",
    headerAlign: "right",
  },
];

export const DisinfectionsByRoomPage = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        organizationIds: [orgId],
      },
    },
  });

  const { data: orgData } = useQueryWithSnack(ORG_QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId || orgId === "support",
  });

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={
          location.pathname.includes("support")
            ? [
                { label: "Support" },
                {
                  label: "Organizations",
                  onClick: () => navigate(`/cloud/support/organizations`),
                },
                {
                  label: orgId && orgData ? orgData?.organization.name : "-",
                  onClick: () =>
                    navigate(`/cloud/support/organizations/${orgId}`),
                },
                {
                  label: "Rooms",
                },
              ]
            : [
                {
                  label: orgId && orgData ? orgData?.organization.name : "-",
                },
                {
                  label: "Rooms",
                },
              ]
        }
      />
      <Grid item xs={12}>
        <WidgetPaper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <WidgetHeader
              label={
                <FormattedMessage
                  id="disinfection-by-room"
                  defaultMessage="Disinfections per Room"
                />
              }
              description="Number of disinfection cycles per room"
            />
          </Box>
          <CloudDataGrid
            rows={data?.disinfectionRooms || []}
            columns={columns || []}
            loading={loading}
            getRowId={(option) => option.lastDateDisinfected}
            pageSize={100}
          />
        </WidgetPaper>
      </Grid>
    </Grid>
  );
};
