import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { MoreText } from "components/atoms/MoreText";
import { Identity } from "gql/graphql";
import _ from "lodash";
import { getOrganizationAdmins } from "utils/affiliationUtils";
import { useUserDisplayName } from "utils/useUserDisplayName";

export const OwnerCell = (params: GridRenderCellParams) => {
  const { owners } = getOrganizationAdmins(params.row.affiliations);

  const firstOwner = _.first(owners) as Identity | undefined;

  const displayName = useUserDisplayName(firstOwner);

  if (owners.length === 0) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography>
          {displayName}
          {owners.length > 1 ? (
            <MoreText>+ {owners.length - 1} more</MoreText>
          ) : undefined}
        </Typography>
      </Box>
    </Box>
  );
};
