import { createContext, useContext } from "react";
import { defaultConfig } from "./defaultConfig";

const ConfigContext = createContext(defaultConfig);

export const ConfigProvider = ConfigContext.Provider;

export function useConfig() {
  const value = useContext(ConfigContext);

  if (value === undefined) {
    throw new Error("useConfig was used outside of its Provider");
  }

  return value;
}
