import { Box, Button, styled, Typography } from "@mui/material";
import { useMemo } from "react";
import { IoOpenOutline } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { ROUTES } from "routing/Routes";

const ProviderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

ProviderText.defaultProps = {
  variant: "body2",
};

const Password = () => {
  return (
    <>
      <Typography color={(theme) => theme.palette.grey[600]} variant="body2">
        You can change your password here:
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{ margin: 2 }}
          target="_blank"
          href={ROUTES.login.resetPassword}
          endIcon={<IoOpenOutline />}
          variant="text"
        >
          Reset password
        </Button>
      </Box>
    </>
  );
};

export const PasswordReset = ({ identity }: any) => {
  const usingPassword = identity?.loginWithPassword;

  const providers = useMemo(() => {
    let providers: Array<string> = [];

    if (identity?.loginWithGoogle) {
      providers = providers.concat("Google");
    }
    if (identity?.loginWithMicrosoft) {
      providers = providers.concat("Microsoft");
    }

    return providers;
  }, [identity?.loginWithGoogle, identity?.loginWithMicrosoft]);

  if (usingPassword) {
    return providers.length === 0 ? (
      <Password />
    ) : providers.length === 1 ? (
      <>
        <Password />
        <ProviderText>
          <FormattedMessage
            id="reset-password-single-sso-and-password"
            defaultMessage="You are also using your {provider} account to log in with. Follow your own organization's policy to change that password."
            values={{
              provider: providers[0],
            }}
          />
        </ProviderText>
      </>
    ) : providers.length === 2 ? (
      <>
        <Password />
        <ProviderText>
          <FormattedMessage
            id="reset-password-multiple-sso-and-password"
            defaultMessage="You are also using your {provider} accounts to log in with. Follow your own organization's policy to change those passwords."
            values={{
              provider: providers.join(" / "),
            }}
          />
        </ProviderText>
      </>
    ) : null;
  } else {
    return providers.length === 1 ? (
      <ProviderText>
        <FormattedMessage
          id="reset-password-sso-single"
          defaultMessage="You are using your {provider} account to log in with. Follow your own organization's policy to change the password."
          values={{
            provider: providers[0],
          }}
        />
      </ProviderText>
    ) : providers.length === 2 ? (
      <ProviderText>
        <FormattedMessage
          id="reset-password-sso-multiple"
          defaultMessage="You are using your {providers} accounts to log in with. Follow your own organization's policy to change those passwords."
          values={{
            providers: providers.join(" / "),
          }}
        />
      </ProviderText>
    ) : null;
  }
};
