import { styled } from "@mui/material";

export const ListElement = styled("li")(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[600],
  padding: 3,
}));

export const OrderedList = styled("ol")(() => ({
  paddingInlineStart: 20,
  margin: "8px 0px",
}));
