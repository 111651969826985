import { Box, Pagination, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {
  DataGrid,
  DataGridProps,
  GridSlotsComponentsProps,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useState } from "react";

interface CloudDataGridProps extends DataGridProps {
  pageSize?: number;
  height?: number;
  onPageChanged?: (page: number) => void;
  page?: number;
  hideFooter?: boolean;
}

export const CloudDataGrid = ({
  rows,
  columns,
  pageSize = 10,
  loading,
  hideFooter,
  getRowId,
  onRowClick,
  initialState,
  page: pageFromProps,
  onPageChanged,
  height,
  paginationMode,
  rowCount: rowCountFromProps,
}: CloudDataGridProps) => {
  const [page, setPage] = useState(0);

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      getRowId={getRowId}
      autoHeight
      height={height}
      hideFooter={hideFooter}
      paginationMode={paginationMode || "client"}
      pageSize={pageSize}
      onRowClick={onRowClick}
      rowCount={rowCountFromProps || (rows || []).length}
      slots={{
        footer: CustomFooter,
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      paginationModel={{ pageSize, page: pageFromProps || page }}
      initialState={{
        pagination: {
          paginationModel: { pageSize, page: pageFromProps || page },
        },
        ...initialState,
      }}
      slotProps={{
        footer: {
          onPageChange: onPageChanged || setPage,
          loading: loading,
        },
      }}
    />
  );
};

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    totalCount: number;
    onPageChange: any;
    totalPages: number;
    page: number;
    pageSize: number;
    loading: boolean;
  }
  interface ColumnSortedAscendingIconPropsOverrides {
    style: any;
  }
}

function CustomFooter(props: NonNullable<GridSlotsComponentsProps["footer"]>) {
  const apiRef = useGridApiContext();
  const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
  const currentPage = apiRef.current.state.pagination.paginationModel.page;
  const rowCount = apiRef.current.getRowsCount();
  const totalPagesCount = Math.ceil(rowCount / pageSize);

  let startingIndex = currentPage * pageSize + 1;
  let endingIndex = startingIndex + pageSize - 1;

  if (rowCount < 1) {
    startingIndex = 0;
  }
  if (endingIndex > rowCount) {
    endingIndex = rowCount;
  }

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: theme.spacing(6),
      })}
    >
      <Typography fontWeight={500} color={(theme) => theme.palette.grey[700]}>
        Showing {startingIndex} - {endingIndex} out of {rowCount}
      </Typography>

      {totalPagesCount > 1 ? (
        <Pagination
          shape="rounded"
          page={currentPage + 1}
          count={totalPagesCount}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            props.onPageChange(value - 1);
            apiRef.current.setPage(value - 1);
          }}
          disabled={totalPagesCount < 2}
        />
      ) : null}
    </Box>
  );
}

const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography color={(theme) => theme.palette.grey[600]}>
        No data
      </Typography>
    </Box>
  );
};

interface StyledDataGridProps extends DataGridProps {
  pageSize: number;
  height?: number;
}

const rowHeight = 53;

const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop: any) => !["pageSize", "height"].includes(prop),
})<StyledDataGridProps>(
  ({ theme, pageSize, rows, rowCount, height, onRowClick }) => ({
    marginTop: 16,
    border: "none",
    "--DataGrid-overlayHeight": "296px",
    "& .MuiDataGrid-overlay": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      borderRadius: 5,
    },
    ".MuiDataGrid-columnHeaders": {
      lineHeight: "40px !important",
      minHeight: "40px !important",
      maxHeight: "40px !important",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
      paddingLeft: 6,
      backgroundColor: "#fff",
      lineHeight: "40px !important",
      minHeight: "40px !important",
      maxHeight: "40px !important",
      alignItems: "center",
      ":focus": {
        outline: "none",
      },
      ":focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-iconButtonContainer": {
      ":focus": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontFamily: "Industry-Medium",
      textTransform: "capitalize",
      color: theme.palette.grey[600],
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
      color: theme.palette.grey[900],
      fontSize: "14px",
      minWidth: "30px",
      ":focus": {
        outline: "none",
      },
      ":focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid.cell, .MuiDataGrid-row:hover": {
      cursor: !!onRowClick ? "pointer" : "auto",
      background: theme.palette.grey[200],
    },
    "& .MuiDataGrid-row, .MuiDataGrid-row--lastVisible": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    "& .MuiDataGrid-iconButtonContainer svg ": {
      height: "12px",
      width: "auto",
      marginBottom: "1px",
    },
    "& .MuiDataGrid-virtualScroller": {
      height: height || "auto",
      minHeight:
        height ||
        Math.max(
          296,
          (Math.ceil(Math.max(rowCount || 0, rows.length) / pageSize) > 1
            ? pageSize
            : Math.min(rows.length, pageSize)) * rowHeight,
        ),
    },
  }),
);
