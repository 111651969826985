import { styled, Typography, TypographyProps } from "@mui/material";

interface CloudLabelProps extends TypographyProps {
  primary?: boolean;
}

export const CloudLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "primary",
})<CloudLabelProps>(({ theme, primary }) => ({
  textTransform: "uppercase",
  lineHeight: "20px",
  fontSize: "12px",
  fontWeight: 700,
  color: primary ? theme.palette.primary.main : theme.palette.grey[500],
  paddingTop: theme?.spacing(4),
  paddingBottom: theme?.spacing(1),
}));

CloudLabel.defaultProps = {
  variant: "body2",
};
