import { GridRenderCellParams } from "@mui/x-data-grid";
import { Pill } from "components/atoms/Pill";
import { ReactElement, useMemo } from "react";

type ChipColor =
  | "success"
  | "warning"
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | undefined;

export const UserStatusCell = (params: GridRenderCellParams) => {
  const status: {
    color: ChipColor;
    label: string | ReactElement;
  } = useMemo(() => {
    switch (params.row.inviteState) {
      case "ACTIVE":
        return { color: "success", label: "ACTIVE" };
      case "PENDING":
      default:
        return { color: "warning", label: "PENDING" };
    }
  }, [params.row]);

  return <Pill label={status.label} color={status.color} size="small" />;
};
