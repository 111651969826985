import { Box, Skeleton, useTheme } from "@mui/material";
import { InlineCard } from "components/atoms/InlineCard";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { DNSVerificationCard } from "components/modules/cards/DNSVerificationCard";
import { StatusCardContent } from "components/modules/cards/StatusCardContent";
import { graphql } from "gql";
import { Privilege } from "gql/graphql";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { AddDomain } from "../dialogs/AddDomain";

export const USERAUTH_QUERY = graphql(`
  query UserAuthentication($orgId: String!) {
    organization(id: $orgId) {
      id
      domainVerifications {
        id
        hostname
        verified
      }
    }
  }
`);

export const UserAuthentication = () => {
  const { orgId } = useParams();
  const theme = useTheme();
  const canAddDomain = usePrivilege(
    Privilege.DomainVerificationCreate,
    orgId,
  ).hasPrivilege;

  const [createDNSDialogOpen, setCreateDNSDialogOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(USERAUTH_QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: !orgId,
  });

  if (loading) return <Skeleton />;

  return (
    <WidgetPaper>
      <WidgetHeader
        label="User Authentication"
        description="Add a domain to enable authentication using an authentication
            service."
        buttonLabel={canAddDomain ? "Add Domain" : undefined}
        onButtonClick={() => setCreateDNSDialogOpen(true)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginTop: "16px",
        }}
      >
        <InlineCard disabled>
          <StatusCardContent
            header="Email and Password"
            description="Users can be onboarded by inviting them"
            statusColor={theme.palette.success.main}
          />
        </InlineCard>
        {data?.organization.domainVerifications.map((item) => (
          <DNSVerificationCard key={item.id} {...item} />
        ))}
      </Box>
      <AddDomain
        open={createDNSDialogOpen}
        onClose={() => setCreateDNSDialogOpen(false)}
      />
    </WidgetPaper>
  );
};
