import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
} from "@mui/material";
import { ReactElement } from "react";

export const SimpleTable = ({
  data,
  columnConfig,
  onRowClick,
  tableRowProps,
  noDataMessage,
}: {
  data: Array<any> | undefined;
  columnConfig: Array<{
    key: string;
    renderCell: (
      row: any,
      rowIndex: number,
    ) => ReactElement | string | number | null;
    sx?: any;
    tableCellProps?: TableCellProps;
  }>;
  onRowClick?: any;
  tableRowProps?: TableRowProps;
  noDataMessage?: string | ReactElement;
}) => {
  return (
    <Table sx={{ width: "100%" }}>
      <TableBody>
        {data && data?.length > 0 ? (
          data?.map((item: any, rowIndex: any) => (
            <TableRow
              onClick={
                !!onRowClick ? (e: any) => onRowClick(item, e) : undefined
              }
              key={item?.id}
              {...tableRowProps}
            >
              {columnConfig.map((column: any) => (
                <TableCell
                  key={column.key}
                  sx={{
                    ...column.sx,
                    border: rowIndex + 1 === data.length ? "none" : "auto",
                  }}
                  {...column.tableCellProps}
                >
                  {column.renderCell(item, rowIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <Box
            sx={(theme) => ({
              width: "fit-content",
              margin: "0 auto",
              marginTop: 4,
              marginBottom: 4,
              color: theme.palette.grey[600],
            })}
          >
            {noDataMessage || "No data"}
          </Box>
        )}
      </TableBody>
    </Table>
  );
};
