import { DisinfectionModes, DisinfectionStatus, RobotType } from "gql/graphql";
import { defineMessages } from "react-intl";
import { DefineMsg } from "types/types";

export const enumMsg: DefineMsg = defineMessages({
  [DisinfectionModes.Stationary]: {
    id: "enum.Stationary",
    defaultMessage: "Stationary",
  },
  [DisinfectionModes.RemoteControlled]: {
    id: "enum.RemoteControlled",
    defaultMessage: "Remote controlled",
  },
  [DisinfectionModes.Autonomous]: {
    id: "enum.Autonomous",
    defaultMessage: "Predefined",
  },
  [DisinfectionStatus.Complete]: {
    id: "enum.Complete",
    defaultMessage: "Complete",
  },
  [DisinfectionStatus.InComplete]: {
    id: "enum.InComplete",
    defaultMessage: "Incomplete",
  },
  [RobotType.Tuv]: {
    id: "enum.TUV",
    defaultMessage: "Model C",
  },
  [RobotType.Eu]: {
    id: "enum.EU",
    defaultMessage: "Model C EU",
  },
  [RobotType.Maritime]: {
    id: "enum.Maritime",
    defaultMessage: "Model C Maritime",
  },
  [RobotType.Pharma]: {
    id: "enum.Pharma",
    defaultMessage: "Model C Pharma",
  },
  NOACCESS: {
    id: "noAccess",
    defaultMessage: "No access",
  },
});
