import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { CloudDialogFooterProps } from "../CloudDialogFooter";
import { CloudStepperCircle, StepperCircleProps } from "./CloudStepperCircle";

export type CloudSteps = Array<{
  label: string | ReactElement;
  description?: string | ReactElement | Array<string>;
  variant?: StepperCircleProps["variant"];
  hidden?: boolean;
  color?: StepperCircleProps["color"];
  component?: () => ReactElement;
  onSubmit?: CloudDialogFooterProps["onSubmit"];
  submitLabel?: CloudDialogFooterProps["submitLabel"];
  submitDisabled?: CloudDialogFooterProps["submitDisabled"];
  secondaryButtonLabel?: CloudDialogFooterProps["secondaryButtonLabel"];
  onSecondaryButtonClick?: CloudDialogFooterProps["onSecondaryButtonClick"];
  loading?: boolean;
}>;

export interface CloudStepperProps {
  steps: CloudSteps;
  style?: any;
  size?: StepperCircleProps["size"];
}

export const CloudStepper = ({ steps, style, size }: CloudStepperProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...style,
      }}
    >
      {steps.map((step) => {
        if (step.hidden) return null;

        return (
          <Box
            key={step.label.toString()}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CloudStepperCircle
                  size={size}
                  color={step.color}
                  variant={step.variant}
                  style={{ marginRight: 8 }}
                />
                <Typography>{step.label}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={(theme) => theme.palette.grey[600]}
                sx={{ maxWidth: "45%", textAlign: "right" }}
              >
                {step.description}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
