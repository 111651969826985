import { IoBusinessOutline } from "react-icons/io5";
import { getAvatarIconSize } from "utils/avatarUtils";
import { CloudAvatar } from "./CloudAvatar";
import { CloudAvatarProps } from "./UserAvatar";

interface OrganizationAvatarProps extends CloudAvatarProps {
  orgId?: string;
}

export const OrganizationAvatar = ({
  size = "medium",
  sx,
  orgId,
}: OrganizationAvatarProps) => {
  const iconSize = getAvatarIconSize(size);

  return (
    <CloudAvatar sx={sx} size={size} seed={orgId || "org"}>
      <IoBusinessOutline size={iconSize} />
    </CloudAvatar>
  );
};
