import { Box } from "@mui/material";
import { SelectorButton } from "components/atoms/buttons/SelectorButton";
import { ReactElement } from "react";

interface Props {
  elements: Array<{
    label: string | ReactElement;
    value: string;
    onClick: (value: string) => void;
  }>;
  selectedValue: string;
  style?: any;
}

export const CloudSelector = ({ elements, selectedValue, style }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        padding: "5px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "row",
        width: "fit-content",
        ...style,
      })}
    >
      {elements.map((element) => {
        return (
          <SelectorButton
            disabled={elements.length < 2}
            key={element.value}
            selected={selectedValue === element.value}
            onClick={() => element.onClick(element.value)}
            label={element.label}
          />
        );
      })}
    </Box>
  );
};
