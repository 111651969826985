import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { AssignRobotToOrganization } from "components/organisms/panels/AssignRobotToOrganization";
import { useState } from "react";

interface Props {
  refetch: () => void;
}

export const AssignRobotCell = ({
  refetch,
  ...params
}: GridRenderCellParams & Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Button
        onClick={(e: any) => {
          setDrawerOpen(true);
          e.stopPropagation();
        }}
        variant="contained"
        size="small"
      >
        Assign
      </Button>
      <AssignRobotToOrganization
        robot={params.row}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        refetch={refetch}
      />
    </>
  );
};
