import { Box, Skeleton } from "@mui/material";
import { LabelValueListItem } from "components/atoms/LabelValueListItem";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import { useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query RobotSoftware($robotId: String!) {
    robot(id: $robotId) {
      serialNumber
      ... on UvdRobot {
        version {
          language
          languageBuild
          seto
          setoBuild
          setoManager
          setoManagerBuild
        }
      }
    }
  }
`);

export const RobotSoftware = () => {
  const { robotId } = useParams();

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: { robotId: robotId ?? "" },
    skip: !robotId,
  });

  const robot = data?.robot as UvdRobot;

  const version = robot?.version;

  const isUndefined = !version;
  const hasSetoData = version?.seto && version?.setoBuild;
  const hasSetoManagerData = version?.setoManager && version.setoManagerBuild;
  const hasLanguageData = version?.language && version.languageBuild;

  if (loading) return <Skeleton height={100} />;

  return (
    <WidgetPaper>
      <WidgetHeader label={"Software"} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: "16px",
        }}
      >
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 0 }}
          label="UVD Robots App"
          value={isUndefined || !hasSetoData ? "-" : version?.seto || "-"}
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 0 }}
          label="UVD Robots App build"
          value={isUndefined || !hasSetoData ? "-" : version?.setoBuild || "-"}
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 0 }}
          label="System manager"
          value={
            isUndefined || !hasSetoManagerData
              ? "-"
              : version?.setoManager || "-"
          }
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 0 }}
          label="System manager build"
          value={
            isUndefined || !hasSetoManagerData
              ? "-"
              : version?.setoManagerBuild || "-"
          }
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 0 }}
          label="Language pack"
          value={
            isUndefined || !hasLanguageData ? "-" : version?.language || "-"
          }
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 0 }}
          label="Language pack build"
          value={
            isUndefined || !hasLanguageData
              ? "-"
              : version?.languageBuild || "-"
          }
        />
      </Box>
    </WidgetPaper>
  );
};
