import { useTheme } from "@mui/material";

interface Props {
  isOffline?: boolean;
}

export const UVDIcon = ({ isOffline = false }: Props) => {
  const theme = useTheme();
  const color = isOffline ? theme.palette.grey[500] : "#22A2D9";

  return (
    <svg viewBox="0 0 20 26" height={24}>
      <path
        d="M1.021 15.415H1.00194L1.021 18.8278V22.9934C1.021 22.9934 0.660449 25.0079 2.92702 24.991H16.6885C16.6885 24.991 18.9995 25.263 18.9995 22.8183V7.36945C18.9995 7.36945 19.179 0.838974 12.4428 1.01261H7.54277C7.54277 1.01261 0.949529 0.419486 1.02259 7.36945L1.021 15.415Z"
        fill="white"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M6.00098 25V21.8377C5.96249 20.3458 7.06706 20.1674 7.06706 20.1674C8.17832 20.3089 7.98753 19.3184 7.98753 19.3184V18"
        stroke={color}
        strokeMiterlimit="10"
        fill="#FFFFFF"
      />
      <path
        d="M7.9873 1V9"
        stroke={color}
        strokeMiterlimit="10"
        fill="#FFFFFF"
      />
      <path
        d="M13.999 25V21.8423C14.0377 20.3504 12.9285 20.172 12.9285 20.172C11.8211 20.3135 12.0127 19.3184 12.0127 19.3184V18"
        stroke={color}
        strokeMiterlimit="10"
        fill="#FFFFFF"
      />
      <path
        d="M12.0127 1V9"
        stroke={color}
        strokeMiterlimit="10"
        fill="#FFFFFF"
      />
      <path
        d="M10 8.5C7.23858 8.5 5 10.7386 5 13.5C5 16.2614 7.23858 18.5 10 18.5C12.7614 18.5 15 16.2614 15 13.5C15 10.7386 12.7614 8.5 10 8.5Z"
        stroke={color}
        strokeMiterlimit="10"
        fill="#FFFFFF"
      />
    </svg>
  );
};
