import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { BatteryChargingIcon } from "./BatteryChargingIcon";
import { BatteryIcon } from "./BatteryIcon";
import { BatteryPluggedInIcon } from "./BatteryPluggedInIcon";

interface Props {
  batteryPercent: number;
  charging: boolean;
  online?: boolean;
  isOffline?: boolean;
  pluggedIn?: boolean;
  iconSize?: number;
}

export const Battery = ({
  batteryPercent,
  charging,
  pluggedIn,
  isOffline,
  iconSize,
}: Props) => {
  const theme = useTheme();
  const batteryPercentage = charging ? 19 : (batteryPercent / 100) * 19;

  const batteryColor = useMemo(() => {
    if (isOffline) {
      return theme.palette.battery.grey;
    }
    if (batteryPercent <= 25) {
      if (batteryPercent <= 10) {
        return theme.palette.battery.red;
      } else {
        return theme.palette.battery.yellow;
      }
    } else {
      return theme.palette.battery.green;
    }
  }, [batteryPercent, isOffline]);

  if (charging) {
    return (
      <BatteryChargingIcon
        size={iconSize}
        color={batteryColor}
        isOffline={isOffline}
      />
    );
  }

  if (pluggedIn) {
    return (
      <BatteryPluggedInIcon
        size={iconSize}
        color={batteryColor}
        isOffline={isOffline}
      />
    );
  }

  return (
    <BatteryIcon
      size={iconSize}
      color={batteryColor}
      value={batteryPercentage}
    />
  );
};
