import BORFavicon from "../assets/favicons/bor-favicon.svg";
import BORLogo from "../assets/logos/bor-logo.png";
import { FirebaseConfig } from "./configTypes";

export const defaultConfig: FirebaseConfig = {
  theme: {}, //todo
  defaultRouteKey: "profile",
  logo: {
    borLogo: BORLogo,
    defaultLogo: BORLogo,
  },
  loginImage: "",
  favicon: BORFavicon,
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI as string,
  wsUri: process.env.REACT_APP_GRAPHQL_WS_URI as string,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT as string,
    storageBucket: process.env.REACT_APP_FIREBASE_BUCKET as string,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID as string,
    appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
  },
};
