import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ServiceNeededIcon } from "assets/icons/ServiceNeededIcon";
import { getShowTechnicalServiceNeeded } from "utils/UVD/UVDUtils";

export const TechnicalServiceNeededCell = (params: GridRenderCellParams) => {
  const { showWarning } = getShowTechnicalServiceNeeded(params.row);

  if (showWarning || !params.row.status) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ServiceNeededIcon />
        <Typography ml={1}>Service needed</Typography>
      </Box>
    );
  } else {
    return null;
  }
};
