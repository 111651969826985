import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DataLabelAndValueListItem } from "components/atoms/DataLabelAndValueListItem";
import { WidgetWrapper } from "components/atoms/WidgetWrapper";
import { format } from "date-fns";
import { RobotType } from "gql/graphql";
import { SelectedDisinfectionReport } from "types/types";
import { getUVDSerialNumber } from "utils/UVD/getUVDRobotSerialNumber";
import { getRoom, getUVDRobotImgSrc } from "utils/UVD/UVDUtils";

export const ReportBasicInfo = ({
  selectedReport,
}: {
  selectedReport: SelectedDisinfectionReport;
}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const style = largeScreen ? { width: "50%" } : { flex: 1 };

  return (
    <WidgetWrapper
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <img
        src={getUVDRobotImgSrc(
          selectedReport?.robot?.robot.robotType || RobotType.Tuv,
        )}
        style={{ height: "auto", width: 53, objectFit: "contain" }}
      />
      <Box gap={2} ml={6} display="flex" flexDirection="column" sx={style}>
        <DataLabelAndValueListItem
          label="Date"
          value={format(new Date(selectedReport?.start), "dd MMMM yyyy")}
        />
        <DataLabelAndValueListItem
          label="Started by"
          value={selectedReport?.startedBy || "-"}
        />
        <DataLabelAndValueListItem
          label="Room"
          value={getRoom(selectedReport?.room, selectedReport?.department)}
        />
        <DataLabelAndValueListItem
          label="Robot"
          value={
            selectedReport?.robot?.robot.serialNumber ||
            getUVDSerialNumber(selectedReport?.robotId || "")
          }
        />
      </Box>
    </WidgetWrapper>
  );
};
