import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { LinearProgressBar } from "components/atoms/LinearProgressBar";

export const MiniDisinfectionModes = ({
  row: { autonomous, remoteControlled, stationary },
}: GridRenderCellParams) => {
  const totalCount = autonomous + remoteControlled + stationary;

  return (
    <Box style={{ width: "100%" }}>
      <LinearProgressBar
        sx={{ height: "6px", marginBottom: "2px" }}
        value={(autonomous / totalCount) * 100}
      />
      <LinearProgressBar
        sx={{ height: "6px", marginBottom: "2px" }}
        value={(remoteControlled / totalCount) * 100}
      />
      <LinearProgressBar
        sx={{ height: "6px" }}
        value={(stationary / totalCount) * 100}
      />
    </Box>
  );
};
