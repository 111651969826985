import {
  Box,
  linearProgressClasses,
  Paper,
  Skeleton,
  Theme,
  Typography,
} from "@mui/material";
import { CloudProgressBar } from "components/atoms/CloudProgressBar";
import { LabelValueListItem } from "components/atoms/LabelValueListItem";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getShowTechnicalServiceNeeded } from "utils/UVD/UVDUtils";

const QUERY = graphql(`
  query RobotBatteries($robotId: String!) {
    robot(id: $robotId) {
      serialNumber
      ... on UvdRobot {
        status {
          batteries {
            health
            percentage
          }
        }
        version {
          batteries {
            id
            family
            serialNumber
            version
          }
        }
      }
    }
  }
`);

const MAX_BATTERY_HEALTH_LEVEL = 105;
const MIN_BATTERY_HEALTH_LEVEL = 50;
const HEALTH_DIFF = MAX_BATTERY_HEALTH_LEVEL - MIN_BATTERY_HEALTH_LEVEL;

const getBatteryHealthPercentage = (health: number) => {
  if (health > MAX_BATTERY_HEALTH_LEVEL) return 100;

  if (health < MIN_BATTERY_HEALTH_LEVEL) return 0;

  const healthDiff = health - MIN_BATTERY_HEALTH_LEVEL;
  return Math.floor((healthDiff / HEALTH_DIFF) * 100);
};

export const RobotBatteries = () => {
  const { robotId } = useParams();

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: { robotId: robotId ?? "" },
    skip: !robotId,
  });

  const robot = data?.robot as UvdRobot;

  const { showBatteryFamilyWarning, showBatteryVersionWarning } =
    getShowTechnicalServiceNeeded(robot);

  const batteriesVersion = robot?.version?.batteries;

  const serialNumbers = _.chain(batteriesVersion)
    .map((item) => item.serialNumber)
    .filter((item) => item !== "")
    .uniq()
    .value();

  const batteryFamilies = _.chain(batteriesVersion)
    .map((item) => item.family)
    .filter((item) => item !== "")
    .uniq()
    .value();

  const batteryVersions = _.chain(batteriesVersion)
    .map((item) => item.version)
    .filter((item) => item !== "")
    .uniq()
    .value();

  if (loading) return <Skeleton height={300} />;

  return (
    <WidgetPaper sx={{ padding: 4, paddingTop: 6 }}>
      <WidgetHeader label="Batteries" wrapperStyle={{ paddingLeft: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: "16px",
        }}
      >
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 2 }}
          labelColor={showBatteryFamilyWarning ? "auto" : undefined}
          label="Battery family"
          value={batteryFamilies.length > 0 ? batteryFamilies.join(", ") : "-"}
          showWarning={showBatteryFamilyWarning}
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 2 }}
          labelColor={showBatteryVersionWarning ? "auto" : undefined}
          label="Battery version"
          value={batteryVersions.length > 0 ? batteryVersions.join(", ") : "-"}
          showWarning={showBatteryVersionWarning}
        />
        <LabelValueListItem
          wrapperStyle={{ paddingLeft: 2 }}
          label="Battery serial numbers"
          value={serialNumbers.length > 0 ? serialNumbers.join(", ") : "-"}
        />
      </Box>
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.light,
          padding: 4,
          margin: 2,
          marginTop: 4,
        })}
      >
        <Typography variant="h5">Health</Typography>
        <Typography
          sx={{ fontSize: "10px", lineHeight: "12px" }}
          color={(theme) => theme.palette.grey[600]}
        >
          State of Health (SOH)
        </Typography>
        <Box gap={2} display="flex" mt={4} flexDirection="column">
          {(robot?.status?.batteries ?? _.fill(Array(4), null)).map(
            (battery, index) => {
              const isUndefined = battery === null;

              return (
                <CloudProgressBar
                  key={index}
                  progressBarSx={(theme: Theme) => ({
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: theme.palette.primary.main,
                    },
                  })}
                  label={"Battery " + (index + 1)}
                  valuePostFix={isUndefined ? "" : "%"}
                  labelValue={
                    isUndefined
                      ? ""
                      : getBatteryHealthPercentage(battery.health)
                  }
                  percentageValue={
                    isUndefined ? 0 : getBatteryHealthPercentage(battery.health)
                  }
                />
              );
            },
          )}
        </Box>
      </Paper>
    </WidgetPaper>
  );
};
