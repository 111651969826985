import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { GoogleIcon } from "assets/icons/GoogleIcon";
import { MicrosoftIcon } from "assets/icons/MicrosoftIcon";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudLabel } from "components/atoms/CloudLabel";
import { FormikTextField } from "components/atoms/formik";
import { CloudDialog, CloudDialogProps } from "components/modules/CloudDialog";
import { useFormik } from "formik";
import { graphql } from "gql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { USERAUTH_QUERY } from "../organizationWidgets/UserAuthentication";

const ADD_DOMAIN = graphql(`
  mutation AddDomain($hostname: String!, $orgId: String!) {
    createDomainVerification(
      hostname: $hostname
      targetOrganizationId: $orgId
    ) {
      id
      hostname
      verified
    }
  }
`);

type Values = {
  hostname: string;
};

export const AddDomain = ({ open, onClose }: CloudDialogProps) => {
  const intl = useIntl();
  const { orgId } = useParams();

  const [createDnsVerification] = useMutation(ADD_DOMAIN, {
    refetchQueries: [
      {
        query: USERAUTH_QUERY,
        variables: {
          orgId: orgId ?? "",
        },
      },
    ],
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<Values>({
    initialValues: {
      hostname: "",
    },
    validationSchema: Yup.object({
      hostname: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Adding domain...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      createDnsVerification({
        variables: {
          hostname: values.hostname,
          orgId: orgId ?? "",
        },
        onCompleted: () => {
          enqueueSnackbar(`Added domain ${values.hostname}`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not add domain ${values.hostname}`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [open]);

  return (
    <form>
      <CloudDialog
        header="Add Domain"
        open={open}
        onClose={handleClose}
        submitLabel="Add"
        hideCancel
        onSubmit={formik.handleSubmit}
        submitDisabled={formik.isValid === false}
      >
        <CloudDialogContent>
          <CloudLabel>Supported authentication methods</CloudLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
            <GoogleIcon />
            <Typography ml={2}>Google</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <MicrosoftIcon />
            <Typography ml={2}>Microsoft</Typography>
          </Box>
          <FormikTextField
            formik={formik}
            name="hostname"
            label="Domain"
            placeholder="Domain"
            helperText="Your organization's domain"
          />
        </CloudDialogContent>
      </CloudDialog>
    </form>
  );
};
