import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";

interface Props {
  value: number | string;
  backgroundColor?: string;
  color?: string;
  textColor?: string;
  size?: number;
}

export const SingleValueDonutChart = ({
  color,
  backgroundColor,
  value,
  textColor,
  size,
}: Props) => {
  const theme = useTheme();

  const isValueNumber = _.isNumber(value);
  const constrastNumber = isValueNumber ? 100 - value : 0;
  const borderRadius = isValueNumber && (value == 100 || value == 0) ? 0 : 30;

  const doughnutData = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [isValueNumber ? value : 0, constrastNumber],
        backgroundColor: [color, backgroundColor],
        borderWidth: [1, 0],
        borderColor: [color, backgroundColor],
      },
    ],
  };

  const backgroundCircle = {
    id: "backgroundCircle",
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
      const width = outerRadius - innerRadius;
      const angle = Math.PI / 180;

      ctx.beginPath();
      ctx.lineWidth = width;
      ctx.strokeStyle = backgroundColor;
      ctx.arc(xCoor, yCoor, outerRadius - width / 2, 0, angle * 360, false);
      ctx.stroke();
    },
  };

  const chartText = _.isNumber(value) ? value + "%" : value;

  const centerText = {
    id: "centerText",
    afterDatasetsDraw(chart: any) {
      const { ctx } = chart;
      ctx.save();

      ctx.font = "bold 24px " + theme.typography.fontFamily;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const text = chartText;
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;

      ctx.fillStyle = textColor ? textColor : theme.palette.grey[900];
      ctx.fillText(text, xCoor, yCoor);
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderRadius,
    cutout: "80%",
    events: [],
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
  };

  return (
    <Box height={size || "auto"} width={size || "auto"}>
      <Doughnut
        redraw={true}
        data={doughnutData}
        options={options}
        plugins={[backgroundCircle, centerText]}
      />
    </Box>
  );
};
