import { Drawer } from "@mui/material";

import { DrawerContent } from "components/atoms/drawer/DrawerContent";
import { DrawerFooter } from "components/atoms/drawer/DrawerFooter";
import { DrawerHeader } from "components/atoms/drawer/DrawerHeader";
import { DrawerWrapper } from "components/atoms/drawer/DrawerWrapper";
import { GET_IDENTITY_WITH_AFFILIATIONS } from "components/organisms/DashboardMenu";
import { graphql } from "gql";
import {
  DisinfectionFilter,
  DisinfectionFilterOptions,
  DisinfectionModes,
  FilterType,
  InterruptionReason,
} from "gql/graphql";
import _ from "lodash";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { hasSupportRole, isSupportPath } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import {
  dateToUTCdateToday,
  utcEndOfMonth,
  utcStartOfMonth,
} from "utils/utilFunctions";
import { getSelectedFilterList } from "utils/UVD/getSelectedFilterList";
import { getFilterComponent } from "./getFilterComponent";
import { SelectedFilterChips } from "./SelectedFilterChips";

interface Props {
  open: boolean;
  onClose: () => void;
  hiddenFilter?: DisinfectionFilter;
}

const QUERY = graphql(`
  query DisinfectionFilterDrawer($filter: DisinfectionFilter!) {
    disinfections(filter: $filter) {
      count
    }
    filterOptions {
      serialNumbers
      rooms
      departments
      operators
      disinfectionModes
      disinfectionStatus
    }
  }
`);

const SUPPORT_FILTEROPTIONS_QUERY = graphql(`
  query DisinfectionFilterDrawerSupportOptions {
    filterOptions {
      organizations {
        id
        name
      }
      organizationCountries
      robotTypes
      interruptionReasons
    }
  }
`);

type DisplayFilters = Array<keyof DisinfectionFilterOptions>;

const supportFilters: DisplayFilters = [
  "serialNumbers",
  "disinfectionStatus",
  "interruptionReasons",
  "organizations",
  "departments",
  "rooms",
  "operators",
  // "robotTypes",
  "disinfectionModes",
  "organizationCountries",
];

const defaultFilters: DisplayFilters = [
  "disinfectionStatus",
  "departments",
  "rooms",
  "operators",
  "serialNumbers",
  "disinfectionModes",
];

export function useDDFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const fromUrl = _.first(searchParams.getAll("filters"));
  const ddfilter = JSON.parse(fromUrl ?? "{}");

  const setDDFilter = (obj: any) => {
    if (_.isEmpty(obj)) {
      searchParams.delete("filters");
    } else {
      searchParams.set("filters", JSON.stringify(obj));
    }
    setSearchParams(searchParams);
  };

  return [ddfilter, setDDFilter];
}

export function useDDTimeSpan() {
  const [DDFilters, setDDFilter] = useDDFilter();

  const generatedStartDate = dateToUTCdateToday(new Date());

  const start = DDFilters?.timeSpan?.start || generatedStartDate.toUTCString();

  const timeSpan = DDFilters?.timeSpan;

  const setStart = (date: Date = new Date()) => {
    setDDFilter({
      ...DDFilters,
      timeSpan: {
        start: utcStartOfMonth(date),
        end: utcEndOfMonth(date),
      },
    });
  };

  return {
    start: new Date(start),
    setStart,
    timeSpan,
  };
}

export function useDDFilterParams() {
  const [DDFilters] = useDDFilter();

  const orgIds =
    DDFilters?.organizationIds?.length > 0
      ? {
          organizationIds: DDFilters?.organizationIds?.map(
            (item: any) => item.id,
          ),
        }
      : undefined;

  return {
    ...DDFilters,
    ...orgIds,
  };
}

export const DisinfectionFilterPanel = ({
  open,
  onClose,
  hiddenFilter,
}: Props) => {
  const location = useLocation();
  const [DDFilters, setDDFilter] = useDDFilter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setStart } = useDDTimeSpan();
  const filterParams = useDDFilterParams();

  const filterList = getSelectedFilterList(DDFilters);
  const filterListLabels = filterList.map((item: any) => item.label);

  useEffect(() => {
    if (!DDFilters.timeSpan) {
      setStart();
    }
  });

  const { data, loading, error } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
  });

  const { data: identityData } = useQueryWithSnack(
    GET_IDENTITY_WITH_AFFILIATIONS,
    {
      fetchPolicy: "cache-first",
    },
  );

  const isSupporter = hasSupportRole(identityData?.me);
  const isOnSupportPath = isSupportPath(location);

  const { data: supportFilterOptionsData } = useQueryWithSnack(
    SUPPORT_FILTEROPTIONS_QUERY,
    {
      skip: !isSupporter || !isOnSupportPath,
    },
  );

  const handleResetFilter = () => {
    const { timeSpan } = DDFilters;
    setDDFilter({ timeSpan });
  };

  const getDisinfectionModes = (
    prev: Array<DisinfectionModes>,
    selectedValue: DisinfectionModes,
  ) => {
    if (prev.includes(selectedValue)) {
      return prev?.filter((element) => element != selectedValue);
    } else {
      return prev.concat([selectedValue]);
    }
  };

  const handleChange = (value: any, id: string, checked?: boolean) => {
    const old = DDFilters;
    if (id === "disinfectionModes") {
      const newModes = getDisinfectionModes(old.disinfectionModes || [], value);

      if (checked == false) {
        const elementsLeft = DDFilters.disinfectionModes.filter(
          (item: DisinfectionModes) => item !== value,
        );
        if (elementsLeft.length === 0) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [id]: _, ...rest } = DDFilters;

          setDDFilter(rest);
        } else {
          setDDFilter({
            ...old,
            disinfectionModes: elementsLeft,
          });
        }
      } else {
        setDDFilter({ ...old, [id]: newModes });
      }
    } else if (id === "interruptionReasons") {
      if (value.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { interruption, ...rest } = DDFilters;
        setDDFilter(rest);
      } else {
        setDDFilter({
          ...old,
          interruption: {
            reasons: value as InterruptionReason[],
            type: FilterType.Inclusive,
          },
        });
      }
    } else {
      if (value.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: _, ...rest } = DDFilters;

        setDDFilter(rest);
      } else {
        setDDFilter({ ...old, [id]: value });
      }
    }
  };

  const removeSelectedFilter = (value: any, id: keyof DisinfectionFilter) => {
    const values: any = DDFilters[id];

    const newArr: any =
      values instanceof Array
        ? values.filter((item: any) => item !== value)
        : [];

    if (id === "interruption") {
      const reasons = DDFilters.interruption?.reasons.filter(
        (item: any) => item !== value,
      );

      if (reasons.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { interruption, ...rest } = DDFilters;
        return setDDFilter(rest);
      }

      return setDDFilter(
        (reasons || []).length > 0
          ? {
              ...DDFilters,
              interruption: {
                type: DDFilters.interruption?.type,
                reasons,
              } as any,
            }
          : DDFilters,
      );
    }

    if (id === "disinfectionStatus" && newArr.length === 0) {
      return setDDFilter({
        ...DDFilters,
        disinfectionStatus: undefined,
      });
    }

    if (newArr.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...rest } = DDFilters;
      return setDDFilter(rest);
    }

    return setDDFilter({
      ...DDFilters,
      ...{ [id]: newArr },
    });
  };

  return (
    <Drawer
      sx={{
        minWidth: 500,
      }}
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <DrawerWrapper>
        <DrawerHeader header="Filter" onClose={onClose} />
        <SelectedFilterChips
          filterList={filterList}
          removeSelectedFilter={removeSelectedFilter}
        />
        <DrawerContent
          hideFooter={filterList.length === 0}
          sx={{ paddingTop: 0 }}
        >
          {(isSupporter && isOnSupportPath
            ? supportFilters
            : defaultFilters
          ).map((item) =>
            getFilterComponent({
              id: item,
              filterListLabels,
              filterOptions: data?.filterOptions,
              supportFilterOptions: supportFilterOptionsData?.filterOptions,
              onChange: handleChange,
              DDFilters,
            }),
          )}
        </DrawerContent>

        {filterList.length === 0 ? null : (
          <DrawerFooter
            overrideWarning
            error={error ? "Filter query failed - try again." : undefined}
            action={{
              label: `Show ${data?.disinfections.count ?? 0} disinfections`,
              onClick: onClose,
              loading: loading,
            }}
            secondaryAction={{
              label: "Reset filter",
              onClick: handleResetFilter,
              color: "primary",
            }}
          />
        )}
      </DrawerWrapper>
    </Drawer>
  );
};
