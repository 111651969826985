import { CloudInlineLink } from "components/atoms/CloudInlineLink";
import { useConfig } from "config/configHook";
import { graphql } from "gql";
import { Environment } from "gql/graphql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

export const GET_SYSTEM_INFORMATION = graphql(`
  query GetSystemInformation {
    systemInformation {
      environment
      version
    }
  }
`);

export const getEnvironment = () => {
  const { data } = useQueryWithSnack(GET_SYSTEM_INFORMATION);

  const config = useConfig();

  let envCallName = "production";
  let oldSystemLink = (
    <CloudInlineLink to={config.oldSystemLinks?.production || ""}>
      FM 1.0-production
    </CloudInlineLink>
  );
  const environment = data?.systemInformation.environment;

  switch (environment) {
    case Environment.Staging:
      envCallName = "staging";
      oldSystemLink = (
        <CloudInlineLink to={config.oldSystemLinks?.staging || ""}>
          FM 1.0-staging
        </CloudInlineLink>
      );
      break;
    case Environment.Development:
      envCallName = "nightly";
      oldSystemLink = (
        <CloudInlineLink to={config.oldSystemLinks?.development || ""}>
          FM-1.0-nightly
        </CloudInlineLink>
      );
      break;
  }

  let spreadsheetUrl =
    "https://docs.google.com/spreadsheets/d/1vZ3fiiqcgLUqfjnxxA_0Ew1Y7W7tKEsVEqzVL6_aUVk/edit?pli=1#gid=1358927794";

  switch (environment) {
    case Environment.Staging:
    case Environment.Development:
      spreadsheetUrl =
        "https://docs.google.com/spreadsheets/d/12vlmT5itFvskVNLoHFHSDW4RulxQmSOmKzNxgZliMM8/edit#gid=1358927794";
  }

  return { environment, oldSystemLink, spreadsheetUrl, envCallName };
};
