export const countries = {
  AF: {
    defaultMessage: "Afghanistan",
    id: "AF",
  },
  AX: {
    defaultMessage: "Åland Islands",
    id: "AX",
  },
  AL: {
    defaultMessage: "Albania",
    id: "AL",
  },
  DZ: {
    defaultMessage: "Algeria",
    id: "DZ",
  },
  AS: {
    defaultMessage: "American Samoa",
    id: "AS",
  },
  AD: {
    defaultMessage: "Andorra",
    id: "AD",
  },
  AO: {
    defaultMessage: "Angola",
    id: "AO",
  },
  AI: {
    defaultMessage: "Anguilla",
    id: "AI",
  },
  AQ: {
    defaultMessage: "Antarctica",
    id: "AQ",
  },
  AG: {
    defaultMessage: "Antigua and Barbuda",
    id: "AG",
  },
  AR: {
    defaultMessage: "Argentina",
    id: "AR",
  },
  AM: {
    defaultMessage: "Armenia",
    id: "AM",
  },
  AW: {
    defaultMessage: "Aruba",
    id: "AW",
  },
  AU: {
    defaultMessage: "Australia",
    id: "AU",
  },
  AT: {
    defaultMessage: "Austria",
    id: "AT",
  },
  AZ: {
    defaultMessage: "Azerbaijan",
    id: "AZ",
  },
  BS: {
    defaultMessage: "Bahamas",
    id: "BS",
  },
  BH: {
    defaultMessage: "Bahrain",
    id: "BH",
  },
  BD: {
    defaultMessage: "Bangladesh",
    id: "BD",
  },
  BB: {
    defaultMessage: "Barbados",
    id: "BB",
  },
  BY: {
    defaultMessage: "Belarus",
    id: "BY",
  },
  BE: {
    defaultMessage: "Belgium",
    id: "BE",
  },
  BZ: {
    defaultMessage: "Belize",
    id: "BZ",
  },
  BJ: {
    defaultMessage: "Benin",
    id: "BJ",
  },
  BM: {
    defaultMessage: "Bermuda",
    id: "BM",
  },
  BT: {
    defaultMessage: "Bhutan",
    id: "BT",
  },
  BO: {
    defaultMessage: "Bolivia (Plurinational State of)",
    id: "BO",
  },
  BQ: {
    defaultMessage: "Bonaire, Sint Eustatius and Saba",
    id: "BQ",
  },
  BA: {
    defaultMessage: "Bosnia and Herzegovina",
    id: "BA",
  },
  BW: {
    defaultMessage: "Botswana",
    id: "BW",
  },
  BV: {
    defaultMessage: "Bouvet Island",
    id: "BV",
  },
  BR: {
    defaultMessage: "Brazil",
    id: "BR",
  },
  IO: {
    defaultMessage: "British Indian Ocean Territory",
    id: "IO",
  },
  BN: {
    defaultMessage: "Brunei Darussalam",
    id: "BN",
  },
  BG: {
    defaultMessage: "Bulgaria",
    id: "BG",
  },
  BF: {
    defaultMessage: "Burkina Faso",
    id: "BF",
  },
  BI: {
    defaultMessage: "Burundi",
    id: "BI",
  },
  CV: {
    defaultMessage: "Cabo Verde",
    id: "CV",
  },
  KH: {
    defaultMessage: "Cambodia",
    id: "KH",
  },
  CM: {
    defaultMessage: "Cameroon",
    id: "CM",
  },
  CA: {
    defaultMessage: "Canada",
    id: "CA",
  },
  KY: {
    defaultMessage: "Cayman Islands",
    id: "KY",
  },
  CF: {
    defaultMessage: "Central African Republic",
    id: "CF",
  },
  TD: {
    defaultMessage: "Chad",
    id: "TD",
  },
  CL: {
    defaultMessage: "Chile",
    id: "CL",
  },
  CN: {
    defaultMessage: "China",
    id: "CN",
  },
  CX: {
    defaultMessage: "Christmas Island",
    id: "CX",
  },
  CC: {
    defaultMessage: "Cocos (Keeling) Islands",
    id: "CC",
  },
  CO: {
    defaultMessage: "Colombia",
    id: "CO",
  },
  KM: {
    defaultMessage: "Comoros",
    id: "KM",
  },
  CG: {
    defaultMessage: "Congo",
    id: "CG",
  },
  CD: {
    defaultMessage: "Congo, Democratic Republic of the",
    id: "CD",
  },
  CK: {
    defaultMessage: "Cook Islands",
    id: "CK",
  },
  CR: {
    defaultMessage: "Costa Rica",
    id: "CR",
  },
  CI: {
    defaultMessage: "Côte d'Ivoire",
    id: "CI",
  },
  HR: {
    defaultMessage: "Croatia",
    id: "HR",
  },
  CU: {
    defaultMessage: "Cuba",
    id: "CU",
  },
  CW: {
    defaultMessage: "Curaçao",
    id: "CW",
  },
  CY: {
    defaultMessage: "Cyprus",
    id: "CY",
  },
  CZ: {
    defaultMessage: "Czechia",
    id: "CZ",
  },
  DK: {
    defaultMessage: "Denmark",
    id: "DK",
  },
  DJ: {
    defaultMessage: "Djibouti",
    id: "DJ",
  },
  DM: {
    defaultMessage: "Dominica",
    id: "DM",
  },
  DO: {
    defaultMessage: "Dominican Republic",
    id: "DO",
  },
  EC: {
    defaultMessage: "Ecuador",
    id: "EC",
  },
  EG: {
    defaultMessage: "Egypt",
    id: "EG",
  },
  SV: {
    defaultMessage: "El Salvador",
    id: "SV",
  },
  GQ: {
    defaultMessage: "Equatorial Guinea",
    id: "GQ",
  },
  ER: {
    defaultMessage: "Eritrea",
    id: "ER",
  },
  EE: {
    defaultMessage: "Estonia",
    id: "EE",
  },
  SZ: {
    defaultMessage: "Eswatini",
    id: "SZ",
  },
  ET: {
    defaultMessage: "Ethiopia",
    id: "ET",
  },
  FK: {
    defaultMessage: "Falkland Islands (Malvinas)",
    id: "FK",
  },
  FO: {
    defaultMessage: "Faroe Islands",
    id: "FO",
  },
  FJ: {
    defaultMessage: "Fiji",
    id: "FJ",
  },
  FI: {
    defaultMessage: "Finland",
    id: "FI",
  },
  FR: {
    defaultMessage: "France",
    id: "FR",
  },
  GF: {
    defaultMessage: "French Guiana",
    id: "GF",
  },
  PF: {
    defaultMessage: "French Polynesia",
    id: "PF",
  },
  TF: {
    defaultMessage: "French Southern Territories",
    id: "TF",
  },
  GA: {
    defaultMessage: "Gabon",
    id: "GA",
  },
  GM: {
    defaultMessage: "Gambia",
    id: "GM",
  },
  GE: {
    defaultMessage: "Georgia",
    id: "GE",
  },
  DE: {
    defaultMessage: "Germany",
    id: "DE",
  },
  GH: {
    defaultMessage: "Ghana",
    id: "GH",
  },
  GI: {
    defaultMessage: "Gibraltar",
    id: "GI",
  },
  GR: {
    defaultMessage: "Greece",
    id: "GR",
  },
  GL: {
    defaultMessage: "Greenland",
    id: "GL",
  },
  GD: {
    defaultMessage: "Grenada",
    id: "GD",
  },
  GP: {
    defaultMessage: "Guadeloupe",
    id: "GP",
  },
  GU: {
    defaultMessage: "Guam",
    id: "GU",
  },
  GT: {
    defaultMessage: "Guatemala",
    id: "GT",
  },
  GG: {
    defaultMessage: "Guernsey",
    id: "GG",
  },
  GN: {
    defaultMessage: "Guinea",
    id: "GN",
  },
  GW: {
    defaultMessage: "Guinea-Bissau",
    id: "GW",
  },
  GY: {
    defaultMessage: "Guyana",
    id: "GY",
  },
  HT: {
    defaultMessage: "Haiti",
    id: "HT",
  },
  HM: {
    defaultMessage: "Heard Island and McDonald Islands",
    id: "HM",
  },
  VA: {
    defaultMessage: "Holy See",
    id: "VA",
  },
  HN: {
    defaultMessage: "Honduras",
    id: "HN",
  },
  HK: {
    defaultMessage: "Hong Kong",
    id: "HK",
  },
  HU: {
    defaultMessage: "Hungary",
    id: "HU",
  },
  IS: {
    defaultMessage: "Iceland",
    id: "IS",
  },
  IN: {
    defaultMessage: "India",
    id: "IN",
  },
  ID: {
    defaultMessage: "Indonesia",
    id: "ID",
  },
  IR: {
    defaultMessage: "Iran (Islamic Republic of)",
    id: "IR",
  },
  IQ: {
    defaultMessage: "Iraq",
    id: "IQ",
  },
  IE: {
    defaultMessage: "Ireland",
    id: "IE",
  },
  IM: {
    defaultMessage: "Isle of Man",
    id: "IM",
  },
  IL: {
    defaultMessage: "Israel",
    id: "IL",
  },
  IT: {
    defaultMessage: "Italy",
    id: "IT",
  },
  JM: {
    defaultMessage: "Jamaica",
    id: "JM",
  },
  JP: {
    defaultMessage: "Japan",
    id: "JP",
  },
  JE: {
    defaultMessage: "Jersey",
    id: "JE",
  },
  JO: {
    defaultMessage: "Jordan",
    id: "JO",
  },
  KZ: {
    defaultMessage: "Kazakhstan",
    id: "KZ",
  },
  KE: {
    defaultMessage: "Kenya",
    id: "KE",
  },
  KI: {
    defaultMessage: "Kiribati",
    id: "KI",
  },
  KP: {
    defaultMessage: "Korea (Democratic People's Republic of)",
    id: "KP",
  },
  KR: {
    defaultMessage: "Korea, Republic of",
    id: "KR",
  },
  KW: {
    defaultMessage: "Kuwait",
    id: "KW",
  },
  KG: {
    defaultMessage: "Kyrgyzstan",
    id: "KG",
  },
  LA: {
    defaultMessage: "Lao People's Democratic Republic",
    id: "LA",
  },
  LV: {
    defaultMessage: "Latvia",
    id: "LV",
  },
  LB: {
    defaultMessage: "Lebanon",
    id: "LB",
  },
  LS: {
    defaultMessage: "Lesotho",
    id: "LS",
  },
  LR: {
    defaultMessage: "Liberia",
    id: "LR",
  },
  LY: {
    defaultMessage: "Libya",
    id: "LY",
  },
  LI: {
    defaultMessage: "Liechtenstein",
    id: "LI",
  },
  LT: {
    defaultMessage: "Lithuania",
    id: "LT",
  },
  LU: {
    defaultMessage: "Luxembourg",
    id: "LU",
  },
  MO: {
    defaultMessage: "Macao",
    id: "MO",
  },
  MG: {
    defaultMessage: "Madagascar",
    id: "MG",
  },
  MW: {
    defaultMessage: "Malawi",
    id: "MW",
  },
  MY: {
    defaultMessage: "Malaysia",
    id: "MY",
  },
  MV: {
    defaultMessage: "Maldives",
    id: "MV",
  },
  ML: {
    defaultMessage: "Mali",
    id: "ML",
  },
  MT: {
    defaultMessage: "Malta",
    id: "MT",
  },
  MH: {
    defaultMessage: "Marshall Islands",
    id: "MH",
  },
  MQ: {
    defaultMessage: "Martinique",
    id: "MQ",
  },
  MR: {
    defaultMessage: "Mauritania",
    id: "MR",
  },
  MU: {
    defaultMessage: "Mauritius",
    id: "MU",
  },
  YT: {
    defaultMessage: "Mayotte",
    id: "YT",
  },
  MX: {
    defaultMessage: "Mexico",
    id: "MX",
  },
  FM: {
    defaultMessage: "Micronesia (Federated States of)",
    id: "FM",
  },
  MD: {
    defaultMessage: "Moldova, Republic of",
    id: "MD",
  },
  MC: {
    defaultMessage: "Monaco",
    id: "MC",
  },
  MN: {
    defaultMessage: "Mongolia",
    id: "MN",
  },
  ME: {
    defaultMessage: "Montenegro",
    id: "ME",
  },
  MS: {
    defaultMessage: "Montserrat",
    id: "MS",
  },
  MA: {
    defaultMessage: "Morocco",
    id: "MA",
  },
  MZ: {
    defaultMessage: "Mozambique",
    id: "MZ",
  },
  MM: {
    defaultMessage: "Myanmar",
    id: "MM",
  },
  NA: {
    defaultMessage: "Namibia",
    id: "NA",
  },
  NR: {
    defaultMessage: "Nauru",
    id: "NR",
  },
  NP: {
    defaultMessage: "Nepal",
    id: "NP",
  },
  NL: {
    defaultMessage: "Netherlands",
    id: "NL",
  },
  NC: {
    defaultMessage: "New Caledonia",
    id: "NC",
  },
  NZ: {
    defaultMessage: "New Zealand",
    id: "NZ",
  },
  NI: {
    defaultMessage: "Nicaragua",
    id: "NI",
  },
  NE: {
    defaultMessage: "Niger",
    id: "NE",
  },
  NG: {
    defaultMessage: "Nigeria",
    id: "NG",
  },
  NU: {
    defaultMessage: "Niue",
    id: "NU",
  },
  NF: {
    defaultMessage: "Norfolk Island",
    id: "NF",
  },
  MK: {
    defaultMessage: "North Macedonia",
    id: "MK",
  },
  MP: {
    defaultMessage: "Northern Mariana Islands",
    id: "MP",
  },
  NO: {
    defaultMessage: "Norway",
    id: "NO",
  },
  OM: {
    defaultMessage: "Oman",
    id: "OM",
  },
  PK: {
    defaultMessage: "Pakistan",
    id: "PK",
  },
  PW: {
    defaultMessage: "Palau",
    id: "PW",
  },
  PS: {
    defaultMessage: "Palestine, State of",
    id: "PS",
  },
  PA: {
    defaultMessage: "Panama",
    id: "PA",
  },
  PG: {
    defaultMessage: "Papua New Guinea",
    id: "PG",
  },
  PY: {
    defaultMessage: "Paraguay",
    id: "PY",
  },
  PE: {
    defaultMessage: "Peru",
    id: "PE",
  },
  PH: {
    defaultMessage: "Philippines",
    id: "PH",
  },
  PN: {
    defaultMessage: "Pitcairn",
    id: "PN",
  },
  PL: {
    defaultMessage: "Poland",
    id: "PL",
  },
  PT: {
    defaultMessage: "Portugal",
    id: "PT",
  },
  PR: {
    defaultMessage: "Puerto Rico",
    id: "PR",
  },
  QA: {
    defaultMessage: "Qatar",
    id: "QA",
  },
  RE: {
    defaultMessage: "Réunion",
    id: "RE",
  },
  RO: {
    defaultMessage: "Romania",
    id: "RO",
  },
  RU: {
    defaultMessage: "Russian Federation",
    id: "RU",
  },
  RW: {
    defaultMessage: "Rwanda",
    id: "RW",
  },
  BL: {
    defaultMessage: "Saint Barthélemy",
    id: "BL",
  },
  SH: {
    defaultMessage: "Saint Helena, Ascension and Tristan da Cunha",
    id: "SH",
  },
  KN: {
    defaultMessage: "Saint Kitts and Nevis",
    id: "KN",
  },
  LC: {
    defaultMessage: "Saint Lucia",
    id: "LC",
  },
  MF: {
    defaultMessage: "Saint Martin (French part)",
    id: "MF",
  },
  PM: {
    defaultMessage: "Saint Pierre and Miquelon",
    id: "PM",
  },
  VC: {
    defaultMessage: "Saint Vincent and the Grenadines",
    id: "VC",
  },
  WS: {
    defaultMessage: "Samoa",
    id: "WS",
  },
  SM: {
    defaultMessage: "San Marino",
    id: "SM",
  },
  ST: {
    defaultMessage: "Sao Tome and Principe",
    id: "ST",
  },
  SA: {
    defaultMessage: "Saudi Arabia",
    id: "SA",
  },
  SN: {
    defaultMessage: "Senegal",
    id: "SN",
  },
  RS: {
    defaultMessage: "Serbia",
    id: "RS",
  },
  SC: {
    defaultMessage: "Seychelles",
    id: "SC",
  },
  SL: {
    defaultMessage: "Sierra Leone",
    id: "SL",
  },
  SG: {
    defaultMessage: "Singapore",
    id: "SG",
  },
  SX: {
    defaultMessage: "Sint Maarten (Dutch part)",
    id: "SX",
  },
  SK: {
    defaultMessage: "Slovakia",
    id: "SK",
  },
  SI: {
    defaultMessage: "Slovenia",
    id: "SI",
  },
  SB: {
    defaultMessage: "Solomon Islands",
    id: "SB",
  },
  SO: {
    defaultMessage: "Somalia",
    id: "SO",
  },
  ZA: {
    defaultMessage: "South Africa",
    id: "ZA",
  },
  GS: {
    defaultMessage: "South Georgia and the South Sandwich Islands",
    id: "GS",
  },
  SS: {
    defaultMessage: "South Sudan",
    id: "SS",
  },
  ES: {
    defaultMessage: "Spain",
    id: "ES",
  },
  LK: {
    defaultMessage: "Sri Lanka",
    id: "LK",
  },
  SD: {
    defaultMessage: "Sudan",
    id: "SD",
  },
  SR: {
    defaultMessage: "SuridefaultMessage",
    id: "SR",
  },
  SJ: {
    defaultMessage: "Svalbard and Jan Mayen",
    id: "SJ",
  },
  SE: {
    defaultMessage: "Sweden",
    id: "SE",
  },
  CH: {
    defaultMessage: "Switzerland",
    id: "CH",
  },
  SY: {
    defaultMessage: "Syrian Arab Republic",
    id: "SY",
  },
  TW: {
    defaultMessage: "Taiwan, Province of China",
    id: "TW",
  },
  TJ: {
    defaultMessage: "Tajikistan",
    id: "TJ",
  },
  TZ: {
    defaultMessage: "Tanzania, United Republic of",
    id: "TZ",
  },
  TH: {
    defaultMessage: "Thailand",
    id: "TH",
  },
  TL: {
    defaultMessage: "Timor-Leste",
    id: "TL",
  },
  TG: {
    defaultMessage: "Togo",
    id: "TG",
  },
  TK: {
    defaultMessage: "Tokelau",
    id: "TK",
  },
  TO: {
    defaultMessage: "Tonga",
    id: "TO",
  },
  TT: {
    defaultMessage: "Trinidad and Tobago",
    id: "TT",
  },
  TN: {
    defaultMessage: "Tunisia",
    id: "TN",
  },
  TR: {
    defaultMessage: "Turkey",
    id: "TR",
  },
  TM: {
    defaultMessage: "Turkmenistan",
    id: "TM",
  },
  TC: {
    defaultMessage: "Turks and Caicos Islands",
    id: "TC",
  },
  TV: {
    defaultMessage: "Tuvalu",
    id: "TV",
  },
  UG: {
    defaultMessage: "Uganda",
    id: "UG",
  },
  UA: {
    defaultMessage: "Ukraine",
    id: "UA",
  },
  AE: {
    defaultMessage: "United Arab Emirates",
    id: "AE",
  },
  GB: {
    defaultMessage: "United Kingdom of Great Britain and Northern Ireland",
    id: "GB",
  },
  US: {
    defaultMessage: "United States of America",
    id: "US",
  },
  UM: {
    defaultMessage: "United States Minor Outlying Islands",
    id: "UM",
  },
  UY: {
    defaultMessage: "Uruguay",
    id: "UY",
  },
  UZ: {
    defaultMessage: "Uzbekistan",
    id: "UZ",
  },
  VU: {
    defaultMessage: "Vanuatu",
    id: "VU",
  },
  VE: {
    defaultMessage: "Venezuela (Bolivarian Republic of)",
    id: "VE",
  },
  VN: {
    defaultMessage: "Viet Nam",
    id: "VN",
  },
  VG: {
    defaultMessage: "Virgin Islands (British)",
    id: "VG",
  },
  VI: {
    defaultMessage: "Virgin Islands (U.S.)",
    id: "VI",
  },
  WF: {
    defaultMessage: "Wallis and Futuna",
    id: "WF",
  },
  EH: {
    defaultMessage: "Western Sahara",
    id: "EH",
  },
  YE: {
    defaultMessage: "Yemen",
    id: "YE",
  },
  ZM: {
    defaultMessage: "Zambia",
    id: "ZM",
  },
  ZW: {
    defaultMessage: "Zimbabwe",
    id: "ZW",
  },
};
