import { GridRenderCellParams } from "@mui/x-data-grid";
import { Pill } from "components/atoms/Pill";
import { ReactElement, useMemo } from "react";
import { ChipColor } from "types/types";
import {
  getOrganizationOwnerInfo,
  getOrganizationStatus,
} from "utils/privileges/privilegeUtils";

export const OrganizationStatusCell = (params: GridRenderCellParams) => {
  const status: {
    color: ChipColor;
    label: string | ReactElement;
  } = useMemo(() => {
    const ownerInfo = getOrganizationOwnerInfo(params.row?.affiliations);
    const orgStatus = getOrganizationStatus(
      params.row.activationState,
      ownerInfo.hasOwner,
    );
    switch (orgStatus) {
      case "ACTIVE":
        return { color: "success", label: "ACTIVE" };
      case "MISSING_OWNER":
        return { color: "error", label: "Missing owner" };
      case "PENDING":
      default:
        return { color: "warning", label: "PENDING" };
    }
  }, [params.row]);

  return <Pill label={status.label} color={status.color} size="small" />;
};
