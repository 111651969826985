import { Typography } from "@mui/material";
import { ReactElement } from "react";

export const LoginHeader = ({
  children,
}: {
  children: ReactElement | string | string[];
}) => {
  return (
    <Typography variant="h4" width="fit-content" alignSelf="center">
      {children}
    </Typography>
  );
};
