import { InlineCard } from "components/atoms/InlineCard";
import { Identity } from "gql/graphql";
import { UserCardContent, UserCardContentProps } from "./UserCardContent";

interface UserCardProps extends UserCardContentProps {
  identity: Partial<Identity>;
  onClick: () => void;
}

export const UserCard = ({ onClick, identity, ...props }: UserCardProps) => {
  return (
    <InlineCard onClick={onClick}>
      <UserCardContent
        identity={identity}
        wrapperStyle={{ padding: 4 }}
        {...props}
      />
    </InlineCard>
  );
};
