import { Box } from "@mui/material";
import { FormikTextField } from "components/atoms/formik";
import { useIntl } from "react-intl";

interface Props {
  formik: any;
  disableForm?: boolean;
}

export const OrgSupportLinks = ({ formik, disableForm }: Props) => {
  const intl = useIntl();

  return (
    <Box>
      <FormikTextField
        name="odooLink"
        disabled={disableForm}
        formik={formik}
        label={intl.formatMessage({
          id: "organization.odooLink",
          defaultMessage: "Link to Odoo",
        })}
        placeholder={intl.formatMessage({
          id: "link.odoo",
          defaultMessage: "Odoo URL",
        })}
      />
      <FormikTextField
        name="hubspotLink"
        disabled={disableForm}
        formik={formik}
        label={intl.formatMessage({
          id: "organization.hubspotLink",
          defaultMessage: "Link to Hubspot",
        })}
        placeholder={intl.formatMessage({
          id: "link.hubspot",
          defaultMessage: "HubSpot URL",
        })}
      />
      <FormikTextField
        name="confluenceLink"
        disabled={disableForm}
        formik={formik}
        label={intl.formatMessage({
          id: "organization.confluenceLink",
          defaultMessage: "Link to CS Customer page",
        })}
        placeholder={intl.formatMessage({
          id: "link.confluence",
          defaultMessage: "CS Customer page URL",
        })}
      />
    </Box>
  );
};
