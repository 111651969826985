import { gql } from "@apollo/client";
import { OrganizationAvatar } from "components/atoms/avatars/OrganizationAvatar";
import { useIntl } from "react-intl";
import { countryMsg } from "trans/definedMessages/countryMessages";
import { CloudCardContent, CloudCardContentProps } from "./CloudCardContent";

const ORGCARDCONTENT_FRAGMENT = gql`
  fragment OrganizationCardContent on Organization {
    id
    name
    country
  }
`;

export interface OrganizationCardContentProps extends CloudCardContentProps {
  organization: any;
}

export const OrganizationCardContent = ({
  organization,
  hideDescription,
  size,
  ...props
}: OrganizationCardContentProps) => {
  const intl = useIntl();

  const description = hideDescription
    ? undefined
    : props.description
      ? props.description
      : organization?.country
        ? intl.formatMessage(countryMsg[organization.country || ""])
        : "";

  return (
    <CloudCardContent
      size={size}
      avatar={
        <OrganizationAvatar
          size={size}
          orgId={organization?.id}
          sx={{ marginRight: 2 }}
        />
      }
      wrapperStyle={{ padding: "8px 0px" }}
      hideIcon={props.hideIcon || false}
      header={organization?.name}
      description={description}
      {...props}
    />
  );
};

OrganizationCardContent.fragments = {
  organization: ORGCARDCONTENT_FRAGMENT,
};
