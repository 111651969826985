import { Box } from "@mui/material";
import { CountryAutocomplete } from "components/atoms/autoComplete/CountryAutocomplete";
import { CloudLabel } from "components/atoms/CloudLabel";
import { FormikTextField } from "components/atoms/formik";
import { FormikAutocomplete } from "components/atoms/formik/FormikAutocomplete";
import { useIntl } from "react-intl";

interface Props {
  formik: any;
  disableForm?: boolean;
  onChange?: () => void;
}

export const OrgNameAndCountry = ({ formik, disableForm, onChange }: Props) => {
  const intl = useIntl();

  return (
    <Box>
      <FormikTextField
        disabled={disableForm}
        name="orgName"
        formik={formik}
        label={intl.formatMessage({
          id: "create.organization.name",
          defaultMessage: "Organization name (required)",
        })}
        placeholder={intl.formatMessage({
          id: "organization.name",
          defaultMessage: "Organization name",
        })}
        onChange={onChange}
      />
      <CloudLabel>Country</CloudLabel>
      <FormikAutocomplete
        name="country"
        disabled={disableForm}
        formik={formik}
        multiple={false}
        component={CountryAutocomplete}
        placeholder="Select country"
        helperText="To better identify this particular organization"
        onChange={onChange}
      />
    </Box>
  );
};
