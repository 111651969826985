import { Paper, PaperProps, styled } from "@mui/material";

export interface InlineCardProps extends PaperProps {
  empty?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const InlineCard = styled(Paper, {
  shouldForwardProp: (prop) =>
    prop !== "empty" && prop !== "fullWidth" && prop !== "disabled",
})<InlineCardProps>(({ theme, empty, fullWidth = true, disabled }) => ({
  cursor: empty || disabled ? "auto" : "pointer",
  pointerEvents: disabled ? "none" : "auto",
  display: "flex",
  boxShadow:
    empty || disabled
      ? "none"
      : "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)",
  border: empty
    ? `1px dashed ${theme.palette.grey[300]}`
    : disabled
      ? `1px solid ${theme.palette.grey[300]}`
      : "none",
  flexGrow: fullWidth ? 1 : "0",
  ":hover": {
    boxShadow:
      empty || disabled ? "none" : "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
    transition: "none",
  },
}));
