import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { CloudProgressBar } from "components/atoms/CloudProgressBar";
import { DisinfectionInterruption } from "gql/graphql";
import _ from "lodash";
import { MessageDescriptor, useIntl } from "react-intl";
import { interruptionMsg } from "trans/definedMessages/interruptionMessages";
import { capitalizeWithPascalCase } from "utils/utilFunctions";
import { getInterruptionArr } from "utils/UVD/UVDUtils";

interface Props {
  interruptions?: DisinfectionInterruption;
}

export const InterruptionsTab = ({ interruptions }: Props) => {
  const intl = useIntl();

  const { activeInterruptions } = getInterruptionArr(interruptions);
  const maxCount = interruptions ? _.max(Object.values(interruptions)) : 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "-16px",
        justifyContent: "flex-start",
      }}
    >
      {activeInterruptions?.map((item) => {
        const percentageValue =
          ((item.value || 0) / ((maxCount as number) || 0)) * 100;

        const label = capitalizeWithPascalCase(item.label);

        return (
          <ProgressBarChartWrapper key={item.label}>
            <CloudProgressBar
              label={intl.formatMessage(
                interruptionMsg[label as any] as MessageDescriptor,
              )}
              labelValue={item.value || ""}
              percentageValue={percentageValue}
              color="primary"
            />
          </ProgressBarChartWrapper>
        );
      })}
    </Box>
  );
};

const ProgressBarChartWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: theme.spacing(4),
}));
