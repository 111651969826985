import { Box, Typography } from "@mui/material";
import { graphql } from "gql";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query RobotDisinfectionCountCell(
    $robotSerialNumber: String!
    $orgIds: [String!]
  ) {
    disinfections(
      filter: { serialNumbers: [$robotSerialNumber], organizationIds: $orgIds }
    ) {
      count
    }
  }
`);

export const RobotDisinfectionCountCell = ({
  robot,
  hideDescription,
  orgId,
}: any) => {
  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      robotSerialNumber: robot.serialNumber,
      orgIds: orgId ? [orgId] : undefined,
    },
  });

  return (
    <Box>
      <Typography>{data?.disinfections.count} Disinfection cycles</Typography>
      {hideDescription ? null : (
        <Typography
          fontSize="10px"
          variant="bodyS"
          color={(theme) => theme.palette.grey[600]}
        >
          Historic data
        </Typography>
      )}
    </Box>
  );
};
