import { styled } from "@mui/material";
import { PulseIcon } from "./PulseIcon";

const Wrapper = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
}));

interface Props {
  showPulse?: boolean;
  isOffline?: boolean;
}

export const UVDPulseIcon = ({ showPulse = false }: Props) => {
  return <Wrapper>{showPulse ? <PulseIcon /> : null}</Wrapper>;
};
