import { Box, Typography } from "@mui/material";
import { WarningYellowIcon } from "assets/icons/WarningYellowIcon";
import { ReactElement } from "react";

interface Props {
  message: string | ReactElement;
  sx?: any;
}

export const Warning = ({ message, sx }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.warning.light,
        padding: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "10px",
        ...sx,
      })}
    >
      <WarningYellowIcon />
      <Typography variant="body2" ml={2}>
        {message}
      </Typography>
    </Box>
  );
};
