import { Box, Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { SupportIcon } from "assets/icons/SupportIcon";
import { useIntl } from "react-intl";
import { roles } from "trans/definedMessages/roleMessages";

export const SupportRoleCell = (params: GridRenderCellParams) => {
  const intl = useIntl();
  if (!params.row.supportRole) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Chip
          size="small"
          color="primary"
          label={
            intl.formatMessage(roles[params.row.supportRole?.title]) || "-"
          }
          icon={
            <Box>
              <SupportIcon />
            </Box>
          }
          sx={{ color: "white" }}
        />
      </Box>
    </Box>
  );
};
