export const WarningYellowIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6808 14.9104L11.3354 3.12661C10.8857 2.29113 9.68754 2.29113 9.23741 3.12661L2.89239 14.9104C2.79473 15.0918 2.74577 15.2954 2.75029 15.5014C2.7548 15.7073 2.81263 15.9086 2.91814 16.0855C3.02365 16.2624 3.17323 16.409 3.35228 16.5108C3.53132 16.6127 3.73372 16.6664 3.93971 16.6667H16.6316C16.8378 16.6667 17.0404 16.6132 17.2197 16.5115C17.399 16.4098 17.5489 16.2633 17.6546 16.0863C17.7603 15.9093 17.8183 15.7079 17.8229 15.5018C17.8275 15.2957 17.7786 15.092 17.6808 14.9104ZM10.2866 14.8423C10.1393 14.8423 9.99536 14.7986 9.87291 14.7168C9.75045 14.635 9.65501 14.5187 9.59865 14.3826C9.54229 14.2466 9.52754 14.0969 9.55628 13.9524C9.58501 13.808 9.65593 13.6753 9.76007 13.5711C9.86421 13.467 9.99689 13.3961 10.1413 13.3674C10.2858 13.3386 10.4355 13.3534 10.5716 13.4097C10.7076 13.4661 10.8239 13.5615 10.9057 13.684C10.9876 13.8064 11.0312 13.9504 11.0312 14.0977C11.0312 14.1955 11.012 14.2923 10.9746 14.3826C10.9371 14.473 10.8823 14.5551 10.8131 14.6242C10.744 14.6934 10.6619 14.7482 10.5716 14.7856C10.4812 14.8231 10.3844 14.8423 10.2866 14.8423ZM11.0953 7.35315L10.8816 11.8954C10.8816 12.0534 10.8188 12.2049 10.7071 12.3167C10.5954 12.4284 10.4438 12.4911 10.2859 12.4911C10.1279 12.4911 9.97635 12.4284 9.86463 12.3167C9.75291 12.2049 9.69015 12.0534 9.69015 11.8954L9.47644 7.35502C9.47164 7.24652 9.48873 7.13816 9.52669 7.03641C9.56466 6.93466 9.62272 6.8416 9.69742 6.76276C9.77212 6.68393 9.86193 6.62094 9.96149 6.57755C10.061 6.53417 10.1683 6.51127 10.2769 6.51023H10.2847C10.3941 6.51017 10.5023 6.53227 10.6029 6.57518C10.7034 6.6181 10.7943 6.68095 10.8699 6.75993C10.9455 6.83891 11.0043 6.9324 11.0428 7.03474C11.0813 7.13708 11.0986 7.24616 11.0938 7.35539L11.0953 7.35315Z"
        fill="#FFC700"
      />
      <path
        d="M10.2865 14.8423C10.1393 14.8423 9.9953 14.7987 9.87284 14.7168C9.75039 14.635 9.65495 14.5187 9.59859 14.3827C9.54223 14.2466 9.52748 14.0969 9.55621 13.9524C9.58494 13.808 9.65586 13.6753 9.76 13.5712C9.86414 13.467 9.99682 13.3961 10.1413 13.3674C10.2857 13.3386 10.4354 13.3534 10.5715 13.4098C10.7076 13.4661 10.8239 13.5616 10.9057 13.684C10.9875 13.8065 11.0312 13.9504 11.0312 14.0977C11.0312 14.1955 11.0119 14.2923 10.9745 14.3827C10.9371 14.473 10.8822 14.5551 10.8131 14.6242C10.7439 14.6934 10.6618 14.7482 10.5715 14.7857C10.4812 14.8231 10.3843 14.8423 10.2865 14.8423ZM11.0952 7.35318L10.8815 11.8954C10.8815 12.0534 10.8187 12.205 10.707 12.3167C10.5953 12.4284 10.4438 12.4912 10.2858 12.4912C10.1278 12.4912 9.97628 12.4284 9.86457 12.3167C9.75285 12.205 9.69009 12.0534 9.69009 11.8954L9.47638 7.35504C9.47157 7.24654 9.48866 7.13819 9.52663 7.03644C9.56459 6.93469 9.62266 6.84162 9.69736 6.76279C9.77206 6.68395 9.86186 6.62097 9.96142 6.57758C10.061 6.53419 10.1683 6.5113 10.2769 6.51025H10.2847C10.394 6.5102 10.5022 6.53229 10.6028 6.57521C10.7034 6.61813 10.7942 6.68097 10.8698 6.75996C10.9454 6.83894 11.0042 6.93242 11.0427 7.03476C11.0812 7.1371 11.0986 7.24618 11.0937 7.35541L11.0952 7.35318Z"
        fill="#181C32"
      />
    </svg>
  );
};
