import UVDFavicon from "../assets/favicons/uvd-favicons.svg";
import UVDLogo from "../assets/logos/uvd-logo.svg";
import { uvdTheme } from "../theme/uvdTheme";
import { Config } from "./configTypes";

export const uvdConfig: Config = {
  theme: uvdTheme,
  defaultRouteKey: "",
  logo: {
    defaultLogo: UVDLogo,
  },
  favicon: UVDFavicon,
  termsLink: "https://uvd.blue-ocean-robotics.com/fmterms",
  contactLink: "mailto:support@uvd-robots.com?subject=UVD Fleet Management",
  oldSystemLinks: {
    production: "https://uvd.blue-ocean-robotics-fleet.com/",
    staging: "https://uvd.staging.blue-ocean-robotics-fleet.com/",
    development: "https://uvd.nightly.blue-ocean-robotics-fleet.com/",
  },
};
