import { Avatar, AvatarProps, useTheme } from "@mui/material";
import { AvatarSize } from "types/types";
import {
  getAvatarBorderRadius,
  getAvatarSize,
  getRandomColor,
} from "utils/avatarUtils";

interface CloudAvatarProps extends AvatarProps {
  size?: AvatarSize;
  seed?: string;
}

export const CloudAvatar = ({
  children,
  size = "medium",
  seed = "",
  ...rest
}: CloudAvatarProps) => {
  const variant: AvatarProps["variant"] = rest.variant || "rounded";
  const theme = useTheme();
  const color = getRandomColor(theme, seed);
  const avatarSize = getAvatarSize(size);
  const borderRadius = getAvatarBorderRadius(size, variant);

  return (
    <Avatar
      variant={variant}
      style={{
        backgroundColor: color.background,
        color: color.contrast,
        textTransform: "uppercase",
        borderRadius: borderRadius,
        ...avatarSize,
      }}
      sx={{
        "& svg > *": { color: color.contrast },
      }}
      {...rest}
    >
      {children}
    </Avatar>
  );
};
