import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  SxProps,
} from "@mui/material";
import { ReactElement } from "react";

interface Props {
  label: ReactElement | string;
  checked: CheckboxProps["checked"];
  onChange?: CheckboxProps["onChange"];
  style?: SxProps;
  description?: ReactElement | string;
  disabled?: boolean;
}

export const CheckboxWithLabel = ({
  label,
  checked,
  onChange,
  disabled,
}: Props) => {
  return (
    <FormControlLabel
      sx={{ marginLeft: "-9px" }}
      disabled={disabled}
      control={<Checkbox checked={checked || false} onChange={onChange} />}
      label={label}
    />
  );
};
