import { Box, Grid, Paper } from "@mui/material";
import { firebaseErrorVar } from "cache";
import { BORBanner } from "components/atoms/BORBanner";
import { StyledLink } from "components/atoms/StyledLink";
import { Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "routing/Routes";
import { useConfig } from "../../config/configHook";

export const LoginWrapper = () => {
  const config = useConfig();
  const location = useLocation();

  const isMainLogin =
    location.pathname.localeCompare(ROUTES.login.default) === 0;

  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        minHeight: "100vh",
        width: "100vw",
        backgroundColor: theme.palette.primary.light,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",

        alignItems: "center",
      })}
    >
      <Paper
        sx={() => ({
          padding: 12,
          paddingBottom: 0,
          width: "25%",
          minWidth: 400,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "3%",
          marginBottom: "3%",
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
        })}
      >
        <Box gap={8} flexDirection="column" display="flex" width={"100%"}>
          <img
            src={config?.logo?.defaultLogo}
            alt="logo"
            style={{ width: 150, margin: 6, alignSelf: "center" }}
          />

          <Outlet />

          <Box>
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {isMainLogin ? null : (
                <>
                  <StyledLink
                    to={"/login"}
                    onClick={() => firebaseErrorVar(undefined)}
                  >
                    Login
                  </StyledLink>
                  <Box
                    sx={(theme) => ({
                      background: theme.palette.grey[300],
                      width: "1px",
                      height: "20px",
                      margin: "0px 16px",
                    })}
                  />
                </>
              )}
              <StyledLink to={config.termsLink || ""} target="_blank">
                Terms
              </StyledLink>
              <Box
                sx={(theme) => ({
                  background: theme.palette.grey[300],
                  width: "1px",
                  height: "20px",
                  margin: "0px 16px",
                })}
              />
              <StyledLink to={config.contactLink || ""} target="_blank">
                Contact Us
              </StyledLink>
            </Grid>
            <Grid item>
              <BORBanner />
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
