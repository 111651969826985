import { Grid } from "@mui/material";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { AssignedRobots } from "components/organisms/AssignedRobots";

export const SupportRobotListPage = () => {
  return (
    <Grid container spacing={6}>
      <HeaderGrid crumbs={[{ label: "Support" }, { label: "Robots" }]} />
      <Grid item xs={12}>
        <AssignedRobots />
      </Grid>
    </Grid>
  );
};
