import { CloudAutoComplete } from "components/atoms/autoComplete/CloudAutocomplete";
import { CloudLabel } from "components/atoms/CloudLabel";
import { InterruptionReason } from "gql/graphql";
import { SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import { interruptionMsg } from "trans/definedMessages/interruptionMessages";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  value: InterruptionReason[] | undefined | null;
  options: InterruptionReason[] | undefined;
  selected: boolean;
}

export const InterruptionFilter = ({
  value,
  onChange,
  options,
  selected,
}: Props & onChangeFilter) => {
  const intl = useIntl();

  return (
    <>
      <CloudLabel primary={selected}>Interruption reason</CloudLabel>
      <CloudAutoComplete
        id="interruptionReasons"
        getOptionLabel={(option: string) =>
          intl.formatMessage(interruptionMsg[option])
        }
        options={options || []}
        value={value}
        placeholder="Reason"
        onChange={(event: SyntheticEvent, value: any) =>
          onChange(value, "interruptionReasons")
        }
      />
    </>
  );
};
