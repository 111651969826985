import { Box, Typography } from "@mui/material";
import { ServiceNeededIcon } from "assets/icons/ServiceNeededIcon";
import { isValidElement, ReactElement } from "react";

interface Props {
  label: string | ReactElement;
  value: string | number | ReactElement;
  labelColor?: string;
  showWarning?: boolean;
  warningLabel?: string;
  wrapperStyle?: any;
  warningIcon?: ReactElement;
}

export const LabelValueListItem = ({
  label,
  value,
  labelColor,
  showWarning = false,
  warningLabel,
  wrapperStyle,
  warningIcon,
}: Props) => {
  return (
    <Box
      sx={(theme) => ({
        background: showWarning ? theme.palette.warning.light : "auto",
        padding: showWarning ? 2 : undefined,
        borderRadius: "10px",
        ...wrapperStyle,
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
          }}
        >
          <Typography
            color={(theme) =>
              labelColor
                ? labelColor
                : showWarning
                  ? theme.palette.grey[900]
                  : theme.palette.grey[700]
            }
            sx={{ minWidth: "47%", marginRight: "8%" }}
          >
            {label}
          </Typography>
          <Typography sx={{ minWidth: "45%" }}>{value}</Typography>
        </Box>
        {showWarning ? (
          isValidElement(warningIcon) ? (
            warningIcon
          ) : (
            <ServiceNeededIcon />
          )
        ) : (
          <Box sx={{ width: 20 }} />
        )}
      </Box>
      {showWarning ? (
        <Typography variant="body2" color={(theme) => theme.palette.grey[700]}>
          {warningLabel || "Technical service needed"}
        </Typography>
      ) : null}
    </Box>
  );
};
