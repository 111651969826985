import { CheckboxWithLabel } from "components/atoms/CheckboxWithLabel";
import { CloudLabel } from "components/atoms/CloudLabel";
import { FilterFormGroup } from "components/atoms/FilterFormGroup";
import { DisinfectionModes } from "gql/graphql";
import { useIntl } from "react-intl";
import { enumMsg } from "trans/definedMessages/enumMessages";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  value: DisinfectionModes[] | undefined | null;
  options: DisinfectionModes[] | undefined;
  selected: boolean;
}

export const ModeFilter = ({
  value,
  onChange,
  options,
  selected,
}: Props & onChangeFilter) => {
  const intl = useIntl();

  return (
    <>
      <CloudLabel primary={selected}>Mode</CloudLabel>
      <FilterFormGroup id="modes">
        <CheckboxWithLabel
          disabled={!options?.includes(DisinfectionModes.Autonomous)}
          label={intl.formatMessage(enumMsg[DisinfectionModes.Autonomous])}
          checked={value?.includes(DisinfectionModes.Autonomous)}
          onChange={(e) => {
            onChange(
              DisinfectionModes.Autonomous,
              "disinfectionModes",
              e.target.checked,
            );
          }}
        />
        <CheckboxWithLabel
          disabled={!options?.includes(DisinfectionModes.RemoteControlled)}
          label={intl.formatMessage(
            enumMsg[DisinfectionModes.RemoteControlled],
          )}
          checked={value?.includes(DisinfectionModes.RemoteControlled)}
          onChange={(e) =>
            onChange(
              DisinfectionModes.RemoteControlled,
              "disinfectionModes",
              e.target.checked,
            )
          }
        />
        <CheckboxWithLabel
          disabled={!options?.includes(DisinfectionModes.Stationary)}
          label={intl.formatMessage(enumMsg[DisinfectionModes.Stationary])}
          checked={value?.includes(DisinfectionModes.Stationary)}
          onChange={(e) =>
            onChange(
              DisinfectionModes.Stationary,
              "disinfectionModes",
              e.target.checked,
            )
          }
        />
      </FilterFormGroup>
    </>
  );
};
