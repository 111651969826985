import { Box, Typography } from "@mui/material";
import { isValidElement, ReactElement } from "react";
import { IoChevronForward } from "react-icons/io5";

export type Crumb = {
  label: string;
  onClick?: () => void;
  pill?: ReactElement;
};

interface Props {
  crumbs: Array<Crumb>;
}

export const CloudBreadCrumb = ({ crumbs }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        padding: "16px 0px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& svg > *": {
          color: theme.palette.grey[500],
        },
      })}
    >
      {crumbs.map((item, index) => {
        const isLast = index + 1 === crumbs.length;
        const hasPill = isValidElement(item.pill);

        return (
          <Box
            key={item.label}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              onClick={item.onClick ? item.onClick : undefined}
              variant="h4"
              mr={1}
              color={(theme) => (isLast ? "auto" : theme.palette.grey[500])}
              sx={(theme) => ({
                cursor: item.onClick ? "pointer" : "auto",
                textWrap: "nowrap",
                ":hover": {
                  color: item.onClick ? theme.palette.primary.main : undefined,
                },
              })}
            >
              {item.label}
            </Typography>
            {isLast ? (
              hasPill ? (
                item.pill
              ) : null
            ) : (
              <IoChevronForward
                size={16}
                style={{ marginRight: "4px", marginBottom: "3px" }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
