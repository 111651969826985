import { useTheme } from "@mui/material";

import { DonutChart } from "components/modules/charts/DonutChart";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { graphql } from "gql";
import { DisinfectionFilter, DisinfectionModes } from "gql/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query PositionsReached(
    $filter: DisinfectionFilter!
    $autonomousFilter: DisinfectionFilter!
  ) {
    disinfectionPositionsStatuses(filter: $filter) {
      disinfected
      failed
      remaining
    }
    disinfections(filter: $autonomousFilter) {
      count
    }
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const PositionsReachedChart = ({ hiddenFilter }: Props) => {
  const theme = useTheme();

  const filterParams = useDDFilterParams();

  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
      autonomousFilter: {
        disinfectionModes: [DisinfectionModes.Autonomous],
        ...filterParams,
        ...hiddenFilter,
      },
    },
    skip: !filterParams?.timeSpan,
  });

  const averagePositionsDisinfected = useMemo(() => {
    const disinfected = data?.disinfectionPositionsStatuses.disinfected || 0;
    const notCompleted =
      (data?.disinfectionPositionsStatuses.failed || 0) +
      (data?.disinfectionPositionsStatuses.remaining || 0);

    const total = disinfected + notCompleted;

    if (disinfected === 0 && notCompleted === 0) return 0;

    return (disinfected / total) * 100;
  }, [data]);

  return (
    <DonutChart
      header={
        <FormattedMessage
          id="positions-reached"
          defaultMessage="Positions Reached"
        />
      }
      description={
        <FormattedMessage
          id="positions-reached-description"
          defaultMessage="Average rate of disinfected positions during predefined disinfections"
        />
      }
      value={
        (data?.disinfections.count || 0) > 0
          ? Math.round(averagePositionsDisinfected)
          : "-"
      }
      paperProps={{
        style: {
          backgroundColor: theme.palette.success.main,
        },
      }}
      paperTextColor="white"
      donutSize={112}
      donutColor="white"
      donutBackgroundColor="rgba(24, 28, 50, 0.05)"
      donutTextColor="white"
    />
  );
};
