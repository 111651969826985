import { ApolloError } from "@apollo/client";
import { ActivationState, Affiliation, Role, RoleType } from "gql/graphql";
import _ from "lodash";
import { Location } from "react-router-dom";

// TODO: cannot add type Identity because of affiliation type issues
export const hasSupportRole = (identity: any) => {
  return !!identity?.supportRole;
};

export const isSupportPath = (location: Location) => {
  return location.pathname.includes("support");
};

export const getUniqueUserRoles = (identityAffiliations: any) => {
  return _.chain(identityAffiliations)
    ?.map((item: Affiliation) => item.roles?.map((role: Role) => role.title))
    .flatten()
    .uniq()
    .value();
};

export const getOwnerAffiliationsPending = (
  identityAffiliations: Affiliation[],
) => {
  return (identityAffiliations || [])?.filter((item: Affiliation) => {
    return (
      _.first(item.roles || [])?.title === RoleType.Owner &&
      item.organization?.activationState === ActivationState.Pending
    );
  });
};

export const getOrganizationOwnerInfo = (
  organizationAffiliations: Affiliation[],
) => {
  const ownerAffiliations = (organizationAffiliations || []).filter(
    (item: Affiliation) => _.first(item.roles)?.title === RoleType.Owner,
  );

  const firstOwnerAffiliation = _.first(ownerAffiliations) as Affiliation;
  const firstOwnerRole = _.first(firstOwnerAffiliation?.roles);

  return {
    firstOwner: firstOwnerAffiliation,
    firstOwnerInviteDate: firstOwnerRole?.date,
    hasOwner: ownerAffiliations?.length > 0,
    ownerAffiliations,
  };
};

export const getOrganizationStatus = (
  activationState: ActivationState,
  hasOwner: boolean,
) => {
  switch (activationState) {
    case ActivationState.Active:
      return !hasOwner ? "MISSING_OWNER" : ActivationState.Active;
    case ActivationState.Pending:
      return !hasOwner ? "MISSING_OWNER" : ActivationState.Pending;
    default:
      break;
  }
};

export const getIsForbidden = (error: ApolloError | undefined) => {
  if (!error) return false;
  return _.first(error?.graphQLErrors)?.extensions?.code === "FORBIDDEN";
};
