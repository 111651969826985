import { linearProgressClasses, Theme } from "@mui/material";
import { secondsToHours } from "date-fns";
import { RobotUvcTube } from "gql/graphql";
import { useMemo } from "react";
import { CloudProgressBar } from "./CloudProgressBar";

const MAX_TUBE_VALUE_SECONDS = 12000 * 3600; // 12.000 hours converted to seconds
const TUBE_MAX_LEVEL = 11000 * 3600; // 1.000 hours left is critical

export const UVCTube = ({
  tube,
  index,
  showWarning = false,
}: {
  tube?: RobotUvcTube | null;
  index?: number;
  showWarning?: boolean;
}) => {
  const isUndefined = tube && Object.keys(tube).length === 0;
  const operationSeconds = tube?.operationSeconds || 0;

  const valueLeft = isUndefined
    ? ""
    : operationSeconds > MAX_TUBE_VALUE_SECONDS
      ? 0
      : secondsToHours(MAX_TUBE_VALUE_SECONDS - operationSeconds);

  const progressValue = useMemo(() => {
    if (isUndefined) return 0;

    const secondsLeft = MAX_TUBE_VALUE_SECONDS - operationSeconds;

    return (secondsLeft / MAX_TUBE_VALUE_SECONDS) * 100;
  }, [operationSeconds]);

  const isCritical = operationSeconds >= TUBE_MAX_LEVEL;

  return (
    <CloudProgressBar
      progressBarSx={(theme: Theme) => ({
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: showWarning
            ? theme.palette.warning.dark
            : isCritical
              ? theme.palette.error.main
              : theme.palette.primary.main,
        },
      })}
      label={"Tube " + ((index || 0) + 1)}
      valuePostFix={isUndefined ? "" : "h"}
      labelValue={valueLeft}
      percentageValue={progressValue}
    />
  );
};
