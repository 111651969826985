export const HamburgerIcon = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.125C0 0.50368 0.50368 0 1.125 0H16.875C17.4963 0 18 0.50368 18 1.125C18 1.74632 17.4963 2.25 16.875 2.25H1.125C0.50368 2.25 0 1.74632 0 1.125ZM0 6C0 5.37868 0.50368 4.875 1.125 4.875H16.875C17.4963 4.875 18 5.37868 18 6C18 6.62132 17.4963 7.125 16.875 7.125H1.125C0.50368 7.125 0 6.62132 0 6ZM1.125 9.75C0.50368 9.75 0 10.2537 0 10.875C0 11.4963 0.50368 12 1.125 12H16.875C17.4963 12 18 11.4963 18 10.875C18 10.2537 17.4963 9.75 16.875 9.75H1.125Z"
        fill="currentColor"
      />
    </svg>
  );
};
