import { Checkbox, FormControlLabel } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { FilterFormGroup } from "components/atoms/FilterFormGroup";
import { RobotType } from "gql/graphql";
import { useIntl } from "react-intl";
import { enumMsg } from "trans/definedMessages/enumMessages";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  value: RobotType[] | undefined | null;
  options: RobotType[] | undefined;
  selected: boolean;
}

export const RobotTypeFilter = ({
  value,
  onChange,
  options,
  selected,
}: Props & onChangeFilter) => {
  const intl = useIntl();

  return (
    <>
      <CloudLabel primary={selected}>Robot type</CloudLabel>
      <FilterFormGroup id="robotTypes">
        <FormControlLabel
          disabled={!options?.includes(RobotType.Tuv)}
          control={
            <Checkbox
              id={RobotType.Tuv}
              name="tuv"
              checked={value?.includes(RobotType.Tuv)}
              onChange={(e) =>
                onChange(RobotType.Tuv, "robotTypes", e.target.checked)
              }
            />
          }
          label={intl.formatMessage(enumMsg[RobotType.Tuv])}
        />
        <FormControlLabel
          disabled={!options?.includes(RobotType.Eu)}
          control={
            <Checkbox
              id={RobotType.Eu}
              name="eu"
              checked={value?.includes(RobotType.Eu)}
              onChange={(e) =>
                onChange(RobotType.Eu, "robotTypes", e.target.checked)
              }
            />
          }
          label={intl.formatMessage(enumMsg[RobotType.Eu])}
        />
        <FormControlLabel
          disabled={!options?.includes(RobotType.Maritime)}
          control={
            <Checkbox
              id={RobotType.Maritime}
              name="maritime"
              checked={value?.includes(RobotType.Maritime)}
              onChange={(e) =>
                onChange(RobotType.Maritime, "robotTypes", e.target.checked)
              }
            />
          }
          label={intl.formatMessage(enumMsg[RobotType.Maritime])}
        />
        <FormControlLabel
          disabled={!options?.includes(RobotType.Pharma)}
          control={
            <Checkbox
              id={RobotType.Pharma}
              name="pharma"
              checked={value?.includes(RobotType.Pharma)}
              onChange={(e) =>
                onChange(RobotType.Pharma, "robotTypes", e.target.checked)
              }
            />
          }
          label={intl.formatMessage(enumMsg[RobotType.Pharma])}
        />
      </FilterFormGroup>
    </>
  );
};
