import { Box, Theme } from "@mui/material";
import { DataLabelAndValueListItem } from "components/atoms/DataLabelAndValueListItem";
import { WidgetWrapper } from "components/atoms/WidgetWrapper";
import { DisinfectionStatus } from "gql/graphql";
import _ from "lodash";
import { IoCheckmarkCircle, IoRemoveCircleOutline } from "react-icons/io5";
import { SelectedDisinfectionReport } from "types/types";
import { getMapPositions, getTaskDuration } from "utils/UVD/UVDUtils";

export const ReportStatusInfo = ({
  selectedReport,
}: {
  selectedReport: SelectedDisinfectionReport;
}) => {
  const { completed, all } = getMapPositions(selectedReport);
  const capitalizedStatus = _.capitalize(selectedReport?.status || "-");
  const { taskDurationInHMS } = getTaskDuration(
    selectedReport?.start,
    selectedReport?.end,
  );

  return (
    <WidgetWrapper
      sx={(theme: Theme) => ({
        borderStyle: "dashed",
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.light,
      })}
    >
      <Box gap={2} flexGrow={1} display="flex" flexDirection="column">
        <DataLabelAndValueListItem
          label="Disinfection mode"
          value={selectedReport?.type ?? "-"}
        />
        {selectedReport?.type === "Stationary" ? (
          <DataLabelAndValueListItem
            label="Minutes disinfected"
            value={Math.floor((selectedReport?.uvcLightDuration ?? 0) / 60)}
          />
        ) : null}
        {selectedReport?.type === "Predefined" ? (
          <DataLabelAndValueListItem
            label="Positions disinfected"
            value={`${completed.length} out of ${all.length}`}
          />
        ) : null}
        <DataLabelAndValueListItem
          label="Total duration"
          value={taskDurationInHMS}
        />
        <DataLabelAndValueListItem
          label="Status"
          value={
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                "& svg > *": {
                  color:
                    capitalizedStatus === DisinfectionStatus.Complete
                      ? theme.palette.success.main
                      : theme.palette.grey[600],
                },
              })}
            >
              {capitalizedStatus === DisinfectionStatus.Complete ? (
                <IoCheckmarkCircle size={16} style={{ marginRight: 4 }} />
              ) : (
                <IoRemoveCircleOutline size={20} style={{ marginRight: 4 }} />
              )}
              {capitalizedStatus}
            </Box>
          }
        />
      </Box>
    </WidgetWrapper>
  );
};
