import { UVDRobotIcon } from "assets/icons/UVDRobotIcon";
import { UVDRobotUnassignedIcon } from "assets/icons/UVDRobotUnassignedIcon";
import { MenuItem } from "components/atoms/dashboard/menuItems/MenuItem";
import { MenuTitle } from "components/atoms/dashboard/menuItems/MenuTitle";
import { graphql } from "gql";
import { IRobot } from "gql/graphql";
import {
  IoBusinessOutline,
  IoColorWand,
  IoGridOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { ROUTES } from "routing/Routes";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

export const SUPPORT_MENU_QUERY = graphql(`
  query SupportMenuItem {
    organizations {
      id
    }
    robots(isActive: true) {
      robots {
        serialNumber
      }
    }
    unassignedRobots: robots(isActive: false) {
      robots {
        serialNumber
      }
    }
    identities {
      id
    }
  }
`);

export const SupportMenuItem = () => {
  const { data } = useQueryWithSnack(SUPPORT_MENU_QUERY);

  const robots = data?.robots.robots as IRobot[];
  const unassignedRobots = data?.unassignedRobots.robots as IRobot[];

  return (
    <>
      <MenuTitle title="Support" hideSettings />
      <NavLink
        to={ROUTES.support.disinfections}
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => {
          return (
            <MenuItem
              hideCount
              isActive={isActive}
              value="Disinfections"
              icon={<IoGridOutline style={{ height: 20, width: 20 }} />}
            />
          );
        }}
      </NavLink>
      <NavLink
        to={ROUTES.support.organizations}
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Organizations"
              icon={<IoBusinessOutline style={{ height: 20, width: 20 }} />}
              count={data?.organizations?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink to={ROUTES.support.users} style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Users"
              icon={<IoPersonOutline style={{ height: 20, width: 20 }} />}
              count={data?.identities?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink to={ROUTES.support.robots} style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Robots"
              icon={<UVDRobotIcon />}
              count={robots?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink
        to={ROUTES.support.unassignedRobots}
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Unassigned Robots"
              icon={<UVDRobotUnassignedIcon />}
              count={unassignedRobots?.length || 0}
            />
          );
        }}
      </NavLink>
      <NavLink to={ROUTES.support.migration} style={{ textDecoration: "none" }}>
        {({ isActive }) => {
          return (
            <MenuItem
              isActive={isActive}
              value="Migrate from FM 1.0"
              icon={<IoColorWand />}
              hideCount
            />
          );
        }}
      </NavLink>
    </>
  );
};
