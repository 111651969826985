import { InlineCard } from "components/atoms/InlineCard";
import {
  OrganizationCardContent,
  OrganizationCardContentProps,
} from "components/modules/cards/OrganizationCardContent";

interface OrganizationCardProps extends OrganizationCardContentProps {
  onClick: () => void;
  inlineCardStyle?: any;
}

export const OrganizationCard = ({
  onClick,
  inlineCardStyle,
  ...props
}: OrganizationCardProps) => {
  return (
    <InlineCard onClick={onClick} style={inlineCardStyle}>
      <OrganizationCardContent wrapperStyle={{ padding: 4 }} {...props} />
    </InlineCard>
  );
};
