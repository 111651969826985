import { Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const ListSectionHeader = ({ children }: Props) => {
  return (
    <Typography
      sx={(theme) => ({
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 700,
        lineHeight: "20px",
        textTransform: "uppercase",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
      })}
    >
      {children}
    </Typography>
  );
};
