import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { RoleCell } from "components/modules/tableCells/RoleCell";
import { UserStatusCell } from "components/modules/tableCells/UserStatusCell";
import { Users } from "components/organisms/Users";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { getFormattedDate } from "utils/utilFunctions";
import { ForbiddenPage } from "./ForbiddenPage";

export const ORG_USER_LIST_QUERY = gql`
  query UsersForOrganization($orgId: String!) {
    organization(id: $orgId) {
      id
      name
      country
      affiliations {
        identity {
          id
          inviteState
          lastLogin
          ...UserCardContent
        }
      }
    }
  }
  ${UserCardContent.fragments.identity}
`;

const columns: GridColDef[] = [
  {
    field: "user",
    width: 270,
    headerName: "User",
    valueGetter: (params) => useUserDisplayName(params.row),
    renderCell: (params) => (
      <UserCardContent identity={params.row} size="small" hideIcon />
    ),
  },
  {
    field: "role",
    width: 270,
    headerName: "Role",
    sortable: false,
    renderCell: RoleCell,
  },
  {
    field: "lastLogin",
    width: 150,
    headerName: "Last login",
    renderCell: (params) => getFormattedDate(params.row?.lastLogin),
  },
  {
    field: "status",
    width: 150,
    headerName: "Status",
    valueGetter: (params) => params.row.inviteState,
    renderCell: UserStatusCell,
  },
];

export const OrganizationUserListPage = () => {
  const { orgId } = useParams();

  const { data, error } = useQueryWithSnack(ORG_USER_LIST_QUERY, {
    variables: { orgId: orgId ?? "" },
    skip: orgId === "support" || !orgId,
  });

  const users = useMemo(() => {
    return (data?.organization.affiliations || [])?.map((item: any) => ({
      ...item.identity,
      ...item.role,
    }));
  }, [data?.organization]);

  const forbidden = getIsForbidden(error);

  if (forbidden) {
    return <ForbiddenPage />;
  }

  return (
    <Grid container spacing={6}>
      <HeaderGrid
        crumbs={[
          {
            label: orgId ? data?.organization?.name || "-" : "-",
          },
          { label: "Users" },
        ]}
      />
      <Grid item xs={12}>
        <Users
          users={users || []}
          columns={columns}
          organization={data?.organization}
        />
      </Grid>
    </Grid>
  );
};
