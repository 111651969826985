import { Autocomplete, Box, Paper } from "@mui/material";
import { IoChevronDown, IoSearch } from "react-icons/io5";
import { CloudTextField } from "../CloudTextField";

export const CustomPaper = (props: any) => {
  return (
    <Paper
      elevation={8}
      {...props}
      sx={(theme) => ({ boxShadow: theme.shadows[2] })}
    />
  );
};

export const CloudAutoComplete = (props: any) => {
  const hidePopupArrow = props.hidePopupArrow || true;

  return (
    <Autocomplete
      multiple={props.multiple || true}
      options={props?.options || []}
      sx={{
        height: "48px",
        ".MuiAutocomplete-popupIndicator": {
          display: hidePopupArrow ? "none" : "auto",
        },
        ...props.sx,
      }}
      PaperComponent={CustomPaper}
      popupIcon={<IoChevronDown size={20} />}
      renderInput={(params) => (
        <CloudTextField
          {...params}
          {...props.textfieldprops}
          fullWidth
          placeholder={props.placeholder || undefined}
          InputProps={{
            ...params.InputProps,
            autoComplete: "no",
            disableUnderline: true,
            startAdornment: props.hideSearchIcon ? undefined : (
              <Box
                sx={(theme) => ({
                  "& svg:first-child": {
                    color: theme.palette.grey[500],
                  },
                })}
              >
                <IoSearch
                  size={20}
                  style={{
                    marginRight: 8,
                  }}
                />
                {params.InputProps.startAdornment}
              </Box>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};
