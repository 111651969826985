import { ListSectionHeader } from "components/atoms/ListSectionHeader";
import { Organization, RobotAffiliation } from "gql/graphql";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { OrganizationCardContent } from "../cards/OrganizationCardContent";
import { ResultWrapper } from "./GlobalSearch";

interface Props {
  organizationResults: any;
  onClose: () => void;
  isSupporter: boolean;
}

export const OrganizationResults = ({
  organizationResults,
  onClose,
  isSupporter,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleNavigation = (org: Organization) => {
    if (isSupporter) {
      navigate("/cloud/support/organizations/" + org.id);
    } else {
      navigate(`/cloud/fm/${org.id}/settings`);
    }
  };

  return (
    <>
      <ListSectionHeader>Organizations</ListSectionHeader>
      {organizationResults.map((org: Organization) => (
        <ResultWrapper
          key={org.id}
          onClick={() => {
            onClose();
            handleNavigation(org);
          }}
        >
          <OrganizationCardContent
            organization={org}
            hideIcon
            description={intl.formatMessage(
              {
                id: "globalsearch.organization.details",
                defaultMessage: "{robotCount} robots / {userCount} users",
              },
              {
                robotCount: org.robotAffiliations.filter(
                  (item: RobotAffiliation) => !!item.active,
                ).length,
                userCount: (org.affiliations || []).length,
              },
            )}
          />
        </ResultWrapper>
      ))}
    </>
  );
};
