import { Typography } from "@mui/material";
import { getUvdRobotStatus } from "utils/utilFunctions";
import {
  useUvdConnectionStatus,
  UVDConnectionStatus,
} from "utils/UVD/useUvdConnectionStatus";

export const UVDRobotStatusCell = ({ robot, style, variant }: any) => {
  const connectionStatus = useUvdConnectionStatus(robot);

  return (
    <Typography
      variant={variant || undefined}
      sx={(theme) => ({
        color:
          connectionStatus === UVDConnectionStatus.OFFLINE
            ? theme.palette.grey[800]
            : "auto",
        ...style,
      })}
    >
      {getUvdRobotStatus(robot, connectionStatus)}
    </Typography>
  );
};
