import { canUse } from "@blue-ocean-robotics/cloud-library";
import { Privilege, Role } from "gql/graphql";
import { useMemo } from "react";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { graphql } from "../../gql";

const GET_PRIVILEGES = graphql(`
  query PrivilegesWrapper {
    me {
      supportRole {
        title
        privileges
        clearance
        override
      }
      affiliations {
        id
        organization {
          id
        }
        roles {
          title
          privileges
          clearance
          override
        }
      }
    }
  }
`);

export function usePrivilege(privilege: Privilege, organizationId?: string) {
  const { data, error, loading } = useQueryWithSnack(GET_PRIVILEGES);

  const hasPrivilege = useMemo(() => {
    if (!data) return false;
    return canUse(data.me, privilege, organizationId);
  }, [data, privilege, organizationId]);

  return { loading, error, hasPrivilege };
}

export function useHasPrivilege(privilege: Privilege) {
  const { data, error, loading } = useQueryWithSnack(GET_PRIVILEGES);

  const hasPrivilege = useMemo(() => {
    if (!data) return false;
    const me = data.me;
    const affiliationPrivileges =
      me.affiliations
        ?.flatMap((o) => o.roles as Role[])
        .flatMap((o) => o.privileges) ?? [];
    const supportRolePrivileges = me.supportRole?.privileges ?? [];
    const concatedPrivileges = affiliationPrivileges.concat(
      supportRolePrivileges,
    );

    return concatedPrivileges.some((o) => o == privilege);
  }, [data, privilege]);

  return { loading, error, hasPrivilege };
}
