import { Box, useTheme } from "@mui/material";
import { GoogleIcon } from "assets/icons/GoogleIcon";
import { MicrosoftIcon } from "assets/icons/MicrosoftIcon";
import { InlineCard } from "components/atoms/InlineCard";
import { StatusCardContent } from "components/modules/cards/StatusCardContent";
import { ManageDomain } from "components/organisms/panels/ManageDomain";
import { DomainVerification, Privilege } from "gql/graphql";
import { useMemo, useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { usePrivilege } from "utils/privileges/usePrivileges";

export const DNSVerificationCard = (verification: DomainVerification) => {
  const theme = useTheme();
  const { orgId } = useParams();

  const canVerifyDomain = usePrivilege(
    Privilege.DomainVerificationVerify,
    orgId,
  ).hasPrivilege;

  const canDeleteDomain = usePrivilege(
    Privilege.DomainVerificationDelete,
    orgId,
  ).hasPrivilege;

  const canOpenDomainDrawer = canVerifyDomain || canDeleteDomain;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<
    "waiting" | "failed" | "success"
  >("waiting");

  const { verified, hostname } = verification;

  const statusText = useMemo(
    () => (verified ? "Verified" : "Not verified"),
    [verified],
  );

  const statusColor = useMemo(() => {
    switch (verified) {
      case true:
        return theme.palette.success.main;
      case false:
      default:
        return theme.palette.warning.main;
    }
  }, [verified]);

  return (
    <>
      <InlineCard
        disabled={!canOpenDomainDrawer}
        onClick={() => setDrawerOpen(true)}
      >
        <StatusCardContent
          header={hostname}
          description={statusText}
          statusColor={
            verificationStatus === "failed"
              ? theme.palette.error.main
              : verificationStatus === "success"
                ? theme.palette.success.main
                : statusColor
          }
          wrapperStyle={{ flexGrow: 1 }}
          action={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "24px",
                  marginRight: 1,
                  "& img": {
                    width: "24px",
                    height: "24px",
                  },
                }}
              >
                <MicrosoftIcon />
              </Box>
              <Box
                sx={{
                  height: "24px",
                  marginRight: canVerifyDomain || canDeleteDomain ? 3 : 0,
                  "& svg": {
                    width: "24px",
                    height: "24px",
                  },
                }}
              >
                <GoogleIcon />
              </Box>
              {canVerifyDomain || canDeleteDomain ? (
                <IoSettingsOutline size={18} />
              ) : null}
            </Box>
          }
        />
      </InlineCard>
      {drawerOpen ? (
        <ManageDomain
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          verification={verification}
          statusColor={statusColor}
          statusText={statusText}
          onVerificationCompleted={setVerificationStatus}
        />
      ) : null}
    </>
  );
};
