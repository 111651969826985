import { useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { FormikTextField } from "components/atoms/formik";
import { useFormik } from "formik";
import { graphql } from "gql";
import { Identity } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { getInitials } from "utils/utilFunctions";
import * as Yup from "yup";

const UPDATE_ME = graphql(`
  mutation UpdateMe($payload: UpdateIdentityPayload!) {
    updateMe(payload: $payload) {
      id
    }
  }
`);

interface Props {
  onComplete: () => void;
  buttonLabel: string;
  user?: Identity;
}

interface Values {
  firstName: string;
  lastName: string;
}

export const SetupProfile = ({ onComplete, buttonLabel, user }: Props) => {
  const intl = useIntl();

  const [updateMe, { loading }] = useMutation(UPDATE_ME);

  const formik = useFormik<Values>({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: "required",
            defaultMessage: "Required",
          }),
        ),
      lastName: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: "required",
            defaultMessage: "Required",
          }),
        ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Saving your name...", {
        variant: "loading",
        persist: true,
      });

      updateMe({
        variables: {
          payload: {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
          },
        },
        onCompleted: () => {
          enqueueSnackbar(`Your name was saved`, {
            variant: "success",
          });
          closeSnackbar(snackbarId);
          onComplete();
        },
        onError: () => {
          enqueueSnackbar(`Could not save your name`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  const initials = useMemo(() => {
    if (
      formik.values.firstName.trim() !== "" ||
      formik.values.lastName.trim() !== ""
    ) {
      return getInitials(
        [formik.values.firstName, formik.values.lastName]
          .filter((item) => item !== "")
          .join(" "),
      );
    } else {
      return <IoPersonOutline />;
    }
  }, [formik.values]);

  return (
    <form>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <UserAvatar identity={user} size="xlarge">
          {initials}
        </UserAvatar>
        <Box mb={12} flexDirection="row" display="flex" gap={4} width="100%">
          <FormikTextField
            formik={formik}
            fullWidth
            name="firstName"
            label={intl.formatMessage({
              id: "firstName",
              defaultMessage: "First name",
            })}
          />
          <FormikTextField
            formik={formik}
            fullWidth
            name="lastName"
            label={intl.formatMessage({
              id: "lastName",
              defaultMessage: "Last name",
            })}
          />
        </Box>
        <Button
          disabled={
            loading ||
            !formik.isValid ||
            (formik.values.firstName === "" && formik.values.lastName === "")
          }
          type="submit"
          sx={{ width: "100%" }}
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          {buttonLabel}
        </Button>
      </Box>
    </form>
  );
};
