import { StyledLink } from "components/atoms/StyledLink";
import { Activity, ActivityCode, RoleType } from "gql/graphql";
import { activityMsg } from "trans/definedMessages/activityLogMessages";
import { roles } from "trans/definedMessages/roleMessages";
import { useUserDisplayName } from "utils/useUserDisplayName";

const getVariableContent = (target?: any) => {
  const typename = target?.related?.__typename;
  return target ? (target.related as typeof typename) : null;
};

export const getActivityMessage = (
  logEntry: Activity,
  isCurrentUserSupporter: boolean,
  intl: any,
) => {
  const code = logEntry.code;
  const msgVariables = logEntry.variables;

  const target = msgVariables.find((item: any) => item.key === "TARGET");
  const role = msgVariables.find((item: any) => item.key === "ROLE");
  const organization = msgVariables.find(
    (item: any) => item.key === "ORGANIZATION",
  );
  const fromOrg = msgVariables.find(
    (item: any) => item.key === "FROM_ORGANIZATION",
  );
  const toOrg = msgVariables.find(
    (item: any) => item.key === "TO_ORGANIZATION",
  );

  const getNavigationUrl = (
    type: "organizations" | "robots" | "users",
    typeId: string,
    fmOrgId?: string,
  ) => {
    return isCurrentUserSupporter
      ? `/cloud/support/${type}/${typeId}`
      : `/cloud/fm/${fmOrgId}/${type}/${typeId}`;
  };

  let variables = {};

  const targetContent = getVariableContent(target);
  const roleValue = role?.value as RoleType;
  const orgContent = organization ? getVariableContent(organization) : null;
  const fromOrgContent = fromOrg ? getVariableContent(fromOrg) : null;
  const toOrgContent = toOrg ? getVariableContent(toOrg) : null;

  switch (code) {
    case ActivityCode.OrganizationCreated:
      variables = {
        orgName: targetContent?.name,
        orgLink: (chunk: any) =>
          !targetContent ? (
            "[ Unknown organization ]"
          ) : (
            <StyledLink
              to={getNavigationUrl("organizations", targetContent.id)}
            >
              {chunk}
            </StyledLink>
          ),
      };
      break;
    case ActivityCode.OrganizationUpdated:
      variables = {
        orgName: targetContent?.name,
        orgLink: (chunk: any) =>
          !targetContent ? (
            "[ Unknown organization ]"
          ) : (
            <StyledLink
              to={getNavigationUrl("organizations", targetContent.id)}
            >
              {chunk}
            </StyledLink>
          ),
      };
      break;
    case ActivityCode.IdentityOnboarded:
      variables = {
        username: useUserDisplayName(targetContent),
      };
      break;
    case ActivityCode.RoleGranted:
      variables = {
        username: useUserDisplayName(targetContent),
        role: intl.formatMessage(roles[roleValue]),
        orgName: orgContent?.name,
        isSupportRole: roleValue === RoleType.SecondLevel,
        userLink: (chunk: any) =>
          !targetContent ? (
            "[ Unknown user ]"
          ) : (
            <StyledLink to={getNavigationUrl("users", targetContent.id)}>
              {chunk}
            </StyledLink>
          ),
        orgLink: (chunk: any) =>
          !orgContent ? (
            "[ Unknown organization ]"
          ) : (
            <StyledLink to={getNavigationUrl("organizations", orgContent.id)}>
              {chunk}
            </StyledLink>
          ),
      };
      break;
    case ActivityCode.RoleRevoked:
      variables = {
        username: useUserDisplayName(targetContent),
        role: intl.formatMessage(roles[roleValue]),
        orgName: orgContent?.name,
        userLink: (chunk: any) =>
          !targetContent ? (
            "[ Unknown user ]"
          ) : (
            <StyledLink to={getNavigationUrl("users", targetContent.id)}>
              {chunk}
            </StyledLink>
          ),
        orgLink: (chunk: any) =>
          !orgContent ? (
            "[ Unknown organization ]"
          ) : (
            <StyledLink to={getNavigationUrl("organizations", orgContent.id)}>
              {chunk}
            </StyledLink>
          ),
      };
      break;
    case ActivityCode.RobotCreated:
      variables = {
        serialNumber: targetContent?.serialNumber,
        robotLink: (chunk: any) =>
          !targetContent ? (
            "[ Unknown robot ]"
          ) : (
            <StyledLink to={getNavigationUrl("robots", targetContent.id)}>
              {chunk}
            </StyledLink>
          ),
      };
      break;
    case ActivityCode.RobotTransferred:
      variables = {
        serialNumber: targetContent?.serialNumber,
        type: toOrg && fromOrg ? "BOTH" : !!toOrg ? "TO" : "FROM",
        toOrgName: toOrgContent?.name || null,
        fromOrgName: fromOrgContent?.name || null,
        robotLink: (chunk: any) =>
          !targetContent ? (
            "[ Unknown robot ]"
          ) : (
            <StyledLink to={getNavigationUrl("robots", targetContent.id)}>
              {chunk}
            </StyledLink>
          ),
        toOrgLink: (chunk: any) =>
          !toOrgContent ? (
            "[ Unknown organization ]"
          ) : (
            <StyledLink to={getNavigationUrl("organizations", toOrgContent.id)}>
              {chunk}
            </StyledLink>
          ),
        fromOrgLink: (chunk: any) =>
          !fromOrgContent ? (
            "[ Unknown organization ]"
          ) : (
            <StyledLink
              to={getNavigationUrl("organizations", fromOrgContent.id)}
            >
              {chunk}
            </StyledLink>
          ),
        unassignedRobotsLink: (chunk: any) =>
          !isCurrentUserSupporter ? (
            chunk
          ) : (
            <StyledLink to="/cloud/support/unassigned-robots">
              {chunk}
            </StyledLink>
          ),
      };
      break;
    case ActivityCode.OrganizationDeleted:
    default:
      variables = {};
      break;
  }

  return activityMsg[code]
    ? intl.formatMessage(activityMsg[code], variables)
    : "-";
};
