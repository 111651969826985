import { Box } from "@mui/material";
import { ReactElement } from "react";
// import { IoCameraOutline, IoClose } from "react-icons/io5";

// const ImageIconButton = styled(Box)(({ theme }) => ({
//   height: "37px",
//   width: "37px",
//   boxShadow: "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
//   borderRadius: 100,
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   position: "absolute",
//   background: "white",
//   cursor: "pointer",
//   border: "none",
//   "& svg > *": {
//     color: theme.palette.grey[500],
//   },
// }));

interface Props {
  defaultAvatar?: ReactElement;
}

export const EditableAvatar = ({ defaultAvatar }: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "120px",
        width: "120px",
        marginTop: "16px",
        marginBottom: "6px",
      }}
    >
      {defaultAvatar}
      {/* <ImageIconButton component={"button"} sx={{ top: "-8px", right: "-8px" }}>
        <IoCameraOutline size={23} />
      </ImageIconButton>
      <ImageIconButton
        component={"button"}
        sx={{ bottom: "-8px", right: "-8px" }}
      >
        <IoClose size={23} />
      </ImageIconButton> */}
    </Box>
  );
};
