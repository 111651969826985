import { Typography } from "@mui/material";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudDialog, CloudDialogProps } from "./CloudDialog";

export const AlertDialog = ({
  open,
  onClose,
  onConfirm,
  header,
  confirmLabel,
  alertMessage,
}: any & CloudDialogProps) => {
  return (
    <CloudDialog
      open={open}
      onClose={onClose}
      header={header}
      submitLabel={confirmLabel || "Yes"}
      onSubmit={onConfirm}
      onCancelClick={onClose}
    >
      <CloudDialogContent>
        <Typography>{alertMessage}</Typography>
      </CloudDialogContent>
    </CloudDialog>
  );
};
