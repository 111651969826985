import { GlobalSearchTabs, NoResults } from "./GlobalSearch";
import { OrganizationResults } from "./OrganizationResults";
import { RobotResults } from "./RobotResults";
import { UserResults } from "./UserResults";

interface Props {
  organizationResults: any;
  assignedRobotResults: any;
  unassignedRobotResults: any;
  userResults: any;
  onClose: () => void;
  isSupporter: boolean;
  selectedFilter: GlobalSearchTabs;
}

export const AllResults = ({
  organizationResults,
  assignedRobotResults,
  unassignedRobotResults,
  userResults,
  onClose,
  isSupporter,
  selectedFilter,
}: Props) => {
  if (
    organizationResults.length === 0 &&
    assignedRobotResults.length === 0 &&
    unassignedRobotResults.length === 0 &&
    userResults.length === 0
  ) {
    return <NoResults selectedFilter="all" />;
  }
  return (
    <>
      {organizationResults.length > 0 ? (
        <OrganizationResults
          organizationResults={organizationResults}
          onClose={onClose}
          isSupporter={isSupporter}
        />
      ) : null}
      {assignedRobotResults.length > 0 || unassignedRobotResults.length > 0 ? (
        <RobotResults
          assignedRobotResults={assignedRobotResults}
          unassignedRobotResults={unassignedRobotResults}
          isSupporter={isSupporter}
          selectedFilter={selectedFilter}
          onClose={onClose}
        />
      ) : null}
      {userResults.length > 0 ? (
        <UserResults
          userResults={userResults}
          onClose={onClose}
          isSupporter={isSupporter}
        />
      ) : null}
    </>
  );
};
