import { USER_AUTOCOMPLETE_QUERY } from "components/atoms/autoComplete/UserAutoComplete";
import { ASSIGNABLE_ROLES } from "components/modules/FmRoles";
import { ORG_MENUITEM_QUERY } from "components/modules/menuItems/OrganizationMenuItem";
import { SUPPORT_MENU_QUERY } from "components/modules/menuItems/SupportMenuItem";
import { ASSIGNED_ROBOTS_QUERY } from "components/organisms/AssignedRobots";
import { ORG_LIST_QUERY } from "components/organisms/Organizations";
import { ORG_DATA_SOURCES_QUERY } from "components/organisms/organizationWidgets/DisinfectionDataSources";
import { ORGUSER_QUERY } from "components/organisms/organizationWidgets/OrgUsers";
import { ROBOT_ORGANIZATION_QUERY } from "components/organisms/robotWidgets/RobotOrganization";
import { UNASSIGNED_ROBOTS_QUERY } from "components/organisms/UnassignedRobots";
import {
  USER_ROLES_ME_QUERY,
  USER_ROLES_QUERY,
} from "components/organisms/userWidgets/UserRoles";
import { ORG_USER_LIST_QUERY } from "components/pages/OrganizationUserListPage";
import { SUPPORT_USER_LIST_QUERY } from "components/pages/SupportUserListPage";
import { USER_QUERY } from "components/pages/UserPage";

export const getUserRefetchQueries = (orgId?: string, userId?: string) => {
  return [
    {
      query: ORGUSER_QUERY,
      variables: { orgId },
      skip: !orgId,
    },
    {
      query: USER_QUERY,
      variables: { userId },
      skip: !userId,
    },
    { query: SUPPORT_USER_LIST_QUERY },
    {
      query: ORG_USER_LIST_QUERY,
      variables: { orgId },
      skip: !orgId,
    },
    {
      query: ASSIGNABLE_ROLES,
      variables: { orgId },
      skip: !orgId,
    },
    { query: USER_ROLES_QUERY, variables: { userId }, skip: !userId },
    { query: USER_ROLES_ME_QUERY },
    { query: USER_AUTOCOMPLETE_QUERY },
  ];
};

export const getRobotRefetchQueries = (robotId: string, orgId: string) => {
  return [
    {
      query: ROBOT_ORGANIZATION_QUERY,
      variables: { robotId: robotId || "" },
      skip: !robotId,
    },
    { query: SUPPORT_MENU_QUERY },
    { query: UNASSIGNED_ROBOTS_QUERY },
    { query: ASSIGNED_ROBOTS_QUERY },
    { query: ORG_LIST_QUERY },
    {
      query: ORG_MENUITEM_QUERY,
      variables: {
        orgId: orgId || "",
      },
      skip: !orgId,
    },
    {
      query: ORG_DATA_SOURCES_QUERY,
      variables: {
        orgId: orgId || "",
      },
      skip: !orgId,
    },
  ];
};
