import { FormattedMessage } from "react-intl";
import { CloudSelector } from "./CloudSelector";

interface Props {
  selected: string;
  onChange: (selectedValue: string) => void;
}

export const FilterSelector = ({ selected, onChange }: Props) => {
  return (
    <CloudSelector
      elements={[
        {
          label: <FormattedMessage id="all" defaultMessage="All" />,
          value: "all",
          onClick: onChange,
        },
        {
          label: <FormattedMessage id="users" defaultMessage="Users" />,
          value: "users",
          onClick: onChange,
        },
        {
          label: <FormattedMessage id="robots" defaultMessage="Robots" />,
          value: "robots",
          onClick: onChange,
        },
        {
          label: (
            <FormattedMessage
              id="organizations"
              defaultMessage="Organizations"
            />
          ),
          value: "organizations",
          onClick: onChange,
        },
      ]}
      selectedValue={selected}
    />
  );
};
