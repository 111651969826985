import { Box, Chip, Typography } from "@mui/material";
import { DisinfectionFilter } from "gql/graphql";
import _ from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { countryMsg } from "trans/definedMessages/countryMessages";
import { enumMsg } from "trans/definedMessages/enumMessages";
import { filterOptionMsg } from "trans/definedMessages/filterMessages";
import { interruptionMsg } from "trans/definedMessages/interruptionMessages";
import { FilterList } from "types/types";

const FilterChipLabel = ({
  label,
  value,
}: {
  label: keyof DisinfectionFilter;
  value: string;
}) => {
  const intl = useIntl();

  const translatedValue = useMemo(() => {
    switch (label) {
      case "robotTypes":
      case "disinfectionModes":
      case "disinfectionStatus":
        return intl.formatMessage(enumMsg[value]);
      case "interruption":
        return intl.formatMessage(interruptionMsg[value]);
      case "organizationCountries":
        return intl.formatMessage(countryMsg[value]);
      default:
        return value;
    }
  }, [value]);

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textTransform: "uppercase",
          fontSize: "9px",
          lineHeight: "9px",
          fontWeight: 600,
          marginRight: 1,
          letterSpacing: "0.5px",
        }}
        color={(theme) => theme.palette.grey[600]}
      >
        {intl.formatMessage(filterOptionMsg[label])}
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontVariant: "common-ligatures" }}
        display="inline"
      >
        {translatedValue}
      </Typography>
    </Box>
  );
};

export const SelectedFilterChips = ({
  filterList,
  removeSelectedFilter,
}: {
  filterList: FilterList;
  removeSelectedFilter: (value: string, id: keyof DisinfectionFilter) => void;
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: theme.spacing(2),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        paddingBottom: 3,
        // flexGrow: 1,
      })}
    >
      {filterList.length > 0 ? (
        filterList.map((item) => {
          if (_.isArray(item.value)) {
            return item.value?.map((el) => {
              const value = item.label === "organizationIds" ? el.name : el;

              return (
                <Chip
                  size="small"
                  key={el}
                  label={
                    <FilterChipLabel
                      label={item.label as keyof DisinfectionFilter}
                      value={value}
                    />
                  }
                  onDelete={() =>
                    removeSelectedFilter(
                      el,
                      item.label as keyof DisinfectionFilter,
                    )
                  }
                />
              );
            });
          } else if (item.label === "interruption") {
            return item.value.reasons.map((reason: any) => (
              <Chip
                size="small"
                key={reason}
                label={
                  <FilterChipLabel
                    label={item.label as keyof DisinfectionFilter}
                    value={reason}
                  />
                }
                onDelete={() =>
                  removeSelectedFilter(
                    reason,
                    item.label as keyof DisinfectionFilter,
                  )
                }
              />
            ));
          }

          return (
            <Chip
              size="small"
              key={item.value}
              label={
                <FilterChipLabel
                  label={item.label as keyof DisinfectionFilter}
                  value={item.value}
                />
              }
              onDelete={() =>
                removeSelectedFilter(
                  item.value as string,
                  item.label as keyof DisinfectionFilter,
                )
              }
            />
          );
        })
      ) : (
        <Box
          sx={(theme) => ({
            border: `1px dashed ${theme.palette.grey[300]}`,
            height: "24px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4,
          })}
        >
          <Typography
            variant="body2"
            component="span"
            color={(theme) => theme.palette.grey[500]}
          >
            No filters
          </Typography>
        </Box>
      )}
    </Box>
  );
};
