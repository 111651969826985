import { gql } from "@apollo/client";
import { SupportAvatar } from "components/atoms/avatars/SupportAvatar";
import { Role, RoleType } from "gql/graphql";
import { useIntl } from "react-intl";
import { getSupportRoleShortDescription } from "trans/definedMessages/roleDescriptions";
import { roles } from "trans/definedMessages/roleMessages";
import { CloudCardContent, CloudCardContentProps } from "./CloudCardContent";

interface SupportCardProps extends CloudCardContentProps {
  supportRole?: Role;
}

export const SupportCardContent = ({
  supportRole,
  ...props
}: SupportCardProps) => {
  const intl = useIntl();
  return (
    <CloudCardContent
      header={
        supportRole
          ? intl.formatMessage(roles[supportRole.title])
          : props.header || "-"
      }
      description={
        supportRole
          ? getSupportRoleShortDescription(supportRole.title as RoleType)
          : props.description
      }
      hideIcon
      avatar={<SupportAvatar />}
      wrapperStyle={{ padding: "8px 0px" }}
      {...props}
    />
  );
};

SupportCardContent.fragments = {
  supportRole: gql`
    fragment SupportCardContent on Role {
      title
    }
  `,
};
