import { firebaseAuthErrors } from "trans/firebaseAuthErrors";

export const getFirebaseErrorCode = (firebaseError: string) => {
  return firebaseError.split("/")[1];
};

export const getFirebaseAuthErrorMsg = (firebaseError: string) => {
  const errorCode = getFirebaseErrorCode(firebaseError);
  return (
    firebaseAuthErrors[errorCode] ||
    `We encountered an internal error. Id: ${btoa(errorCode)}`
  );
};
