import { useSubscription } from "@apollo/client";
import { Box, Skeleton, Typography } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { CodeBlock } from "components/atoms/CodeBlock";
import {
  CloudStepper,
  CloudStepperProps,
} from "components/atoms/stepper/CloudStepper";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { graphql } from "gql";
import { UvdRobot } from "gql/graphql";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getFormattedDate } from "utils/utilFunctions";
import { ON_ROBOT_CHANGED } from "../UnassignedRobots";

const QUERY = graphql(`
  query RobotTransferStatus($robotId: String!) {
    robot(id: $robotId) {
      serialNumber
      active {
        createdAt
        organization {
          id
          name
        }
      }
      ... on UvdRobot {
        status {
          job {
            state
          }
        }
      }
    }
  }
`);

export const RobotTransferStatus = () => {
  const { robotId } = useParams();

  const [isOpen, setIsOpen] = useState(true);

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: { robotId: robotId || "" },
    skip: !robotId,
  });

  const robotSubscription = useSubscription(ON_ROBOT_CHANGED);
  const robot = useMemo(() => {
    if (robotSubscription.data && robotSubscription.data.robotChanged) {
      const subRobot = robotSubscription.data.robotChanged as UvdRobot;

      return _.merge(data?.robot, subRobot) as UvdRobot;
    } else {
      return data?.robot as UvdRobot;
    }
  }, [robotSubscription, data?.robot]);

  const pillStatus: any = useMemo(() => {
    if (!!robot?.active && !!robot?.status) {
      return {
        label: "Completed",
        color: "success",
        style: { color: "white" },
      };
    } else if (!robot?.status) {
      return {
        label: "Pending technical service",
        color: "warning",
      };
    } else if (!robot?.active && !!robot?.status) {
      return { label: "Organization missing", color: "error" };
    } else {
      return { label: "" };
    }
  }, [robot]);

  const steps: CloudStepperProps["steps"] = useMemo(() => {
    return [
      {
        label:
          "Robot assigned to " +
          (robot?.active ? robot?.active?.organization?.name : "organization"),
        description: robot?.active?.createdAt
          ? getFormattedDate(new Date(robot?.active?.createdAt))?.toString()
          : "",
        variant: !robot?.active ? "waiting" : "done",
      },
      {
        label: "Data syncing to Cloud enabled",
        description: "",
        variant: robot?.status ? "done" : "waiting",
      },
    ];
  }, [robot]);

  useEffect(() => {
    if (!!robot?.status && !!robot?.active) {
      setIsOpen(false);
    }
  }, [robot?.status]);

  if (loading) return <Skeleton />;

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <WidgetHeader
            label="Status"
            pillLabel={pillStatus?.label}
            pillStyle={pillStatus?.style}
            pillColor={pillStatus?.color}
          />

          <Box onClick={() => setIsOpen(!isOpen)} sx={{ cursor: "pointer" }}>
            {isOpen ? <IoChevronUp size={24} /> : <IoChevronDown size={24} />}
          </Box>
        </Box>
        {isOpen ? (
          <>
            <CloudStepper size="small" style={{ marginTop: 4 }} steps={steps} />
            {!robot?.status ? (
              <Box
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.palette.grey[300]}`,
                  gap: 2,
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 4,
                  marginLeft: 7,
                })}
              >
                <CloudLabel>How to enable data syncing</CloudLabel>
                <Typography
                  variant="body2"
                  color={(theme) => theme.palette.grey[600]}
                >
                  1. Update Organization ID inside the robot to sync
                  Disinfection data to {robot?.active?.organization?.name}
                </Typography>
                <CodeBlock>
                  {`sudo seto_manager pc setup --org-id ${
                    robot?.active?.organization?.id || ""
                  }`}
                </CodeBlock>
                <Typography
                  variant="body2"
                  color={(theme) => theme.palette.grey[600]}
                >
                  2. Restart the robot software
                </Typography>
                <CodeBlock>sudo systemctl restart seto && sleep 30</CodeBlock>
              </Box>
            ) : null}
          </>
        ) : null}
      </WidgetPaper>
    </>
  );
};
