import { useReactiveVar } from "@apollo/client";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { LargeSpinner } from "components/atoms/LargeSpinner";
import { ScrollToTop } from "components/atoms/ScrollToTop";
import { jwtDecode } from "jwt-decode";
import { useEffect, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { IntlProvider } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { localeVar, tokenLanguageVar } from "./cache";
import { useConfig } from "./config/configHook";
import { useFirebase } from "./firebaseWrapper";
import { graphql } from "./gql";
import { Routing } from "./routing/Routing";
import messagesDa from "./trans/da.json";
import messagesEn from "./trans/en.json";

const LocalHostWarning = styled("div")(() => ({
  backgroundColor: "#ff0",
  color: "#000",
  padding: "0.5rem 5rem",
  fontWeight: 700,
  position: "fixed",
  right: 90,
  bottom: "2rem",
  transform: "translate(65%, 0%) rotate(-30deg)",
  zIndex: "9999",
  pointerEvents: "none",
  textAlign: "center",
  lineHeight: "0.4rem",
  fontSize: "0.8rem",
  boxShadow: "0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1)",
  pointerEvent: "none",
}));

const GET_LANGUAGE = graphql(`
  query GetIdentityLanguage {
    me {
      language
    }
  }
`);

export const ContentRoot = () => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const [user, loading] = useAuthState(firebase.auth);
  const tokenLanguage = useReactiveVar(tokenLanguageVar);

  const browserLocale = navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language;

  const { data: languageData } = useQueryWithSnack(GET_LANGUAGE, {
    skip: loading || !user,
  });

  useEffect(() => {
    if (loading) return;

    const fetchData = async () => {
      return await user?.getIdToken();
    };

    fetchData()
      .then((resp) => {
        if (!resp) return;
        // TODO: this one gives error because the resp is undefined twice before its returned
        const decodedToken: any = jwtDecode(resp);
        tokenLanguageVar(decodedToken.language?.toLowerCase());
      })
      .catch((e) => {
        console.error(e);
      });
  }, [user, loading]);

  const locale = useMemo(() => {
    if (!tokenLanguage && !languageData && browserLocale) {
      return browserLocale;
    } else if (tokenLanguage && !languageData) {
      return tokenLanguage;
    } else if (languageData?.me.language) {
      return languageData.me.language?.toLowerCase();
    } else {
      return "en";
    }
  }, [tokenLanguage, languageData, browserLocale]);

  const messageObj = useMemo(() => {
    switch (locale) {
      case "da":
        return messagesDa;
      case "en":
      default:
        return messagesEn;
    }
  }, [locale]);

  useEffect(() => {
    localeVar(locale);
  }, [locale]);

  return (
    <IntlProvider messages={messageObj} locale={locale} defaultLocale="en">
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.page,
          minHeight: "100vh",
          display: "flex",
          position: "absolute",
          minWidth: "100%",
        })}
      >
        {loading ? (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.background.page,
              minHeight: "100vh",
              display: "flex",
              position: "absolute",
              minWidth: "100%",
            })}
          >
            <LargeSpinner />
          </Box>
        ) : (
          <>
            <Routing key={user?.email} />
            {window.location.hostname === "localhost" ? (
              <LocalHostWarning>Localhost mode</LocalHostWarning>
            ) : null}
            <ScrollToTop />
          </>
        )}
      </Box>
    </IntlProvider>
  );
};
