import { CountryCodes } from "gql/graphql";
import { useIntl } from "react-intl";
import { countryMsg } from "trans/definedMessages/countryMessages";
import { CloudAutoComplete } from "./CloudAutocomplete";

interface Props {
  options?: Array<CountryCodes>;
  [key: string]: any;
}

export const CountryAutocomplete = ({
  options,
  hideLabel,
  ...props
}: Props) => {
  const intl = useIntl();

  return (
    <CloudAutoComplete
      options={options ?? Object.values(CountryCodes)}
      getOptionLabel={(option: CountryCodes) => {
        if (countryMsg[option]?.id) {
          return intl.formatMessage(countryMsg[option]);
        } else return option;
      }}
      value={props.value}
      textfieldprops={{
        label: hideLabel ? undefined : "Country",
      }}
      {...props}
    />
  );
};
