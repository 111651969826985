import { Box, Skeleton, Typography } from "@mui/material";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { DisinfectedCell } from "components/modules/tableCells/DisinfectedCell";
import { DisinfectionModeCell } from "components/modules/tableCells/DisinfectionModeCell";
import { UVDRobotStatusIconCell } from "components/modules/tableCells/UVDRobotStatusIconCell";
import { format } from "date-fns";
import { graphql } from "gql";
import { Disinfection } from "gql/graphql";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getUVDOrgId } from "utils/UVD/getUVDRobotSerialNumber";
import { DisinfectionReportDialog } from "../disinfectionReportWidgets/DisinfectionReportDialog";
import { ExposureMap } from "../disinfectionReportWidgets/ExposureMap";

const QUERY = graphql(`
  query RobotLatestDisinfection($filter: DisinfectionFilter) {
    disinfections(filter: $filter) {
      disinfections {
        id
        robotId
        robot {
          id
          robot {
            serialNumber
          }
          active {
            serialNumber
          }
          organization {
            id
            name
          }
        }
        robotId
        department
        distanceTraveled
        end
        interruptions {
          cause
          connectionClosed
          connectionUnstable
          fromUI
          functionButton
          heatDetection
          internalError
          localizationLoss
          lowBattery
          personDetection
          resetButton
          robotStuck
          tabletMovement
          walkDetection
        }
        room
        start
        startedBy
        status
        submittedBy
        type
        uvcLightDuration
        positions {
          _id
          name
          status
        }
        navigationMap {
          id
          active
          area
          mapItems {
            id
            name
            position {
              x
              y
              yaw
            }
            category
          }
          origin {
            x
            y
            yaw
          }
          resolution
          size {
            height
            width
          }
          name
          navigationMapFile {
            id
            name
          }
          zones {
            id
            name
            category
            mapItems {
              id
              category
              name
              order
              position {
                x
                y
                yaw
              }
              time
            }
          }
        }
        heatMap {
          id
          name
        }
      }
    }
  }
`);

export const RobotLatestDisinfection = ({
  robotSerialNumber,
  currentOrgId,
}: any) => {
  const [reportOpen, setReportOpen] = useState(false);

  const { data, loading } = useQueryWithSnack(QUERY, {
    variables: {
      filter: {
        serialNumbers: [robotSerialNumber || ""],
        organizationIds: currentOrgId ? [currentOrgId] : undefined,
      },
    },
  });

  const latestDisinfection = _.first(
    data?.disinfections.disinfections,
  ) as Disinfection;

  const disinfectionOrgName = useMemo(() => {
    const orgId = getUVDOrgId(latestDisinfection?.robotId || "");

    return orgId === latestDisinfection?.robot?.organization.id
      ? latestDisinfection?.robot?.organization.name
      : "Unknown organization";
  }, [latestDisinfection]);

  if (loading) return <Skeleton height={300} />;

  if (!latestDisinfection) return null;

  return (
    <>
      <WidgetPaper
        onClick={() => setReportOpen(true)}
        sx={{
          padding: 0,
          ":hover": {
            boxShadow: "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
            transition: "none",
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 6,
            paddingBottom: 4,
          }}
        >
          <WidgetHeader
            label={
              latestDisinfection?.start
                ? format(new Date(latestDisinfection?.start), "dd MMMM yyyy")
                : "-"
            }
            labelStyle={{ margin: 0 }}
          />
          <UVDRobotStatusIconCell
            status={latestDisinfection?.status}
            size={24}
          />
        </Box>

        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            height: 157,
          }}
        >
          <ExposureMap
            selectedReport={latestDisinfection}
            mapStateWrapperStyle={{
              borderRadius: 0,
              borderRight: "none",
              borderLeft: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 6,
            paddingTop: 4,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <DisinfectionModeCell type={latestDisinfection?.type} />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography>
              {[latestDisinfection?.room, latestDisinfection?.department]
                .filter((item) => item !== "")
                .join(" - ")}
            </Typography>
            <Typography>{disinfectionOrgName}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <DisinfectedCell disinfection={latestDisinfection} />
          </Box>
        </Box>
      </WidgetPaper>
      {latestDisinfection ? (
        <DisinfectionReportDialog
          open={reportOpen}
          onClose={() => setReportOpen(false)}
          selectedReport={latestDisinfection}
        />
      ) : null}
    </>
  );
};
