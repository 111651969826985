import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { graphql } from "gql";
import { RoleType } from "gql/graphql";
import _ from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { roles } from "trans/definedMessages/roleMessages";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const QUERY = graphql(`
  query RoleCell($userId: String!) {
    identity(id: $userId) {
      affiliations {
        organization {
          id
        }
        roles {
          title
        }
      }
    }
  }
`);

export const RoleCell = (params: GridRenderCellParams) => {
  const { orgId } = useParams();
  const intl = useIntl();

  const { data } = useQueryWithSnack(QUERY, {
    variables: { userId: params.row.id || "" },
  });

  const roleArr = useMemo(() => {
    return data?.identity.affiliations
      ?.filter((item) => item.organization?.id === orgId)
      .map((item) => {
        const roleTitle = _.first(item.roles)?.title;
        return intl.formatMessage(roles[roleTitle as RoleType]);
      })
      .join(", ");
  }, [data?.identity.affiliations]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography>{roleArr}</Typography>
      </Box>
    </Box>
  );
};
