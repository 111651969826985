import { useReactiveVar } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { firebaseErrorVar } from "cache";
import { FormikPassword } from "components/atoms/formik";
import { LoginHeader } from "components/atoms/LoginHeader";
import { StyledLink } from "components/atoms/StyledLink";
import { useConfig } from "config/configHook";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useFirebase } from "firebaseWrapper";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFirebaseAuthErrorMsg,
  getFirebaseErrorCode,
} from "utils/firebaseAuthErrorUtils";
import * as Yup from "yup";
import { FormWrapper } from "./LoginDefault";
import { SSOButtons } from "./SSOButtons";

interface Values {
  password: string;
}

export const CreateLogin = () => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const navigate = useNavigate();
  const { encodedUserEmail } = useParams();
  const intl = useIntl();
  const firebaseError: string = useReactiveVar(firebaseErrorVar);

  const userEmail = atob((encodedUserEmail || "")?.toString());

  const formik = useFormik<Values>({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(
          intl.formatMessage({
            id: "required",
            defaultMessage: "Required",
          }),
        )
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Password must be a mix of upper- and lowercase letters, numbers & symbols.",
        ),
    }),
    onSubmit(values) {
      createUserWithEmailAndPassword(firebase.auth, userEmail, values.password)
        .then(() => {
          firebaseErrorVar(undefined);
          navigate("/onboarding");
          firebase.logInWithEmailAndPassword(userEmail, values.password);
        })
        .catch((error) => {
          firebaseErrorVar((error as FirebaseError).code);
        });
    },
  });

  return (
    <FormWrapper>
      <LoginHeader>Choose a password</LoginHeader>
      <Box display="flex" flexDirection="column">
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 100,
            padding: "0px 16px",
            width: "fit-content",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            p: {
              fontSize: "17px",
              lineHeight: "24px",
            },
          })}
        >
          {userEmail}
        </Box>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box>
            <FormikPassword
              formik={formik}
              name="password"
              label={intl.formatMessage({
                id: "login.password",
                defaultMessage: "Password",
              })}
              placeholder="Password"
              helperText="Use 8 or more characters with a mix of letters, numbers & symbols."
            />
          </Box>

          {firebaseError &&
          getFirebaseErrorCode(firebaseError) === "email-already-in-use" ? (
            <Typography sx={{ marginTop: 6 }}>
              It looks like this account already exists.{" "}
              <StyledLink
                to="/login"
                onClick={() => firebaseErrorVar(undefined)}
              >
                Try to log in instead
              </StyledLink>
            </Typography>
          ) : firebaseError ? (
            getFirebaseAuthErrorMsg(firebaseError)
          ) : null}

          {!!firebaseError ? null : (
            <Box mt={6}>
              <Typography mb={2}>
                By clicking Continue, you agree to the{" "}
                <StyledLink to={config.termsLink || ""} target="_blank">
                  Terms
                </StyledLink>
                .
              </Typography>
              <Button type="submit" sx={{ width: "100%" }}>
                Setup my profile
              </Button>
            </Box>
          )}
        </form>
      </Box>
      <SSOButtons />
    </FormWrapper>
  );
};
