import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  children: ReactElement | string;
}

export const PageHeader = ({ children }: Props) => {
  return (
    <Box
      mb={4}
      sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Typography variant="h2">{children}</Typography>
    </Box>
  );
};
