import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { Privilege } from "gql/graphql";
import { useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { EditUserBasicInfo } from "../panels/EditUserBasicInfo";

interface Props {
  user: any;
  userIsMe: boolean;
  loading?: boolean;
}

export const UserBasicInfo = ({ user, userIsMe, loading }: Props) => {
  const [editPanelOpen, setEditPanelOpen] = useState(false);
  const fullName = useUserDisplayName(user);

  const canDeleteUser = usePrivilege(Privilege.IdentityDelete).hasPrivilege;

  if (loading) return <Skeleton />;

  return (
    <>
      <WidgetPaper
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <UserAvatar size="xlarge" sx={{ marginRight: 4 }} identity={user} />
          <Box width="75%">
            {user?.firstName || user?.lastName ? (
              <Typography
                variant="h3"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Industry-Bold",
                  overflowWrap: "anywhere",
                }}
              >
                {fullName}
              </Typography>
            ) : null}
            <Typography
              fontSize="16px"
              lineHeight="inherit"
              sx={{ overflowWrap: "anywhere" }}
            >
              {user?.email}
            </Typography>
          </Box>
        </Box>
        {userIsMe || canDeleteUser ? (
          <IconButton
            onClick={() => setEditPanelOpen(true)}
            sx={{
              alignSelf: "flex-start",
              marginRight: "-8px",
              marginTop: "-8px",
            }}
          >
            <IoSettingsOutline size={24} />
          </IconButton>
        ) : null}
      </WidgetPaper>
      {editPanelOpen ? (
        <EditUserBasicInfo
          open={editPanelOpen}
          onClose={() => setEditPanelOpen(false)}
          identity={user}
          userIsMe={userIsMe}
        />
      ) : null}
    </>
  );
};
