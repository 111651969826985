import { NumberChart } from "components/modules/charts/NumberChart";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const UNIQUE_ROOMS = graphql(`
  query UniqueRooms($filter: DisinfectionFilter!) {
    numberOfUniqueRoomsDisinfected(filter: $filter)
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const UniqueRoomsChart = ({ hiddenFilter }: Props) => {
  const filterParams = useDDFilterParams();

  const { data, loading } = useQueryWithSnack(UNIQUE_ROOMS, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
    skip: !filterParams?.timeSpan,
  });

  return (
    <NumberChart
      header={
        <FormattedMessage id="unique-rooms" defaultMessage="Unique Rooms" />
      }
      description={
        <FormattedMessage
          id="unique-rooms-description"
          defaultMessage="Number of unique rooms disinfected"
        />
      }
      value={data?.numberOfUniqueRoomsDisinfected ?? 0}
      loading={loading}
    />
  );
};
