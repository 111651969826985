import {
  DocumentNode,
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  useQuery,
  useSuspenseQuery,
} from "@apollo/client";
import { ExecutableDefinitionNode, FragmentDefinitionNode } from "graphql";
import { useSnackbar } from "notistack";

export function useQueryWithSnack<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
  hideErrorSnackbar?: boolean,
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(query, {
    onError: () => {
      !hideErrorSnackbar &&
        enqueueSnackbar(
          `Could not fetch data. Id: ${constructErrorId(query)}`,
          {
            variant: "error",
          },
        );
    },
    ...options,
  });
}

export function useSuspenseQueryWithSnack<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SuspenseQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) {
  const { enqueueSnackbar } = useSnackbar();
  return useSuspenseQuery(query, {
    onError: () => {
      enqueueSnackbar(`Could not fetch data. Id: ${constructErrorId(query)}`, {
        variant: "error",
      });
    },
    ...options,
  });
}

export function constructErrorId<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(query: DocumentNode | TypedDocumentNode<TData, TVariables>) {
  const ids: string[] = [];
  for (const definition of query.definitions) {
    switch (definition.kind) {
      case "OperationDefinition":
        {
          const executableDefinition: ExecutableDefinitionNode = definition;
          if (executableDefinition.name) {
            ids.push(executableDefinition.name.value);
          }
        }
        break;
      case "FragmentDefinition":
        {
          const executableDefinition: FragmentDefinitionNode = definition;
          if (executableDefinition.name) {
            ids.push(executableDefinition.name.value);
          }
        }
        break;
      default:
        console.debug(`Could not find id type for ${definition.kind}`);
    }
  }
  return ids.map((o) => btoa(o)).join(", ");
}
