import { Path, Svg } from "@react-pdf/renderer";

export const FailedPositionIcon = () => (
  <Svg width="14" height="24" viewBox="0 0 20 24">
    <Path
      d="M20 9.6C20 14.9019 10 24 10 24C10 24 -2.59836e-08 14.9019 -1.67389e-08 9.6C-7.49427e-09 4.29807 4.23703 7.38784e-09 10 1.74364e-08C15.763 2.74849e-08 20 4.29807 20 9.6Z"
      fill="#D92E2E"
    />
  </Svg>
);

export const DisinfectedPositionIcon = () => (
  <Svg width="14" height="24" viewBox="0 0 39 47">
    <Path
      d="M38.2917 18.4845C38.2917 28.6355 19.1458 46.0545 19.1458 46.0545C19.1458 46.0545 -4.97477e-08 28.6355 -3.20481e-08 18.4845C-1.43484e-08 8.3335 8.11214 0.104492 19.1458 0.104492C30.1795 0.104492 38.2917 8.3335 38.2917 18.4845Z"
      fill="#22A2D9"
    />
  </Svg>
);

export const RemainingPositionIcon = () => (
  <Svg width="14" height="24" viewBox="0 0 20 24">
    <Path
      d="M19.5 9.6C19.5 10.7886 18.9316 12.256 18.0062 13.8338C17.0892 15.3973 15.8596 17.0069 14.6192 18.4641C13.3806 19.9195 12.1405 21.2122 11.2093 22.1417C10.744 22.6062 10.3565 22.9792 10.0858 23.2358C10.0557 23.2643 10.0271 23.2913 10 23.3169C9.97289 23.2913 9.94429 23.2643 9.91424 23.2358C9.64353 22.9792 9.25604 22.6062 8.79073 22.1417C7.85955 21.2122 6.61944 19.9195 5.38076 18.4641C4.14041 17.0069 2.91077 15.3973 1.9938 13.8338C1.06844 12.256 0.5 10.7886 0.5 9.6C0.5 4.5855 4.50174 0.5 10 0.5C15.4983 0.5 19.5 4.5855 19.5 9.6Z"
      stroke="#22A2D9"
    />
  </Svg>
);
