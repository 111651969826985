import { Grid } from "@mui/material";
import { DisinfectionFilterButtons } from "components/modules/disinfectionFilter/DisinfectionFilterButtons";
import {
  DisinfectionFilterPanel,
  useDDFilter,
  useDDTimeSpan,
} from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { DisinfectionFilter } from "gql/graphql";
import { useState } from "react";
import { getSelectedFilterList } from "utils/UVD/getSelectedFilterList";

function alterMonth(date: Date, months: number) {
  const newDate = new Date(date);
  newDate.setUTCMonth(newDate.getUTCMonth() + months);
  return newDate;
}

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const DisinfectionDashboardFilter = ({ hiddenFilter }: Props) => {
  const [DDFilters] = useDDFilter();
  const { start, setStart } = useDDTimeSpan();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Grid item>
        <DisinfectionFilterButtons
          start={new Date(start)}
          filterCount={getSelectedFilterList(DDFilters).length || 0}
          onOpenDrawer={() => setDrawerOpen(true)}
          onBackClick={() => setStart(alterMonth(start, -1))}
          onForwardClick={() => setStart(alterMonth(start, 1))}
          onTodayClick={() => setStart()}
        />
      </Grid>
      <DisinfectionFilterPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        hiddenFilter={hiddenFilter}
      />
    </>
  );
};
