import { gql } from "@apollo/client";
import { Box } from "@mui/material";
import { UserAvatar } from "components/atoms/avatars/UserAvatar";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { closeSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routing/Routes";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { CloudListItem } from "../atoms/dashboard/Identity/popup/CloudListItem";
import { CloudListItemGroup } from "../atoms/dashboard/Identity/popup/CloudListItemGroup";
import { PopupMenu } from "../atoms/dashboard/Identity/popup/CloudPopupMenu";

const GET_IDENTITY = gql`
  query IdentityButton {
    me {
      ...UserAvatar
      ...UserCardContent
    }
  }
  ${UserAvatar.fragments.identity}
  ${UserCardContent.fragments.identity}
`;

export const IdentityButton = () => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data, client } = useQueryWithSnack(GET_IDENTITY);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserAvatar
        size="medium"
        onClick={handleOpen}
        identity={data?.me}
        sx={{ ml: 6, cursor: "pointer" }}
      />
      <PopupMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        paperProps={{ padding: 0, overflow: "hidden" }}
      >
        <Box>
          <Box
            onClick={() => {
              navigate(ROUTES.profile);
              handleClose();
            }}
            sx={(theme) => ({
              ":hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
                ".MuiTypography-root": {
                  color: theme.palette.primary.main,
                },
                button: {
                  pointerEvents: "none",
                },
                "svg > *": {
                  color: theme.palette.primary.main,
                },
              },
            })}
          >
            <UserCardContent
              avatarSize="large"
              identity={data?.me}
              iconType="navigate"
              wrapperStyle={{ padding: "16px 24px" }}
              iconStyle={{ marginRight: "-12px" }}
            />
          </Box>
          {/* <CloudListItemGroup
            sx={(theme: any) => ({
              padding: 2,
              borderTop: "1px solid " + theme.palette.grey[200],
              borderBottom: "1px solid " + theme.palette.grey[200],
            })}
          >
            
          </CloudListItemGroup> */}
          <CloudListItemGroup
            sx={(theme: any) => ({
              padding: 0,
              borderTop: "1px solid " + theme.palette.grey[200],
              marginBottom: 2,
            })}
          >
            <CloudListItem
              label="Sign Out"
              onClick={() => {
                firebase.logout();
                closeSnackbar();
                navigate("/");
                client.clearStore();
              }}
            />
          </CloudListItemGroup>
        </Box>
      </PopupMenu>
    </>
  );
};
