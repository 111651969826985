import { Box, IconButton, Typography } from "@mui/material";
import { IoCopyOutline } from "react-icons/io5";

export const CodeBlock = ({ children }: { children: string }) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "6px 8px",
        background: theme.palette.grey[200],
        alignItems: "center",
      })}
    >
      <Typography
        variant="bodyS"
        sx={{ fontFamily: "monospace", lineHeight: "14px" }}
      >
        {children}
      </Typography>
      <IconButton
        sx={{ marginLeft: 4 }}
        onClick={() => navigator.clipboard.writeText(children.toString())}
      >
        <IoCopyOutline
          size={16}
          style={{
            cursor: "pointer",
            color: "#5E6278",
          }}
        />
      </IconButton>
    </Box>
  );
};
