import { ListSectionHeader } from "components/atoms/ListSectionHeader";
import { Identity } from "gql/graphql";
import { useNavigate } from "react-router-dom";
import { UserCardContent } from "../cards/UserCardContent";
import { ResultWrapper } from "./GlobalSearch";

interface Props {
  userResults: any;
  onClose: () => void;
  isSupporter: boolean;
}

export const UserResults = ({ userResults, onClose, isSupporter }: Props) => {
  const navigate = useNavigate();

  const handleNavigation = (user: Identity) => {
    if (isSupporter) {
      navigate("/cloud/support/users/" + user.id);
    } else {
      navigate(
        `/cloud/fm/${user.affiliations[0].organization.id}/users/${user.id}`,
      );
    }
  };
  return (
    <>
      <ListSectionHeader>Users</ListSectionHeader>
      {userResults.map((user: Identity) => (
        <ResultWrapper
          key={user.id}
          onClick={() => {
            onClose();
            handleNavigation(user);
          }}
        >
          <UserCardContent identity={user} hideIcon />
        </ResultWrapper>
      ))}
    </>
  );
};
