import { Box } from "@mui/material";
import { CountryAutocomplete } from "components/atoms/autoComplete/CountryAutocomplete";
import { Warning } from "components/atoms/Warning";
import { CountryCodes, Organization } from "gql/graphql";
import { SyntheticEvent } from "react";

export const ReviewOrganization = ({
  selectedOrg,
  selectedOrgCountry,
  setSelectedOrgCountry,
}: {
  selectedOrg: Partial<Organization> | null;
  selectedOrgCountry: CountryCodes | null;
  setSelectedOrgCountry: any;
}) => {
  return (
    <Box pb={6}>
      <Warning
        message={`Select a country for ${selectedOrg?.name} for easy identification in the system.`}
        sx={{ marginBottom: 6 }}
      />
      <CountryAutocomplete
        multiple={false}
        value={selectedOrgCountry}
        placeholder="Country"
        onChange={(event: SyntheticEvent, value: any) =>
          setSelectedOrgCountry(value)
        }
      />
    </Box>
  );
};
