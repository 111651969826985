interface Props {
  color: string;
  isOffline?: boolean;
  size?: number;
}

export const BatteryPluggedInIcon = ({
  color,
  isOffline,
  size = 24,
}: Props) => {
  return (
    <svg viewBox="0 0 25 14" height={size} width={size}>
      <path
        d="M24 5C24.5523 5 25 5.44772 25 6V8C25 8.55228 24.5523 9 24 9V5Z"
        fill="rgba(36, 37, 37)"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2H15.0372V1H20C21.6569 1 23 2.34315 23 4V10C23 11.6569 21.6569 13 20 13H13.55V12H20C21.1046 12 22 11.1046 22 10V4C22 2.89543 21.1046 2 20 2ZM10.55 12H3C1.89543 12 1 11.1046 1 10V4C1 2.89543 1.89543 2 3 2H8.96279V1H3C1.34315 1 0 2.34315 0 4V10C0 11.6569 1.34315 13 3 13H10.55V12Z"
        fill="rgba(36, 37, 37)"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.55 10.1358C8.83427 9.92486 7.5 8.56941 7.5 6.9413V3H3C2.44772 3 2 3.44772 2 4V10C2 10.5523 2.44771 11 3 11H10.55V10.1358ZM13.55 10.1225V11H20C20.5523 11 21 10.5523 21 10V4C21 3.44772 20.5523 3 20 3H16.5V6.9413C16.5 8.53717 15.2169 9.87193 13.55 10.1225Z"
        fill={color}
        fillOpacity="0.9"
      />
      <path
        d="M14.4384 3.6118V1.36399C14.4384 1.1639 14.2809 1 14.0887 1H13.4465C13.2542 1 13.0967 1.1639 13.0967 1.36399V3.6118H10.9952V1.36399C10.9952 1.1639 10.8377 1 10.6455 1H10.0033C9.80999 1 9.6525 1.1639 9.6525 1.36399V3.6118H8.5V6.49712C8.5 8.06377 9.71488 9.34838 11.212 9.38137H11.2274V12.4678C11.2274 12.7616 11.4564 13 11.7387 13H12.3522C12.6345 13 12.8636 12.7616 12.8636 12.4678V9.38137C14.3668 9.3569 15.5909 8.06909 15.5909 6.49712V3.6118H14.4384Z"
        fill={isOffline ? color : "rgba(36, 37, 37)"}
        fillOpacity="0.9"
      />
    </svg>
  );
};
