import { gql, useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { SupportCardContent } from "components/modules/cards/SupportCardContent";
import { CloudDrawer, CloudDrawerProps } from "components/modules/CloudDrawer";
import { useConfig } from "config/configHook";
import { useFirebase } from "firebaseWrapper";
import { graphql } from "gql";
import { Identity, Privilege, RoleType } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getSupportRoleShortDescription } from "trans/definedMessages/roleDescriptions";
import { roles } from "trans/definedMessages/roleMessages";
import { SupportRole } from "types/types";
import { usePrivilege } from "utils/privileges/usePrivileges";
import { getUserRefetchQueries } from "utils/refetchQueries";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { ALL_ORG_QUERY } from "../dialogs/AssignSupportRole";
import { getAlertLabel } from "./EditOrganizationRole";

export const REVOKE = graphql(`
  mutation RevokeUserRole($userId: String!, $role: RoleType!, $orgId: String) {
    revoke(id: $userId, role: $role, targetOrganizationId: $orgId) {
      id
      firstName
      lastName
      affiliations {
        organization {
          name
        }
        roles {
          title
        }
      }
    }
  }
`);

const EDITSUPPORTROLE_FRAGMENT = gql`
  fragment EditSupportRoles on Identity {
    id
    supportRole {
      title
    }
  }
`;

interface Props {
  open: CloudDrawerProps["open"];
  onClose: CloudDrawerProps["onClose"];
  user: Identity;
  userIsMe?: boolean;
}

export const EditSupportRole = ({ open, onClose, user, userIsMe }: Props) => {
  const intl = useIntl();
  const config = useConfig();
  const firebase = useFirebase(config);
  const navigate = useNavigate();

  const canRemoveSupportRole = usePrivilege(
    Privilege.GenericRoleManagement,
  ).hasPrivilege;

  const displayName = useUserDisplayName(user);
  const supportRole = user?.supportRole?.title ?? RoleType.SecondLevel;
  const isLastAffiliation = user?.affiliations?.length === 0;

  const handleClose = () => {
    onClose();
  };

  const refetchQueries = getUserRefetchQueries(undefined, user?.id);

  const [revokeUserRole, { client }] = useMutation(REVOKE, {
    refetchQueries,
  });

  const handleRevokeRole = () => {
    const snackbarId = enqueueSnackbar("Revoking support role...", {
      variant: "loading",
      persist: true,
    });
    handleClose();

    revokeUserRole({
      variables: {
        userId: user.id,
        role: supportRole as RoleType,
      },
      onCompleted: () => {
        const snackbarMessage = isLastAffiliation
          ? userIsMe
            ? "Deleted my profile"
            : `Deleted user ${displayName}`
          : `Revoked ${intl.formatMessage(
              roles[supportRole],
            )} role from ${displayName}`;

        enqueueSnackbar(snackbarMessage, {
          variant: "success",
        });

        if (isLastAffiliation) {
          if (userIsMe) {
            firebase.logout();
            closeSnackbar();
            navigate("/");
            client.clearStore();
          } else {
            navigate("/cloud/support/users");
          }
        } else if (userIsMe) {
          navigate("/cloud/profile");
        }

        closeSnackbar(snackbarId);
      },
      onError: () => {
        enqueueSnackbar(
          `Could not revoke ${intl.formatMessage(
            roles[supportRole],
          )} role from ${displayName}`,
          {
            variant: "error",
          },
        );
        closeSnackbar(snackbarId);
      },
    });
  };

  const { data: allOrgData } = useQueryWithSnack(ALL_ORG_QUERY);

  const allOrgText = useMemo(() => {
    const allOrgs = allOrgData?.organizations ?? [];

    if (allOrgs.length > 0) {
      const firstOrg = _.first(allOrgs);

      return (
        <FormattedMessage
          id="support.organizationsCount"
          defaultMessage={
            "{firstOrgName} <light>{restCount, plural, =0 {} one {+# organization} other {+# organizations}}</light>"
          }
          values={{
            restCount: allOrgs.length - 1,
            firstOrgName: firstOrg?.name ?? "-",
            light: (chunks) => (
              <Typography
                color={(theme) => theme.palette.grey[600]}
                display="inline"
              >
                {chunks}
              </Typography>
            ),
          }}
        />
      );
    } else {
      return "No organizations";
    }
  }, [allOrgData]);

  const alertLabel = getAlertLabel(
    isLastAffiliation,
    userIsMe || false,
    supportRole as RoleType,
    intl,
  );

  return (
    <CloudDrawer
      open={open}
      onClose={handleClose}
      header={`Manage ${intl.formatMessage(
        roles[supportRole as RoleType],
      )} Role`}
      secondaryAction={{
        label: `Remove ${intl.formatMessage(
          roles[supportRole as RoleType],
        )} Role`,
        onClick: handleRevokeRole,
        color: "error",
        disabled: !canRemoveSupportRole,
      }}
      alertLabel={alertLabel.message}
      alertSubmitButtonLabel={alertLabel.button}
    >
      <CloudLabel>Support role</CloudLabel>
      <Box
        sx={{
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SupportCardContent
          header={intl.formatMessage(roles[SupportRole.SecondLevel])}
          description={getSupportRoleShortDescription(
            SupportRole.SecondLevel as unknown as RoleType,
          )}
        />
        <Typography ml={13}>{allOrgText}</Typography>
      </Box>
    </CloudDrawer>
  );
};

EditSupportRole.fragments = {
  user: EDITSUPPORTROLE_FRAGMENT,
};
