import { gql } from "@apollo/client";
import { RobotAvatar } from "components/atoms/avatars/RobotAvatar";
import { RobotType } from "gql/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { enumMsg } from "trans/definedMessages/enumMessages";
import { CloudCardContent, CloudCardContentProps } from "./CloudCardContent";

export interface RobotCardContentProps extends CloudCardContentProps {
  robot: any;
}

const ROBOTCARDCONTENT_FRAGMENT = gql`
  fragment RobotCardContent on UvdRobot {
    id
    robotType
    serialNumber
  }
`;

export const RobotCardContent = ({
  header: headerProp,
  description: descriptionProp,
  hideDescription,
  robot,
  size,
  ...props
}: RobotCardContentProps) => {
  const intl = useIntl();

  const robotType = robot?.robotType || RobotType.Tuv;

  const header = useMemo(() => {
    return headerProp ? headerProp : robot?.serialNumber;
  }, [headerProp, robot]);

  const description = () => {
    return descriptionProp
      ? descriptionProp
      : intl.formatMessage(enumMsg[robotType]);
  };

  return (
    <CloudCardContent
      size={size}
      header={header || "-"}
      description={hideDescription ? undefined : description()}
      avatar={
        <RobotAvatar
          size={size}
          robotType={robotType}
          sx={{ marginRight: size === "small" ? 0 : 2 }}
        />
      }
      {...props}
    />
  );
};

RobotCardContent.fragments = {
  robot: ROBOTCARDCONTENT_FRAGMENT,
};
