import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudInlineLink } from "components/atoms/CloudInlineLink";
import { FormikTextField } from "components/atoms/formik";
import { ListElement, OrderedList } from "components/atoms/ListElements";
import { CloudDialog, CloudDialogProps } from "components/modules/CloudDialog";
import { useFormik } from "formik";
import { graphql } from "gql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { getEnvironment } from "utils/UVD/environmentUtils";
import * as Yup from "yup";
import { UNASSIGNED_ROBOTS_QUERY } from "../UnassignedRobots";

const CREATE_ROBOT = graphql(`
  mutation AddRobot($serialNumber: String!) {
    createRobot(serialNumber: $serialNumber) {
      id
      serialNumber
    }
  }
`);

type Values = {
  serialNumber: string;
};

interface AddRobotProps {
  refetch?: () => void;
}

export const AddRobot = ({
  open,
  onClose,
  refetch,
}: CloudDialogProps & AddRobotProps) => {
  const intl = useIntl();

  const { environment, spreadsheetUrl, oldSystemLink } = getEnvironment();

  const [createRobot] = useMutation(CREATE_ROBOT, {
    refetchQueries: [{ query: UNASSIGNED_ROBOTS_QUERY }],
  });

  useEffect(() => {
    (() => formik.validateForm())();
  }, []);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<Values>({
    initialValues: {
      serialNumber: "",
    },
    validationSchema: Yup.object({
      serialNumber: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Adding robot...", {
        variant: "loading",
        persist: true,
        subText: "This might take a while ...",
      });
      handleClose();

      createRobot({
        variables: {
          serialNumber: values.serialNumber,
        },
        onCompleted: () => {
          enqueueSnackbar(`Added Robot ${values.serialNumber}`, {
            variant: "success",
          });

          refetch && refetch();
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not add robot ${values.serialNumber}`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  return (
    <form>
      <CloudDialog
        header="Add robot"
        description="Add a new robot or an existing FM 1.0 robot"
        open={open}
        onClose={handleClose}
        hideCancel
        submitLabel="Add"
        submitDisabled={formik.values.serialNumber === ""}
        onSubmit={formik.handleSubmit}
      >
        <CloudDialogContent>
          <Box mb={2} sx={() => ({})}>
            <Typography
              variant="body2"
              color={(theme) => theme.palette.grey[600]}
            >
              Before you add an FM 1.0 robot:
            </Typography>
            <OrderedList>
              <ListElement>
                Make sure the robot is in the “Blue Ocean Robotics” organization
                in {oldSystemLink}
              </ListElement>
              <ListElement>
                Make sure the robot is configured as a {environment} robot
              </ListElement>
            </OrderedList>
          </Box>
          <FormikTextField
            formik={formik}
            name="serialNumber"
            label="serial number"
            placeholder="Robot serial number, for example 10000123"
          />
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey[600]}
            mt={2}
          >
            We only accept serial numbers that are in the{" "}
            <CloudInlineLink to={spreadsheetUrl}>
              UVD Credentials sheet
            </CloudInlineLink>
          </Typography>
        </CloudDialogContent>
      </CloudDialog>
    </form>
  );
};
