import { Typography } from "@mui/material";
import { ContentWrapper } from "components/atoms/ContentWrapper";
import { closeSnackbar } from "notistack";

export const ForbiddenPage = () => {
  closeSnackbar();

  return (
    <ContentWrapper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "10%",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: 96, marginBottom: 12 }}>
        403
      </Typography>
      <Typography variant="h3" mb={2}>
        You do not have access to view this screen.
      </Typography>
      <Typography variant="caption" color={(theme) => theme.palette.grey[600]}>
        If you believe this is an error, please contact our support.
      </Typography>
    </ContentWrapper>
  );
};
