import { Box, Typography } from "@mui/material";

import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import {
  useDDFilterParams,
  useDDTimeSpan,
} from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { format, isAfter, isSameDay, isWeekend } from "date-fns";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { utcDateToLocaleDate } from "utils/utilFunctions";

const QUERY = graphql(`
  query CyclesCounter($filter: DisinfectionFilter!) {
    numberOfDisinfectionCycles(filter: $filter, groupBy: Day) {
      date
      count
    }
  }
`);

export function getDaysInMonth(date: Date) {
  const dateIterator = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1),
  );
  const days: Date[] = [];
  while (dateIterator.getUTCMonth() == date.getUTCMonth()) {
    days.push(new Date(dateIterator));
    dateIterator.setUTCDate(dateIterator.getUTCDate() + 1);
  }
  return days;
}

interface Props {
  color?: string;
  hiddenFilter?: DisinfectionFilter;
}

export const CycleOverviewChart = ({ color, hiddenFilter }: Props) => {
  const filterParams = useDDFilterParams();
  const { start } = useDDTimeSpan();

  const { data } = useQueryWithSnack(QUERY, {
    variables: {
      filter: { ...filterParams, ...hiddenFilter },
    },
  });

  const cyclesByDay = useMemo(() => {
    const currentMonthDays = start ? getDaysInMonth(start) : null;

    const largestNumberOfCycles = Math.max(
      ...(data?.numberOfDisinfectionCycles ?? []).map((item) => item.count),
    );

    return currentMonthDays?.map((day) => {
      const dataDay = data?.numberOfDisinfectionCycles.find((item) =>
        isSameDay(new Date(item.date), day),
      );

      return {
        day: day.getUTCDate(),
        percentage: ((dataDay?.count ?? 0) / largestNumberOfCycles) * 100,
        count: dataDay?.count ?? 0,
        isWeekend: isWeekend(utcDateToLocaleDate(day)),
        hide: isAfter(utcDateToLocaleDate(day), new Date()),
      };
    });
  }, [data, start]);

  const header = start ? format(utcDateToLocaleDate(start), "MMMM yyyy") : "-";

  return (
    <WidgetPaper
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WidgetHeader
        label={header}
        description={
          <FormattedMessage
            id="cycles-count-description"
            defaultMessage="Number of disinfection cycles per day"
          />
        }
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 4,
          maxWidth: "100%",
        }}
      >
        {cyclesByDay?.map((item) => (
          <ChartColumn key={item.day} {...item} color={color} />
        ))}
      </Box>
    </WidgetPaper>
  );
};

interface ChartColumnProps {
  percentage: number;
  day: string | number;
  count: number;
  isWeekend: boolean;
  hide: boolean;
  color?: string;
}

const ChartColumn = ({
  isWeekend,
  color,
  percentage,
  day,
  count,
  hide,
}: ChartColumnProps) => {
  const WIDTH = "50%";
  return (
    <Box
      sx={() => ({
        height: 180,
        width: WIDTH,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      })}
    >
      {hide ? (
        <Box sx={{ flex: 1 }} />
      ) : (
        <>
          {count > 0 ? (
            <Typography variant="body1" fontWeight={500} mb={2}>
              {count}
            </Typography>
          ) : (
            <Box
              sx={{
                minHeight: "20px",
                mb: 2,
              }}
            />
          )}
          <Box
            sx={{
              height: "100%",
              width: WIDTH,
              backgroundColor: "rgba(24, 28, 50, 0.05)",
              borderRadius: 100,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={(theme) => ({
                position: "absolute",
                top: `${100 - percentage}%`,
                height: `100%`,
                width: "100%",
                backgroundColor: color ?? theme.palette.success.main,
                borderRadius: "inherit",
              })}
            />
          </Box>
        </>
      )}
      <Typography
        variant="body2"
        color={(theme) => theme.palette.grey[isWeekend ? 900 : 600]}
        mt={2}
      >
        {day}
      </Typography>
    </Box>
  );
};
