import { OrganizationAutoComplete } from "components/atoms/autoComplete/OrganizationAutoComplete";
import { CloudLabel } from "components/atoms/CloudLabel";
import { Organization } from "gql/graphql";
import { SyntheticEvent } from "react";
import { onChangeFilter } from "../getFilterComponent";

interface Props {
  value: string[] | undefined | null;
  options: Partial<Organization>[] | undefined;
  selected: boolean;
}

export const OrganizationFilter = ({
  value,
  onChange,
  options,
  selected,
}: Props & onChangeFilter) => {
  return (
    <>
      <CloudLabel primary={selected}>Organization</CloudLabel>
      <OrganizationAutoComplete
        autoComplete="off"
        getOptionLabel={(option: Organization) => option.name}
        isOptionEqualToValue={(option: any, value: any) =>
          option.id === value.id
        }
        options={options}
        value={value}
        placeholder="Name"
        onChange={(event: SyntheticEvent, value: any) =>
          onChange(value, "organizationIds")
        }
      />
    </>
  );
};
