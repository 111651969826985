import { NumberChart } from "components/modules/charts/NumberChart";
import { useDDFilterParams } from "components/modules/disinfectionFilter/DisinfectionFilterPanel";
import { graphql } from "gql";
import { DisinfectionFilter } from "gql/graphql";
import { FormattedMessage } from "react-intl";
import { useQueryWithSnack } from "utils/useQueryWithSnack";

const AVERAGE_DURATION = graphql(`
  query AverageDuration($filter: DisinfectionFilter!) {
    disinfectionAverageDuration(filter: $filter)
  }
`);

interface Props {
  hiddenFilter?: DisinfectionFilter;
}

export const AverageDurationChart = ({ hiddenFilter }: Props) => {
  const filterParams = useDDFilterParams();

  const { data, loading } = useQueryWithSnack(AVERAGE_DURATION, {
    variables: {
      filter: {
        ...filterParams,
        ...hiddenFilter,
      },
    },
    skip: !filterParams?.timeSpan,
  });

  return (
    <NumberChart
      header={
        <FormattedMessage
          id="average-duration"
          defaultMessage="Average Duration"
        />
      }
      description={
        <FormattedMessage
          id="average-duration-description"
          defaultMessage="Average disinfection time in minutes, UV-C lights at full power."
        />
      }
      value={Math.floor(data?.disinfectionAverageDuration || 0)}
      postfix="minutes"
      loading={loading}
    />
  );
};
