import { Box, BoxProps, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { IoCheckmark } from "react-icons/io5";

const ProgressIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.5" cy="16.6978" r="15" stroke="#009EF7" strokeWidth="2" />
      <circle cx="16.5" cy="16.6978" r="5" fill="#009EF7" />
    </svg>
  );
};

interface StepperCircleStyleProps extends BoxProps {
  size: number;
}

const StepperCircleOutlined = styled(Box, {
  shouldForwardProp: (prop) => prop !== "size",
})<StepperCircleStyleProps>(({ theme, size }) => ({
  height: size,
  width: size,
  borderRadius: 100,
  border: "2px dashed " + theme.palette.grey[400],
  boxSizing: "border-box",
}));

interface StepperCircleFilledProps extends StepperCircleStyleProps {
  color?: string;
}

const StepperCircleFilled = styled(Box, {
  shouldForwardProp: (prop: any) => !["color", "size"].includes(prop),
})<StepperCircleFilledProps>(({ theme, color, size }) => ({
  height: size,
  width: size,
  borderRadius: 100,
  backgroundColor: color !== undefined ? color : theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  svg: {
    width: size * 0.7,
    height: size * 0.7,
    color: "white",
  },
}));

export interface StepperCircleProps {
  variant?: "waiting" | "done" | "current";
  color?: "primary" | "error";
  style?: any;
  size?: "large" | "small";
}

export const CloudStepperCircle = ({
  variant = "done",
  color = "primary",
  size = "large",
  style,
}: StepperCircleProps) => {
  const theme = useTheme();

  const getColor = () => {
    switch (color) {
      case "error":
        return theme.palette.error.main;
      case "primary":
      default:
        return theme.palette.primary.main;
    }
  };

  const getSize = () => {
    switch (size) {
      case "small":
        return 20;
      case "large":
      default:
        return 32;
    }
  };

  switch (variant) {
    case "waiting":
      return <StepperCircleOutlined style={style} size={getSize()} />;
    case "current":
      return <ProgressIcon />;
    case "done":
    default:
      return (
        <StepperCircleFilled color={getColor()} size={getSize()} style={style}>
          <IoCheckmark />
        </StepperCircleFilled>
      );
  }
};
