export const CustomCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.2941 0H3.70588C1.65918 0 0 1.65918 0 3.70588V14.2941C0 16.3408 1.65918 18 3.70588 18H14.2941C16.3408 18 18 16.3408 18 14.2941V3.70588C18 1.65918 16.3408 0 14.2941 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CustomChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14 0H4C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0Z"
        fill="white"
      />
      <path
        d="M14 18H4C2.93913 18 1.92172 17.5786 1.17157 16.8284C0.421424 16.0783 0 15.0609 0 14V4C0 2.93913 0.421424 1.92172 1.17157 1.17157C1.92172 0.421428 2.93913 0 4 0H14C15.0609 0 16.0783 0.421428 16.8284 1.17157C17.5786 1.92172 18 2.93913 18 4V14C18 15.0609 17.5786 16.0783 16.8284 16.8284C16.0783 17.5786 15.0609 18 14 18ZM4.26 8.566L3 9.826L6.9 13.726L14.74 6.059L13.48 4.799L6.9 11.146L4.26 8.566Z"
        fill="currentColor"
      />
    </svg>
  );
};
