import { useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { CountryAutocomplete } from "components/atoms/autoComplete/CountryAutocomplete";
import { OrganizationAvatar } from "components/atoms/avatars/OrganizationAvatar";
import { FormikTextField } from "components/atoms/formik";
import { FormikAutocomplete } from "components/atoms/formik/FormikAutocomplete";
import { StyledLink } from "components/atoms/StyledLink";
import { useConfig } from "config/configHook";
import { useFormik } from "formik";
import { graphql } from "gql";
import { ActivationState, Affiliation, CountryCodes } from "gql/graphql";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";

const UPDATE_ORG = graphql(`
  mutation SetupOrganization(
    $orgId: String!
    $payload: UpdateOrganizationPayload!
  ) {
    updateOrganization(id: $orgId, payload: $payload) {
      id
      name
    }
  }
`);

interface Props {
  affiliation: Affiliation;
  onComplete: () => void;
  buttonLabel: string;
  allAffiliations: Affiliation[];
}

interface Values {
  orgName: string;
  country: string;
}

export const SetupOrganization = ({
  affiliation,
  onComplete,
  buttonLabel,
}: Props) => {
  const intl = useIntl();
  const config = useConfig();

  const [updateOrg, { loading }] = useMutation(UPDATE_ORG);

  const affiliationInfo = useMemo(() => {
    return affiliation;
  }, [affiliation]);

  const formik = useFormik<Values>({
    initialValues: {
      orgName: affiliationInfo?.organization?.name || "",
      country: affiliationInfo?.organization?.country || "",
    },
    validationSchema: Yup.object({
      orgName: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
      country: Yup.string(),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Onboarding your organization...", {
        variant: "loading",
        persist: true,
      });

      updateOrg({
        variables: {
          orgId: affiliationInfo?.organization?.id || "",
          payload: {
            name: values.orgName,
            activationState: ActivationState.Active,
            country:
              values.country === ""
                ? undefined
                : (values.country as CountryCodes),
          },
        },
        onCompleted: (data) => {
          enqueueSnackbar(
            `${
              data.updateOrganization.name || "Your organization"
            } was onboardet`,
            {
              variant: "success",
            },
          );
          closeSnackbar(snackbarId);
          onComplete();
        },
        onError: (e) => {
          console.log("could not eeeee", e);

          enqueueSnackbar(
            `Could not onboard ${
              affiliationInfo?.organization?.name || "your organization"
            }`,
            {
              variant: "error",
            },
          );
          closeSnackbar(snackbarId);
          onComplete();
        },
      });
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    formik.setFieldValue("orgName", affiliation?.organization?.name || "");
    formik.setFieldValue("country", affiliation?.organization?.country || "");
  }, []);

  return (
    <form key={affiliationInfo.id}>
      <Box
        width={"100%"}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <OrganizationAvatar
          size="xlarge"
          orgId={affiliationInfo?.organization?.id}
        />

        <Box mb={12} flexDirection="column" display="flex" width="100%">
          <FormikTextField
            formik={formik}
            fullWidth
            name="orgName"
            placeholder="Your organization name"
            label={intl.formatMessage({
              id: "organization.name",
              defaultMessage: "Organization name",
            })}
          />
          <FormikAutocomplete
            hideSearchIcon
            name="country"
            formik={formik}
            multiple={false}
            component={CountryAutocomplete}
            placeholder="Country"
            helperText="Select the country where your organization is using UVD Robots."
            textfieldprops={{
              label: intl.formatMessage({
                id: "country",
                defaultMessage: "Country",
              }),
            }}
          />
        </Box>

        <Typography mb={2}>
          By clicking Finish, you agree to the{" "}
          <StyledLink to={config.termsLink || ""} target="_blank">
            Terms
          </StyledLink>{" "}
          on behalf of your organization.
        </Typography>
        <Button
          disabled={!formik.isValid || loading}
          sx={{ width: "100%" }}
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          {buttonLabel}
        </Button>
      </Box>
    </form>
  );
};
