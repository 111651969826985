import { Box, Typography } from "@mui/material";
import { InlineCard } from "components/atoms/InlineCard";
import { format } from "date-fns";
import { Organization } from "gql/graphql";
import { OrganizationCardContent } from "./OrganizationCardContent";

interface Props {
  organization?: Partial<Organization>;
  disinfectionCount?: number;
  disinfectionStart?: Date;
  disinfectionEnd?: Date;
}

export const OrganizationDisinfectionCard = ({
  organization,
  disinfectionCount,
  disinfectionStart,
  disinfectionEnd,
}: Props) => {
  return (
    <InlineCard
      disabled
      sx={{
        flexGrow: 0,
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
      }}
    >
      <OrganizationCardContent organization={organization} hideIcon />
      <Box>
        <Typography
          textAlign="right"
          variant="body2"
          color={(theme) => theme.palette.grey[500]}
        >
          {disinfectionCount || 0} Disinfection cycles
        </Typography>
        {disinfectionStart && disinfectionEnd ? (
          <Typography
            textAlign="right"
            variant="body2"
            color={(theme) => theme.palette.grey[500]}
          >
            {format(new Date(disinfectionStart), "d MMM yyyy")} -{" "}
            {format(new Date(disinfectionEnd), "d MMM yyyy")}
          </Typography>
        ) : null}
      </Box>
    </InlineCard>
  );
};
