import { FormikTextField } from "./FormikTextField";

interface Props {
  name: string;
  formik: any;
  [key: string]: any;
}

export const FormikPassword = (props: Props) => {
  const { name, formik, ...rest } = props;

  return (
    <FormikTextField name={name} formik={formik} type={"password"} {...rest} />
  );
};
