import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { MultipleOrganizationCell } from "components/modules/tableCells/MultipleOrganizationsCell";
import { SupportRoleCell } from "components/modules/tableCells/SupportRoleCell";
import { UserStatusCell } from "components/modules/tableCells/UserStatusCell";
import { Users } from "components/organisms/Users";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { getFormattedDate } from "utils/utilFunctions";

export const SUPPORT_USER_LIST_QUERY = gql`
  query UsersForSupport {
    identities {
      id
      email
      firstName
      lastName
      inviteState
      lastLogin
      affiliations {
        roles {
          title
          privileges
        }
        organization {
          id
          name
          country
        }
      }
      supportRole {
        title
      }
      ...UserCardContent
    }
  }
  ${UserCardContent.fragments.identity}
`;

const columns: GridColDef[] = [
  {
    field: "user",
    flex: 1,
    maxWidth: 300,
    minWidth: 180,
    headerName: "User",
    valueGetter: (params) => useUserDisplayName(params.row),
    renderCell: (params) => (
      <UserCardContent identity={params.row} size="small" hideIcon />
    ),
  },
  {
    field: "organization",
    flex: 1,
    maxWidth: 300,
    minWidth: 180,
    headerName: "Organization",
    sortable: false,
    renderCell: (params) => (
      <MultipleOrganizationCell hideAvatar identity={params.row} />
    ),
  },
  {
    field: "support",
    width: 110,
    headerName: "Support",
    valueGetter: (params) => params.row.supportRole,
    renderCell: SupportRoleCell,
  },
  {
    field: "lastLogin",
    width: 150,
    type: "Date",
    align: "right",
    headerAlign: "right",
    headerName: "Last login",
    renderCell: (params) => getFormattedDate(params.row?.lastLogin),
  },
  {
    field: "status",
    width: 150,
    headerName: "Status",
    valueGetter: (params) => params.row.inviteState,
    renderCell: UserStatusCell,
  },
];

export const SupportUserListPage = () => {
  const { data, loading } = useQueryWithSnack(SUPPORT_USER_LIST_QUERY);

  return (
    <Grid container spacing={6}>
      <HeaderGrid crumbs={[{ label: "Support" }, { label: "Users" }]} />
      <Grid item xs={12}>
        <Users
          users={data?.identities || []}
          columns={columns}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};
