import { Box, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { RobotStatusDot } from "components/atoms/RobotStatusDot";
import { useUvdConnectionStatus } from "utils/UVD/useUvdConnectionStatus";

export const SerialNumberCell = (params: GridRenderCellParams) => {
  const status = useUvdConnectionStatus(params.row);

  return (
    <Box
      sx={{
        overflow: "clip",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <RobotStatusDot status={status} />
      <Tooltip title={params.row?.serialNumber} arrow placement="top">
        <Typography display="block" noWrap>
          {params.row.serialNumber}
        </Typography>
      </Tooltip>
    </Box>
  );
};
