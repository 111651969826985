export const PinIcon = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4583 11.1244C23.4583 16.9248 11.7917 26.8782 11.7917 26.8782C11.7917 26.8782 0.125 16.9248 0.125 11.1244C0.125 5.32398 5.0682 0.621826 11.7917 0.621826C18.5151 0.621826 23.4583 5.32398 23.4583 11.1244Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const RemainingPinIcon = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8333 10.5538C22.8333 11.8583 22.1681 13.4679 21.0865 15.1973C20.0148 16.9108 18.5781 18.6741 17.1298 20.2699C15.6832 21.8636 14.2352 23.2791 13.1479 24.2968C12.6046 24.8054 12.1521 25.2139 11.8358 25.4949C11.7741 25.5497 11.7176 25.5997 11.6667 25.6446C11.6157 25.5997 11.5592 25.5497 11.4975 25.4949C11.1813 25.2139 10.7288 24.8054 10.1854 24.2968C9.09816 23.2791 7.65009 21.8636 6.20357 20.2699C4.75519 18.6741 3.31852 16.9108 2.24683 15.1973C1.16524 13.4679 0.5 11.8583 0.5 10.5538C0.5 5.07025 5.17682 0.55127 11.6667 0.55127C18.1565 0.55127 22.8333 5.07025 22.8333 10.5538Z"
        fill="transparent"
        stroke="#22A2D9"
      />
    </svg>
  );
};
