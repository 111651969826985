import { gql, useMutation } from "@apollo/client";
import { Box, RadioGroup, Typography } from "@mui/material";
import { UserAutoComplete } from "components/atoms/autoComplete/UserAutoComplete";
import { RadioButton } from "components/atoms/buttons/RadioButton";
import { CloudDialogContent } from "components/atoms/CloudDialogContent";
import { CloudLabel } from "components/atoms/CloudLabel";
import { Warning } from "components/atoms/Warning";
import { SupportCardContent } from "components/modules/cards/SupportCardContent";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { CloudDialog, CloudDialogProps } from "components/modules/CloudDialog";
import { useFormik } from "formik";
import { graphql } from "gql";
import { Identity, Organization, RoleType, SupportRoleType } from "gql/graphql";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getSupportRoleShortDescription } from "trans/definedMessages/roleDescriptions";
import { roles } from "trans/definedMessages/roleMessages";
import { SupportRole } from "types/types";
import { getUserRefetchQueries } from "utils/refetchQueries";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import * as Yup from "yup";
import { INVITE } from "./InviteUser";

export const ALL_ORG_QUERY = graphql(`
  query AssignSupportRoleAllOrgs {
    supportAssignable
    organizations {
      id
      name
    }
  }
`);

type Values = {
  emails: Array<string>;
  role: RoleType.SecondLevel | RoleType.FirstLevel | undefined;
  organization?: Organization;
};

interface Props {
  identity?: Identity;
}

export const AssignSupportRole = ({
  open,
  onClose,
  identity,
}: Props & CloudDialogProps) => {
  const intl = useIntl();
  const displayName = useUserDisplayName(identity);

  const refetchQueries = getUserRefetchQueries(undefined, identity?.id);

  const [inviteUser] = useMutation(INVITE, {
    refetchQueries,
  });

  const { data } = useQueryWithSnack(ALL_ORG_QUERY);

  const allOrgText = useMemo(() => {
    const allOrgs = data?.organizations || [];

    if (allOrgs.length > 0) {
      const firstOrg = _.first(allOrgs);

      return (
        <FormattedMessage
          id="support.organizationsCount"
          defaultMessage={
            "{firstOrgName} <light>{restCount, plural, =0 {} one {+# organization} other {+# organizations}}</light>"
          }
          values={{
            restCount: allOrgs.length - 1,
            firstOrgName: firstOrg?.name || "-",
            light: (chunks) => (
              <Typography
                color={(theme) => theme.palette.grey[600]}
                display="inline"
              >
                {chunks}
              </Typography>
            ),
          }}
        />
      );
    } else {
      return "No organizations";
    }
  }, [data]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<Values>({
    initialValues: {
      emails: identity ? [identity.email] : [],
      role: RoleType.SecondLevel,
    },
    validationSchema: Yup.object({
      emails: Yup.array().min(1, "").required("Required"),
      role: Yup.string().required(
        intl.formatMessage({
          id: "required",
          defaultMessage: "Required",
        }),
      ),
    }),
    onSubmit(values) {
      const snackbarId = enqueueSnackbar("Assigning support role...", {
        variant: "loading",
        persist: true,
      });
      handleClose();

      const inputs = values.emails.map((email) => ({
        email: email.trim(),
        role: values.role as RoleType,
      }));

      inviteUser({
        variables: {
          inviteInputs: inputs,
        },
        onCompleted: () => {
          enqueueSnackbar(
            inputs.length > 1
              ? `Assigned ${inputs.length} Supporters`
              : `Assigned Support role to ${
                  identity ? displayName : _.first(values.emails)
                }`,
            {
              variant: "success",
            },
          );
          closeSnackbar(snackbarId);
        },
        onError: () => {
          enqueueSnackbar(`Could not assign Supporter ${displayName}`, {
            variant: "error",
          });
          closeSnackbar(snackbarId);
        },
      });
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [open]);

  return (
    <form>
      <CloudDialog
        header="Assign support role"
        open={open}
        onClose={handleClose}
        onSubmit={formik.handleSubmit}
        hideCancel
        submitLabel="Assign"
        submitDisabled={!formik.isValid}
      >
        <CloudDialogContent>
          <Warning
            message="Be careful who you assign a support role to!"
            sx={{ marginBottom: 3 }}
          />
          {identity ? (
            <UserCardContent identity={identity} hideIcon />
          ) : (
            <UserAutoComplete
              disableAutoSelect
              disableSupportUsers
              onChange={(e: any, emails: any) =>
                formik.setFieldValue("emails", emails)
              }
              onBlur={() => formik.setFieldTouched("emails")}
            />
          )}
          <CloudLabel>Support role</CloudLabel>
          <RadioGroup
            name="role"
            value={formik.values.role}
            onChange={(e: any) => formik.setFieldValue("role", e.target.value)}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RadioButton
                disabled={
                  !data?.supportAssignable.includes(
                    SupportRole.SecondLevel as unknown as SupportRoleType,
                  )
                }
                style={{ paddingBottom: 0 }}
                label={
                  <SupportCardContent
                    header={intl.formatMessage(roles[SupportRole.SecondLevel])}
                    description={getSupportRoleShortDescription(
                      SupportRole.SecondLevel as unknown as RoleType,
                    )}
                  />
                }
                value={SupportRole.SecondLevel}
              />
              <Typography ml={21}>{allOrgText}</Typography>
            </Box>
          </RadioGroup>
        </CloudDialogContent>
      </CloudDialog>
    </form>
  );
};

AssignSupportRole.fragments = {
  identity: gql`
    fragment AssignSupportRole on Identity {
      email
      ...UserDisplayName
    }
    ${useUserDisplayName.fragments.identity}
  `,
};
