import { RobotJob } from "gql/graphql";
import { FormattedMessage } from "react-intl";

const ERROR = {
  any_emergency_button_pressed: (
    <FormattedMessage id="emergency-stop" description="Emergency stop" />
  ),
  emergency_reset_button: (
    <FormattedMessage id="emergency-stop" description="Emergency stop" />
  ),
  emergency_button_report: (
    <FormattedMessage id="emergency-stop" description="Emergency stop" />
  ),
  robot_stuck: <FormattedMessage id="robot-stuck" description="Robot stuck" />,
  robot_stuck_recovery: "Operator action needed",
  robot_free: "Operator action needed",
  maintenance_mode: "In maintenance mode",
  push_robot_mode: "Operator action needed",
  rebootable_error: "Fatal error, trying to resolve",
  fatal_error: "Fatal error",
};

export const getChargingStatus = (robotJob: RobotJob) => {
  const { state } = robotJob;

  switch (state) {
    case "awaiting_charging":
      return { charging: false, pluggedIn: true, label: "Awaiting charging" };
    case "start_charging":
      return { charging: false, pluggedIn: true, label: "Preparing charging" };
    case "charging":
      return { charging: true, pluggedIn: false, label: "Charging" };
    case "charged":
      return { charging: true, pluggedIn: false, label: null };
    case "fully_charged":
    case "charging_failed":
      return { charging: false, pluggedIn: true, label: null };
    default:
      return null;
  }
};

export const getIsDocked = (state: RobotJob["state"]) => {
  switch (state) {
    case "awaiting_charging":
    case "start_charging":
    case "charging":
    case "charged":
    case "fully_charged":
    case "charging_failed":
      return true;
    default:
      return false;
  }
};

export const getIsDisinfecting = (state: RobotJob["state"]) => {
  switch (state) {
    case "manual_disinfecting":
    case "stationary_disinfecting":
    case "disinfecting":
      return true;
    default:
      return false;
  }
};

export const getRobotStatus = (robotJob: RobotJob) => {
  const { type, state } = robotJob;

  const dbType = type?.toLowerCase();
  const defaultStatusMsg = "No status available";

  if (
    state === "any_emergency_button_pressed" ||
    state === "emergency_reset_button" ||
    state === "emergency_button_report" ||
    state === "robot_stuck" ||
    state === "robot_stuck_recovery" ||
    state === "robot_free" ||
    state === "maintenance_mode" ||
    state === "push_robot_mode" ||
    state === "rebootable_error" ||
    state === "fatal_error"
  ) {
    return ERROR[state];
  }
  if (dbType === "") {
    switch (state) {
      case "idle":
        return "Idle";
      case "awaiting_charging":
      case "start_charging":
      case "charging":
      case "charged":
      case "fully_charged":
      case "charging_failed":
        return "Docked";
      case "undocking":
        return "Undocking";
      case "undocking_cancelled":
        return "Undocking cancelled";
      case "driving_manual":
        return "Driving - Remote controlled";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "go to position") {
    switch (state) {
      case "driving":
        return "Driving autonomously";
      case "driving_paused":
        return "Driving paused";
      case "driving_manual":
        return "Driving - Remote controlled";
      case "driving_failed":
        return "Driving failed";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "go to docking station") {
    switch (state) {
      case "driving":
        return "Driving to dock";
      case "driving_paused":
        return "Driving paused";
      case "driving_manual":
        return "Driving - Remote controlled";
      case "driving_failed":
        return "Driving failed";
      case "docking_cancelled":
        return "Docking cancelled";
      case "prepare_docking":
      case "docking":
        return "Docking";
      case "docking_failed":
        return "Docking failed";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "create map") {
    switch (state) {
      case "start_mapping":
      case "mapping":
        return "Scanning area";
      case "start_localization":
        return "Locating on map";
      case "map_align":
      case "map_review":
        return "Map editing";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "shutdown") {
    switch (state) {
      case "shutdown_screen":
        return "Shutting down";
      case "delayed_shutdown_screen":
        return "Fatal error, waiting for shutdown";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "reboot") {
    switch (state) {
      case "reboot_screen":
        return "Rebooting";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "startup") {
    switch (state) {
      case "":
        return "Starting up";
      case "press_reset_on_boot_screen":
        return "Action needed";
      default:
        return defaultStatusMsg;
    }
  }
  if (dbType === "disinfect zone") {
    switch (state) {
      case "room_selection":
        return "Operator prepares disinfection";
      case "open_door_to_enter":
      case "open_door_to_leave":
        return "Operator action needed";
      case "driving":
        return "Driving autonomously";
      case "driving_manual":
        return "Driving - Remote controlled";
      case "driving_paused":
        return "Driving paused";
      case "driving_failed":
        return "Stopped";
      case "mode_selection":
      case "mode_setup":
      case "checklist":
      case "login_screen":
      case "review_screen":
      case "cover_removal":
      case "press_start_disinfection_button":
      case "leave_room_close_door":
      case "install_safety_devices":
        return "Operator prepares disinfection";
      case "start_disinfection_countdown":
        return "Waiting for disinfection to start";
      case "disinfection_interrupted":
        return "Disinfection interrupted, resuming";
      case "disinfecting_warm_up":
        return "Warming up";
      case "manual_disinfecting":
        return "Disinfecting - Remote controlled mode";
      case "stationary_disinfecting":
        return "Disinfecting - Stationary mode";
      case "interruption_decision":
        return "Disinfection interrupted";
      case "disinfection_report":
        return "Reviewing report";
      case "disinfecting":
        return "Disinfecting - Autonomous mode";
      default:
        return defaultStatusMsg;
    }
  }
};
