import { useReactiveVar } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { firebaseErrorVar } from "cache";
import { FormikTextField } from "components/atoms/formik";
import { LoginHeader } from "components/atoms/LoginHeader";
import { sendPasswordReset } from "firebaseWrapper";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { getFirebaseAuthErrorMsg } from "utils/firebaseAuthErrorUtils";
import * as Yup from "yup";
import { FormWrapper } from "./LoginDefault";

interface Values {
  email: string;
}

export const ResetPassword = () => {
  const intl = useIntl();
  const firebaseError: string = useReactiveVar(firebaseErrorVar);

  const formik = useFormik<Values>({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          intl.formatMessage({
            id: "login.invalidEmail",
            defaultMessage: "Invalid email address",
          }),
        )
        .required(
          intl.formatMessage({
            id: "required",
            defaultMessage: "Required",
          }),
        ),
    }),
    onSubmit(values) {
      sendPasswordReset(values.email);
    },
  });

  return (
    <FormWrapper>
      <LoginHeader>Reset password</LoginHeader>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography
          color={(theme) => theme.palette.grey[600]}
          sx={{
            textAlign: "center",
            maxWidth: "75%",
          }}
        >
          Enter your email address and we'll send you instructions to reset your
          password
        </Typography>
      </Box>
      <FormikTextField
        formik={formik}
        name="email"
        label={intl.formatMessage({
          id: "login.email",
          defaultMessage: "E-mail",
        })}
        placeholder="Your e-mail address"
        textfieldProps={{ autoFocus: true }}
        sx={{ width: "100%" }}
        onChange={() => firebaseErrorVar(undefined)}
      />
      <Box>
        <Typography
          color={(theme) => theme.palette.error.main}
          sx={{ marginBottom: 6 }}
        >
          {firebaseError ? getFirebaseAuthErrorMsg(firebaseError) : ""}
        </Typography>
        <Button
          type="submit"
          onClick={() => formik.handleSubmit()}
          sx={{ width: "100%" }}
        >
          Reset password
        </Button>
      </Box>
    </FormWrapper>
  );
};
